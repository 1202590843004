import React from 'react';
import { Footer } from '../components/layouts/Footer';
import { Header } from '../components/layouts/HeaderComponent';
import { Hero } from '../components/layouts/Hero/HeroQuoteAnnoucement';
import { MediaComponent, MediaComponentWithCenterImage } from '../components/layouts/MediaComponent';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { HeaderBackground } from '../components/pageComponents/header';
import { useGetInnerWidth } from '../utils/hooks';
import { ListContainer, RandomButtonContainer } from '../components/pageComponents/Styles/style';
import { createMarkUp } from '../components/V2/helpers';
import { colorsRD } from '../components/foundations';
import { Button, ButtonContainer } from '../components/pageComponents/mediaComponentContent/styles';
import { Icon } from '../components/subComponents/Icon';
import { PageContainer } from '../components/containers/PageContainer';

const Gmp = () => {
  const { innerWidth } = useGetInnerWidth();

  const Lang = 'en';
  const data = require(`../../data/content/Pages/geomagic-maintenance-program/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HeaderBackground screenWidth={innerWidth}>
        <HelmetContainer title={data.helmet.title} lang={Lang} />
        <Header />
        <Hero
          title={data.content.hero.title}
          description={data.content.hero.description}
          companies={[]}
          announcementQuote={data.content.hero.announcementQuote}
          announcementPerson={data.content.hero.announcementPerson}
          announcementStars={data.content.hero.announcementStars}
          image={{
            src: '/assets/images/pages/geomagic-maintenance-program/3d-scanning-solutions-key-visual.webp',
            alt: data.content.hero.imageAlt
          }}
          phone
          buttons={[
            {
              text: globalCopy.Buttons.ContactUs,
              vertical: 'buyDesignX'
            }
            // {
            //   text: globalCopy.Buttons.SpeakToSupport,
            //   link: 'https://softwaresupport.oqton.com/s/article/Contact-Us?language=en_US',
            //   color: colorsRD.white
            // }
          ]}
        />
      </HeaderBackground>
      <MediaComponentWithCenterImage
        inverted
        articles={[
          {
            title: data.content.media.title,
            subTitle: data.content.media.subTitle,
            image: {
              src: '/assets/images/pages/geomagic-maintenance-program/icons.png',
              alt: data.content.media.imgAlt
            },
            content: <React.Fragment>{data.content.media.content}</React.Fragment>,
            button: data.content.media.content.button,
            buttonLink: '/manufacturing-os/',
            width: '80%'
          }
        ]}
      />
      <MediaComponent
        articles={[
          {
            width: '80%',
            title: data.content.media2.title,
            content: (
              <>
                <React.Fragment>
                  <p dangerouslySetInnerHTML={createMarkUp(data.content.media2.copy)}></p>
                  <br />
                  <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.media2.list)}></ListContainer>
                  <br />
                  <p dangerouslySetInnerHTML={createMarkUp(data.content.media2.copy2)}></p>
                </React.Fragment>
                <RandomButtonContainer>
                  <ButtonContainer>
                    <a href="https://softwaresupport.oqton.com/s/article/Contact-Us?language=en_US">
                      <Button>
                        {globalCopy.Buttons.ContactSupport}
                        <Icon ml="12px" name="Arrow" />
                      </Button>
                    </a>
                  </ButtonContainer>
                </RandomButtonContainer>
              </>
            )
          }
        ]}
      />

      <MediaComponent
        articles={[
          {
            title: data.content.media3.title,
            content: (
              <React.Fragment>
                <p dangerouslySetInnerHTML={createMarkUp(data.content.media3.copy)}></p>
              </React.Fragment>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="Stay on the Cutting Edge"
                  className="vidyard_iframe"
                  src="//play.vidyard.com/YAgvNBeJ47pLW2kYNGpBty.html?"
                  width="640"
                  height="360"
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            },
            image: { src: '/assets/images/pages/3DXpert/media_2.png', alt: 'Placeholder', imagePos: 'left' }
          }
        ]}
      />
      <MediaComponent
        articles={[
          {
            title: data.content.media4.title,
            content: <p dangerouslySetInnerHTML={createMarkUp(data.content.media4.copy)}></p>,
            image: {
              src: '/assets/images/pages/geomagic-maintenance-program/2.png',
              alt: data.content.hero.imageAlt,
              imagePos: 'right'
            }
          }
        ]}
      />
      <Footer />
    </PageContainer>
  );
};

export default Gmp;
