import React from 'react';
import styled from 'styled-components';

import { spacing } from '../../../../foundations';
import { mediaQueriesSizes } from '../../../../../GlobalStyles';

const DoubleImageContainer = styled.section`
  margin: ${spacing.xxxl}px auto ${spacing.xxxl}px auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    margin: 16px 0;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    flex-direction: row;
    justify-content: space-between;
    img {
      margin: 0;
      width: calc(50% - 8px) !important;
    }
  }
`;

interface ImgProps {
  src: string;
  alt: string;
}

interface DoubleImageProps {
  img1: ImgProps;
  img2: ImgProps;
}

export const DoubleImage = ({ img1, img2 }: DoubleImageProps) => {
  return (
    <DoubleImageContainer>
      <img src={img1.src} alt={img1.alt} />
      <img src={img2.src} alt={img2.alt} />
    </DoubleImageContainer>
  );
};
