import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { createMarkUp } from '../helpers';
import { GridStyles, GridWrap } from './styles.jsx';
import { GridItem1 } from './GridItems/GridItem1';
import { GridItem2 } from './GridItems/GridItem2';
import { GridItem3 } from './GridItems/GridItem3';
import { GridItem4 } from './GridItems/GridItem4';
import { GridItem5 } from './GridItems/GridItem5';
import { GridItem6 } from './GridItems/GridItem6';
import { GridItem7 } from './GridItems/GridItem7';
import { GridItem8 } from './GridItems/GridItem8';
import { GridItem9 } from './GridItems/GridItem9';

interface Props {
  Title?: string;
  ID?: string;
  Subtitle?: string;
  Copy?: string;
  TitleColour?: string;
  additionalClasses?: string;
  TitleAlignment?: 'center' | 'left';
  Theme?: 'default' | 'teal';
  Items: object;
  ShowDivider: boolean;
}

const getComponent = Item => {
  if (Item.Component === 'GridItem1') {
    return (
      <>
        <GridItem1 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem2') {
    return (
      <>
        <GridItem2 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem3') {
    return (
      <>
        <GridItem3 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem4') {
    return (
      <>
        <GridItem4 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem5') {
    return (
      <>
        <GridItem5 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem6') {
    return (
      <>
        <GridItem6 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem7') {
    return (
      <>
        <GridItem7 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem8') {
    return (
      <>
        <GridItem8 Item={Item} />
      </>
    );
  }
  if (Item.Component === 'GridItem9') {
    return (
      <>
        <GridItem9 Item={Item} />
      </>
    );
  }
};

export const Grid = ({
  ShowDivider = true,
  Title = '',
  Subtitle = '',
  MicroTitle = '',
  Theme = 'default',
  TitleColour = 'default',
  TitleAlignment = '',
  Copy = '',
  ID = '',
  Items = [],
  additionalClasses,
  ...props
}: Props) => {
  return (
    <div {...props} id={ID}>
      <GlobalStyle />
      <div className={'container'}>
        <GridStyles className={` alignment-${TitleAlignment} ${additionalClasses} theme-${Theme}`}>
          {Title || Subtitle ? (
            <>
              {MicroTitle ? <h4 dangerouslySetInnerHTML={createMarkUp(MicroTitle)}></h4> : null}
              {Title ? (
                <h2 className={`color-${TitleColour}`} dangerouslySetInnerHTML={createMarkUp(Title)}></h2>
              ) : null}
              {Subtitle ? <p>{Subtitle}</p> : null}
              {Copy ? <p className={'small'}>{Copy}</p> : null}
              {ShowDivider && <div className={`title-underline `}></div>}
            </>
          ) : null}
          <GridWrap className={additionalClasses}>{Items.map((item, index) => getComponent(item))}</GridWrap>
        </GridStyles>
      </div>
    </div>
  );
};
