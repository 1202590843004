import React from 'react';
import moment from 'moment';

import { PageContainer } from '../components/containers/PageContainer';
import { Footer } from '../components/layouts/Footer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Header } from '../components/layouts/HeaderComponent';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../components/V2/FeaturedNavigation/FeaturedNavigation';
import { CapterraScore } from '../components/V2/CapterraScore/CapterraScore';
import { BackgroundGrey, BackgroundHalfGrey } from '../GlobalStyles';
import { Accordion } from '../components/V2/Accordion/Accordion';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { FullScreenImage } from '../components/V2/FullScreenImage/FullScreenImage';
import { SolutionBlock } from '../components/V2/SolutionBlock/SolutionBlock';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { IconGrid } from '../components/V2/IconGrid/IconGrid';
import { Grid } from '../components/V2/Grid/Grid';
import { ResourceDownloads } from '../components/V2/ResourceDownloads/ResourceDownloads';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

const GeomagicDesignX = () => {
  const Lang = 'en';
  const data = require(`../../data/content/Pages/products/geomagic-designx/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const sitemap = require(`../../data/sitemap-data.json`);

  const AnnoucementEndDate = moment('2023-12-12 17:00:00');
  const shouldDisplayAnnouncement = moment().isBefore(AnnoucementEndDate);
  const vertical = 'NewForm-GDX';

  let additionalClasses = '';
  if (shouldDisplayAnnouncement) {
    additionalClasses = 'additional-padding-top';
  }

  return (
    <PageContainer lang={Lang}>
      <HelmetContainer
        currentPage={'geomagic-designx'}
        image="/assets/images/pages/geomagicDesignX/key-visual-designx.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
      />
      <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

      <LargeHero
        Country={Lang}
        AddSalesTel={true}
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Logo={'geomagic-designx'}
        Copy={data.content.hero.copy}
        Background={'/assets/images/pages/geomagicDesignX/3DReverseEngineeringSoftware.webp'}
        Button={{
          theme: 'gradient-red',
          copy: globalCopy.Buttons.DownloadFreeTrial,
          link: sitemap['geomagic-designx-download'][Lang]
        }}
      />

      <BackgroundGrey>
        <div className={'wrap'}>
          <AnnoucementBar
            AdditionalClass={'offset-bottom increase-z header-margin-0'}
            Image={'https://oqtonadmin.com/wp-content/uploads/2024/03/aerospace-annoucement.png'}
            Content={
              '<h3>From Physical Part to Parametric Model: 3D Scanning Applications in Aerospace</h3><p>Download this whitepaper and discover how companies across the Aerospace industry are leveraging 3D Scanning to design and manufacture better parts, faster</p>'
            }
            Link={{
              label: 'Download whitepaper',
              target: '_blank',
              link: 'https://oqton.com/news/ebooks/3d-scanning-applications-in-aerospace/'
            }}
          />
        </div>

        <FeaturedNavigation
          AdditionalClass={`additional-margin-bottom additional-padding-top`}
          Theme={'gradient-red'}
          Title={data.content.FeaturedNavigation.Title}
          NavItems={[
            {
              Title: data.content.FeaturedNavigation.Nav[0],
              Link: '#overview'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[1],
              Link: '#applications'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[2],
              Link: '#highlights'
            },
            {
              Title: data.content.FeaturedNavigation.Nav[3],
              Link: '#resources'
            }
          ]}
          Copy={data.content.FeaturedNavigation.Copy}
          Image={{
            link: '/assets/images/pages/geomagicDesignX/UltimateScanToCadSolution.webp',
            alt: data.content.FeaturedNavigation.ImageAlt
          }}
        />

        <CapterraScore
          Reviewer={data.content.CapterraScore.Reviewer}
          Review={data.content.CapterraScore.Review}
          Image={'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905'}
        />

        <SplitColumn
          id={'overview'}
          TextColor={'Grey'}
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol1.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicDesignX/ReuseExistingProducts.webp',
            alt: data.content.SpltiCol1.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol2.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicDesignX/SeamlessScannerSupport.webp',
            alt: data.content.SpltiCol2.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Left'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol3.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicDesignX/UnmatchedReverseEngineering.webp',
            alt: data.content.SpltiCol3.ImgAlt
          }}
        />
        <SplitColumn
          TextColor={'Grey'}
          ImagePosition={'Right'}
          ImageOverlap={false}
          ImageBackground={false}
          Copy={data.content.SpltiCol4.Copy}
          Background={'Transparent'}
          Image={{
            src: '/assets/images/pages/geomagicDesignX/EfficientCADCreation.webp',
            alt: data.content.SpltiCol4.ImgAlt
          }}
        />
      </BackgroundGrey>

      <FullScreenImage
        Title={data.content.FullScreenImage.Title}
        Subtitle={data.content.FullScreenImage.Subtitle}
        TextColour={'Red'}
        Background={'/assets/images/components/background-circles.webp'}
        Image={{
          alt: data.content.FullScreenImage.ImgAlt,
          url: '/assets/images/pages/geomagicDesignX/direct-cad-package-integration.webp'
        }}
      />

      <BackgroundGrey>
        <IconGrid
          id={'applications'}
          Theme={'light'}
          Title={data.content.IconGrid.Title}
          Subtitle={data.content.IconGrid.Subtitle}
          Items={data.content.IconGrid.Items}
        />

        <Grid
          ID={'highlights'}
          Title={data.content.Grid.Title}
          Subtitle={data.content.Grid.Subtitle}
          Items={data.content.Grid.Items}
        />

        <ResourceDownloads
          id={'resources'}
          Title={data.content.ResourceDownloads.Title}
          Items={data.content.ResourceDownloads.Items}
        />
      </BackgroundGrey>

      <ArticleBlock
        id={'customer-stories'}
        Title={"Don't take our word for it! Have a look at how successful our customers are:"}
        Layout={'layout-1'}
        Link={{
          copy: 'All Customer Stories',
          link: '/news/?&category=Customer-Story'
        }}
        Items={[
          {
            title: 'Cummins uses Oqton’s Geomagic software',
            link: '/news/customer-stories/cummins-uses-geomagic-to-get-1952-race-car-running-again/',
            image: '/assets/images/pages/geomagicDesignX/news/cummings-uses-oqton-geomagic.webp',
            category: 'Customer Story',
            readTime: ''
          },
          {
            title: 'Design on the fly – 3 examples of 3D scanning accelerating product development',
            link: '/posts/design-on-the-fly-examples-of-3d-scanning-software-accelerating-product-development/',
            image: '/assets/images/pages/geomagicDesignX/news/design-on-the-fly.webp',
            category: 'Customer Story',
            readTime: ''
          },
          {
            title: 'Kindig-it Design builds cooler cars faster with Oqton’s Geomagic Control X and Design X',
            link: '/news/customer-stories/kindig-it-design-builds-cars-faster-with-oqton/',
            image: '/assets/images/pages/geomagicDesignX/news/kindig-it-design-with-geomagic.webp',
            category: 'Customer Story',
            readTime: ''
          }
        ]}
      />

      <BackgroundHalfGrey>
        <HighlightBlock
          Theme={'accent-red'}
          Title={data.content.HighlightBlock.Title}
          Button={{
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-designx-download'][Lang]
          }}
          Image={{
            title: '',
            alt: '',
            url: '/assets/images/pages/geomagicDesignX/3DReverseEngineeringSoftware.webp'
          }}
        />
      </BackgroundHalfGrey>

      <BackgroundGrey>
        <Accordion id="faqs" Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
      </BackgroundGrey>

      <SolutionBlock
        Title={data.content.SolutionBlock.Title}
        Theme={'dark'}
        Items={[
          {
            logo: 'geomagic-controlx',
            title: data.content.SolutionBlock.Items[0],
            link: globalCopy.Navigation.ProductURLs.geoControlX
          },
          {
            logo: 'geomagic-wrap',
            title: data.content.SolutionBlock.Items[1],
            link: globalCopy.Navigation.ProductURLs.geoWrap
          },
          {
            logo: 'geomagic-for-solid',
            title: data.content.SolutionBlock.Items[2],
            link: globalCopy.Navigation.ProductURLs.GeomagicForSolidworks
          }
        ]}
      />

      <Footer />
    </PageContainer>
  );
};

export default GeomagicDesignX;
