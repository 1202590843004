import React from 'react';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BackgroundGrey, BackgroundWhite } from '../GlobalStyles';
import { Header } from '../components/layouts/HeaderComponent';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { Footer } from '../components/layouts/Footer';

import BlogData from '../../data/new-blog-data.json';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { SolutionBlock } from '../components/V2/SolutionBlock/SolutionBlock';

interface SEOData {
  page_title: string;
  page_meta_description: string;
  page_share_image: {
    url: string;
  };
}

interface PartnerPageData {
  title: string;
  fields: {
    seo: SEOData;
  };
}

interface PartnerPageProps {
  pageContext: {
    partnerPageData: PartnerPageData;
  };
}

const PartnerPageTemplate: React.FC<PartnerPageProps> = ({ pageContext: { partnerPageData } }) => {
  if (!partnerPageData || !partnerPageData.fields) {
    return <></>;
  }

  const fields = partnerPageData.fields;

  const Lang = 'en';
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const thankYouPageData = require(`../../data/content/Pages/thank-you/${Lang}.json`);

  let TextColumnButton;
  if (fields.text_and_video_block.add_button) {
    TextColumnButton = {
      copy: fields.text_and_video_block.button_text,
      theme: 'transparent-with-grey-outline',
      link: fields.text_and_video_block.button_link
    };
  }

  let targetSlugs = [];
  fields.news_articles.articles.map((item, index) => {
    const parts = item.link.split('/');

    if (parts[parts.length - 1] === '') {
      parts.pop();
    }

    const slug = parts[parts.length - 1];
    targetSlugs.push(slug);
  });

  const selectedBlogs = BlogData.data.allDibPosts.edges.filter(blog =>
    targetSlugs.some(
      slug => (blog.node.slug && blog.node.slug.includes(slug)) || (blog.node.link && blog.node.link.includes(slug))
    )
  );

  let productGridItems = [];

  thankYouPageData.products.map((item, index) => {
    partnerPageData.fields.product_grid.products.map((product, key) => {
      let visibleProduct = product.product;
      if (visibleProduct === 'geomagic-for-solidworks') {
        visibleProduct = 'geomagic-for-solid';
      }
      if (visibleProduct === 'geomagic-design-x') {
        visibleProduct = 'geomagic-designx';
      }
      if (visibleProduct === 'geomagic-control-x') {
        visibleProduct = 'geomagic-controlx';
      }
      if (visibleProduct === item.Component) {
        productGridItems.push({
          logo: visibleProduct,
          title: item.subtitle,
          copy: item.copy,
          link: item.fixedURL
        });
      }
    });
  });

  return (
    <PageContainer>
      <HelmetContainer
        image={fields.seo.page_share_image.url}
        title={fields.seo.page_title}
        description={fields.seo.page_meta_description}
      />

      <BackgroundGrey>
        <Header />

        <LargeHero
          Lang={'en'}
          AddSalesTel={true}
          BackgroundStyle={'image'}
          Copy={`<h1><b>${fields.hero.title}</b></h1>${fields.hero.copy}`}
          Background={fields.hero.partner_logo.url}
          Button={{
            copy: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true,
            theme: 'primary'
          }}
        />
      </BackgroundGrey>

      <BackgroundWhite>
        {fields.text_and_video_block.add_video_or_image === 'none' && (
          <TextColumns
            Columns={'1'}
            Title={fields.text_and_video_block.title}
            Copy={fields.text_and_video_block.copy}
            Button={TextColumnButton}
          />
        )}

        {fields.text_and_video_block.add_video_or_image === 'video' && (
          <>
            <div className={'spacing-lg'}></div>
            <SplitColumn
              TextSize={'S'}
              Background={'White'}
              ImagePosition={'Right'}
              Copy={`<h3>${fields.text_and_video_block.title}</h3>` + fields.text_and_video_block.copy}
              MediaType={'Video'}
              Video={fields.text_and_video_block.video_url}
              Button={TextColumnButton}
            />
            <div className={'spacing-lg'}></div>
          </>
        )}

        {fields.text_and_video_block.add_video_or_image === 'image' && (
          <>
            <div className={'spacing-lg'}></div>
            <SplitColumn
              TextSize={'S'}
              Background={'White'}
              ImagePosition={'Right'}
              Copy={`<h3>${fields.text_and_video_block.title}</h3>` + fields.text_and_video_block.copy}
              MediaType={'Image'}
              Image={{
                alt: fields.text_and_video_block.image.alt,
                src: fields.text_and_video_block.image.url
              }}
              Button={TextColumnButton}
            />
            <div className={'spacing-lg'}></div>
          </>
        )}
      </BackgroundWhite>

      <BackgroundGrey>
        <ArticleBlock
          Layout={'layout-4'}
          Items={selectedBlogs}
          Link={{
            link: '/news/',
            copy: 'All Resources'
          }}
          Title={fields.news_articles.title}
        />

        <HighlightBlock
          ImageType={'Background'}
          Theme={'Primary'}
          Title={fields.cta_button.copy}
          Button={{
            copy: fields.cta_button.button_copy,
            ModelTitle: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true
          }}
          Image={{
            title: '',
            alt: '',
            url: '/assets/images/partners/cta-bg.png'
          }}
        />

        <div className={'spacing-lg'}></div>
      </BackgroundGrey>

      <SolutionBlock Title={fields.product_grid.title} Theme={'dark'} Items={productGridItems} />

      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default PartnerPageTemplate;
