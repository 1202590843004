import React from 'react';
import { Footer } from '../components/layouts/Footer';
import { HeroButtonLess } from '../components/layouts/Hero/HeroButtonLess';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { RDBold } from '../styles/typography';
import { ListContainer } from '../components/pageComponents/Styles/style';
import {
  HeaderBackground,
  IntroContainer,
  IntroFormContainer,
  SectionTitle,
  Title,
  PackageContainer,
  TrioDisplay,
  TrioLeft,
  TrioRight,
  TrioBottom,
  TinyBlockContainer,
  TinyBlock,
  ListContainerCentered
} from '../components/pageComponents/iamIntroPackages';
import { Header } from '../components/layouts/HeaderComponent';
import { useGetInnerWidth } from '../utils/hooks';
import { Content } from '../components/layouts/PageContainer/PageContainer';
import { CustomerStorySpotlight } from '../components/layouts/CustomerStorySpotlight';
import { PageContainer } from '../components/containers/PageContainer';

const IAMIntroPackage = () => {
  const { innerWidth } = useGetInnerWidth();

  return (
    <PageContainer>
      <HelmetContainer
        image="/assets/images/pages/iamIntroPackages/background-2048.png"
        title="Is your AM business focused on value-adding tasks?"
        pageDescription="All-In-One Software for Industrial Additive Manufacturing"
        description="Prepare, optimize, and 3D print high-quality parts in record time with an all-in-one, integrated 3D additive manufacturing software that streamlines workflow, from design to printing."
      />
      <HeaderBackground screenWidth={innerWidth}>
        <Header hideNavigation />
        <HeroButtonLess
          title="Is your AM business focused on value-adding tasks?"
          //subtitle={<HeaderSubtitle icon="ThreeDXpert" text="3DXpert" />}
          // image={{ src: '/assets/images/pages/iamIntroPackage/hero.png', alt: 'Automated Additive Manufacturing' }}
          description="Test drive Oqton MOS and 3DXpert in one integrated, powerful introduction package"
        />
      </HeaderBackground>
      <Content>
        <IntroContainer>
          <section>
            <SectionTitle>
              <RDBold>Gain insight and full control into your production process</RDBold>
            </SectionTitle>
            <p>
              You can easily connect your existing equipment to get monitoring, dashboards and live alerts with our
              machine agnostic and secure platform. Combining order tracking, lightweighting, build preparation,
              slicing, IOT machine monitoring, and scheduling, Oqton’s <RDBold>MES</RDBold> functionalities help to:
            </p>
            <ListContainer>
              <li>Optimize machine uptime</li>
              <li>Plan and schedule your orders</li>
              <li>Reduce the Total Cost Of Operating (TCO)</li>
            </ListContainer>
            <SectionTitle>
              <RDBold>Remove repetitive tasks</RDBold>
            </SectionTitle>
            <p>
              Your managers don’t want to waste expensive engineering hours for unproductive print iterations. Your
              engineers want to creatively solve problems in the most efficient way, instead of doing the same boring
              thing over and over again. Our AM Introduction Package allows your experts to apply DfAM without dealing
              with mesh data, and to:
            </p>
            <ListContainer>
              <li>Capture their knowledge</li>
              <li>Reduce failure rates</li>
              <li>Focus on value-adding tasks</li>
            </ListContainer>
            <SectionTitle>
              <RDBold>Evolve your company and enter industrial production.</RDBold>
            </SectionTitle>
            <p>
              Learn together with us how Artificial Intelligence (AI) can help you handling large quantities of parts by
              applying machine learning techniques. We can help you scale up with:
            </p>
            <ListContainer>
              <li>Highly-engineered single parts</li>
              <li>Short run production</li>
              <li>Serial manufacturing</li>
            </ListContainer>
          </section>
          <section>
            <IntroFormContainer
              title="Contact form"
              src="https://go.oqton.com/l/976933/2022-07-12/257jx"
              width="100%"
              height="100%"
            />
          </section>
        </IntroContainer>
      </Content>
      <PackageContainer>
        <Content>
          <Title>What’s in the package?</Title>
          <TrioDisplay>
            <TrioLeft>
              <SectionTitle>MES and IoT functionality</SectionTitle>
              <TinyBlockContainer>
                <TinyBlock>
                  <img src="/assets/images/pages/iamIntroPackages/users.svg" alt="5x Users" width="68" height="61" />
                  <p>5X Users</p>
                </TinyBlock>
                <TinyBlock>
                  <img src="/assets/images/pages/iamIntroPackages/os.svg" alt="Gateway Access" width="68" height="61" />
                  <p>Gateway Access</p>
                </TinyBlock>
                <TinyBlock>
                  <img
                    src="/assets/images/pages/iamIntroPackages/machines.webp"
                    alt="4x Machines"
                    width="68"
                    height="61"
                  />
                  <p>4x Machines</p>
                </TinyBlock>
              </TinyBlockContainer>
              <ListContainerCentered>
                <ListContainer>
                  <li>5 users</li>
                  <li>4 printers</li>
                  <li>1 IoT Gateway</li>
                </ListContainer>
              </ListContainerCentered>
            </TrioLeft>
            <TrioRight>
              <SectionTitle>3DXpert Ultimate with Build Simulation</SectionTitle>
              <TinyBlockContainer>
                <TinyBlock>
                  <img src="/assets/images/pages/iamIntroPackages/3dxpert.svg" alt="3dxpert" width="68" height="61" />
                  <p>3DXpert</p>
                </TinyBlock>
                <TinyBlock>
                  <img
                    src="/assets/images/pages/iamIntroPackages/buildsimulation.webp"
                    alt="Build Simulation"
                    width="68"
                    height="61"
                  />
                  <p>Build Simulation</p>
                </TinyBlock>
              </TinyBlockContainer>
              <ListContainerCentered>
                <ListContainer>
                  <li>Build preparation</li>
                  <li>Design for AM</li>
                  <li>Build simulation</li>
                  <li>Part compensation</li>
                  <li>Thermal adjustment</li>
                </ListContainer>
              </ListContainerCentered>
            </TrioRight>
          </TrioDisplay>
          <TrioBottom>
            <TinyBlock>
              <img
                src="/assets/images/pages/iamIntroPackages/training.svg"
                alt="One Day Training"
                width="68"
                height="61"
              />
              <p>One Day Training</p>
            </TinyBlock>
            <TinyBlock>
              <img src="/assets/images/pages/iamIntroPackages/12-months.svg" alt="12 months" width="68" height="68" />
              <p>12 months</p>
            </TinyBlock>
            <TinyBlock>
              <img src="/assets/images/pages/iamIntroPackages/money.svg" alt="$20,000" width="66" height="58" />
              <p>$20,000</p>
            </TinyBlock>
          </TrioBottom>
        </Content>
      </PackageContainer>
      <CustomerStorySpotlight
        title="Customer stories"
        cards={[
          {
            url: '/news/customer-stories/automating-end-to-end-manufacturing/',
            smallText:
              '"Oqton’s cost-effective hardware agnostic Manufacturing OS has quickly enabled us to fully integrate our breadth of printers and post processing systems. With Oqton’s agile development and continuous deployment of enhancements to their Manufacturing OS, PrinterPrezz looks forward to our continued partnership towards achieving our goals."',
            customerName: 'Neel Kumar, Design Engineering Manager, PrinterPrezz',
            image: {
              src: '/assets/images/customerStories/emerson/IMG-04-small.png',
              alt: 'customer story',
              position: 'bottom'
            },
            hideLink: true
          },
          {
            url: '/news/customer-stories/emerson-greatly-improves-am-efficiency/',
            smallText:
              '"3DXpert streamlined our process and workflow greatly. We eliminated a ton of in-and-out of software operations, which has helped us reach our goals of maintaining top data integrity and eliminating redundancy from our workflow. Beyond that, the support we have gotten from the 3DXpert team is simply first-class."',
            customerName: 'Tom Gabriel, R&D Manager, Additive Manufacturing, Emerson Automation Solutions',
            image: {
              src: '/assets/images/customerStories/printerPrezz/IMG-01-small.png',
              alt: 'customer story',
              position: 'bottom'
            },
            hideLink: true
          }
        ]}
      />
      <Footer />
    </PageContainer>
  );
};

export default IAMIntroPackage;
