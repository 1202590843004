import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { InnovationIllustrationStyles, Inner, Item } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  id?: string;
  Copy?: string;
  Title?: string;
  Items?: object;
}

export const InnovationIllustrationBlock = ({ id = '', Title = '', Copy = '', Items = [], ...props }: Props) => {
  console.log(Items);

  return (
    <InnovationIllustrationStyles>
      <GlobalStyle />
      <div className={'container'}>
        <h2>{Title}</h2>
        <p className={'copy'}>{Copy}</p>

        <div className={'wrapper'}>
          <Inner>
            <div className={'column'}>
              <Item className={'energy'}>
                <img loading="lazy" src={'/assets/images/homepage-v2/industry_innovation-energy.png'} />
                <a className={'inner'} href={Items.energy.fixedUrl}>
                  <h3>{Items.energy.title}</h3>
                  <p dangerouslySetInnerHTML={createMarkUp(Items.energy.copy)}></p>
                  <div className={'fakeBtn'}></div>
                </a>
              </Item>
            </div>
            <div className={'column'}>
              <Item className={'healthcare'}>
                <img alt="" loading="lazy" src={'/assets/images/homepage-v2/industry_innovation-medical.png'} />
                <a className={'inner'} href={Items.healthcare.fixedUrl}>
                  <h3>{Items.healthcare.title}</h3>
                  <p dangerouslySetInnerHTML={createMarkUp(Items.healthcare.copy)}></p>
                  <div className={'fakeBtn'}></div>
                </a>
              </Item>
              <Item className={'automotive'}>
                <img alt="" loading="lazy" src={'/assets/images/homepage-v2/industry_innovation-automotive.png'} />
                <a className={'inner'} href={Items.automotive.fixedUrl}>
                  <h3>{Items.automotive.title}</h3>
                  <p dangerouslySetInnerHTML={createMarkUp(Items.automotive.copy)}></p>
                  <div className={'fakeBtn'}></div>
                </a>
              </Item>
            </div>
            <div className={'column'}>
              <Item className={'aerospace'} href={Items.aerospace.fixedUrl}>
                <img alt="" loading="lazy" src={'/assets/images/homepage-v2/industry_innovation-aerospace.png'} />
                <a className={'inner'} href={Items.aerospace.fixedUrl}>
                  <h3>{Items.aerospace.title}</h3>
                  <p dangerouslySetInnerHTML={createMarkUp(Items.aerospace.copy)}></p>
                  <div className={'fakeBtn'}></div>
                </a>
              </Item>
              <Item className={'industrial'} href={Items.industrial.fixedUrl}>
                <img alt="" loading="lazy" src={'/assets/images/homepage-v2/industry_innovation-industrial.png'} />
                <a className={'inner'} href={Items.industrial.fixedUrl}>
                  <h3>{Items.industrial.title}</h3>
                  <p dangerouslySetInnerHTML={createMarkUp(Items.industrial.copy)}></p>
                  <div className={'fakeBtn'}></div>
                </a>
              </Item>
            </div>
          </Inner>
        </div>
      </div>
    </InnovationIllustrationStyles>
  );
};
