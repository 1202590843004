import React, { useEffect, useRef } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { ButtonComponent } from '../Button/Button';
import { createMarkUp } from '../helpers';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { SalesTel } from '../Atoms/SalesTel/SalesTel';

import { LargeHeroStyles, CopyWrapper, CardWrapper } from './styles-ABTest.jsx';

interface Props {
  AdditionalClasses?: string;
  Copy?: string;
  Button?: {};
  BackgroundColour?: 'white' | 'off-white';
  Country?: 'en' | 'fr' | 'uk' | 'de' | 'it' | 'ko';
  BackgroundStyle?: 'bottom-right' | 'center-right' | 'cover' | 'image';
  AddSalesTel?: boolean;
  Logo?:
    | null
    | 'manufacturing-os'
    | 'geomagic-designx'
    | 'geomagic-controlx'
    | 'geomagic-freeform'
    | 'geomagic-wrap'
    | '3dxpert'
    | 'amphyon'
    | 'd2p'
    | 'geomagic-for-solid';
  Background?: string;
  SecondaryLogo?: string;
}

export const LargeHeroABTest = ({
  AddSalesTel = false,
  AdditionalClasses,
  SecondaryLogo,
  Button,
  Logo,
  Country,
  Background,
  BackgroundColour = 'white',
  BackgroundStyle = 'bottom-right',
  Copy = '<h1>Title</h1><p>Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy Copy</p>',
  ...props
}: Props) => {
  const scrollDivRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const globalCopy = require(`../../../../data/content/Global/${Country}.json`);

  console.log(globalCopy);
  useEffect(() => {
    const scrollDiv = scrollDivRef.current;

    const scrollRight = () => {
      if (scrollDiv) {
        const scrollAmount = 1;
        const scrollDelay = 20;
        const scrollWidth = scrollDiv.scrollWidth;
        let scrollPosition = 0;

        scrollIntervalRef.current = setInterval(() => {
          if (scrollPosition < scrollWidth) {
            scrollDiv.scrollLeft += scrollAmount;
            scrollPosition += scrollAmount;
          } else {
            clearInterval(scrollIntervalRef.current);
          }
        }, scrollDelay);

        scrollDiv.addEventListener('touchstart', stopAutoScroll);
      }

      setTimeout(stopAutoScroll, 10000); //If function does continue to run, it will stop after 10s
    };

    const stopAutoScroll = () => {
      clearInterval(scrollIntervalRef.current);
      if (scrollDiv) {
        scrollDiv.removeEventListener('touchstart', stopAutoScroll);
      }
    };

    scrollRight();

    return () => {
      clearInterval(scrollIntervalRef.current);
      if (scrollDiv) {
        scrollDiv.removeEventListener('touchstart', stopAutoScroll);
      }
    };
  }, []);

  return (
    <LargeHeroStyles
      className={`HomepageVarient Component_LargeHero background-colour-${BackgroundColour} background-style-${BackgroundStyle} ${AdditionalClasses}`}
      {...props}
    >
      {Background && BackgroundStyle !== 'image' && (
        <div
          className={'background background-style-' + BackgroundStyle}
          style={{ backgroundImage: 'url(' + Background + ')' }}
        ></div>
      )}
      <GlobalStyle />
      <div className={'container'}>
        <div className={'copy-wrap'}>
          {Logo && <ProductLogo Logo={Logo} />}
          <CopyWrapper className={AdditionalClasses} dangerouslySetInnerHTML={createMarkUp(Copy)}></CopyWrapper>
          <div className={'button-wrap'}>
            {Button && (
              <ButtonComponent
                ModelTitle={Button.ModelTitle}
                ModelVertical={Button.ModelVertical}
                Target={Button.target}
                Theme={Button.theme}
                Label={Button.copy}
                Link={Button.link}
                OpenDemoModel={Button.OpenDemoModel}
              />
            )}
            {AddSalesTel && <SalesTel Country={Country} />}
            {(() => {
              switch (Logo) {
                case 'geomagic-for-solid':
                  return (
                    <img
                      alt=""
                      className={'secondary-logo'}
                      src="/assets/images/pages/geomagic-for-solidworks/gold_solidworks_badge.png"
                    />
                  );
                default:
                  return '';
              }
            })()}
          </div>
          {SecondaryLogo && (
            <img className="secondaryLogo" src={SecondaryLogo.Logo} alt={SecondaryLogo.Alt} loading="lazy" />
          )}
        </div>

        {Background && BackgroundStyle === 'image' && (
          <div className={'image'}>
            <img src={Background} alt={''} />
          </div>
        )}
      </div>

      <CardWrapper>
        <div id={'scrollDiv'} className={'inner'} ref={scrollDivRef}>
          {globalCopy.Navigation.IndustriesSubMenu.map((item, index) => (
            <a href={item.fixedUrl} key={index}>
              <img alt={item.title} src={`/assets/2024/homepage-cards/${Country}/${item.slug.toLowerCase()}.png`} />
            </a>
          ))}
        </div>
      </CardWrapper>
    </LargeHeroStyles>
  );
};
