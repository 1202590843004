import React from 'react';

interface ParagraphProps {
  title?: string | React.ReactNode;
  intro?: string | React.ReactNode;
  text?: string | React.ReactNode;
  link?: string;
  linkText?: string;
}

export const Paragraph = ({ title, intro, text, link, linkText }: ParagraphProps) => {
  return (
    <>
      <div>
        {title && <h3>{title}</h3>}
        {intro && <p>{intro}</p>}
        {text && <p>{text}</p>}
        {link && <a href={link}>{linkText}</a>}
      </div>
    </>
  );
};
