import React from 'react';
import styled from 'styled-components';
import {
  spacing,
  colors,
  media,
  fontSizes,
  newLineHeight as lineHeight,
  newLetterSpacing as letterSpacing,
  newFontWeights as fontWeights
} from '../../../../foundations';

interface ListProps {
  items: Array<string | React.ReactNode>;
}

const ListContainer = styled.ul``;

export const ListItem = styled.li`
  /* Safari 11+ */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      margin-bottom: ${spacing.xxl}px;
      margin-top: ${spacing.xxl}px;
    }
  }
  @-moz-document url-prefix() {
    margin-bottom: ${spacing.xxl}px;
    margin-top: ${spacing.xxl}px;
  }
  color: ${colors.quinary};
  font-size: ${fontSizes.ml};
  font-weight: ${fontWeights.book};
  line-height: ${lineHeight.ml};
  letter-spacing: ${letterSpacing.xxl};
`;

const ListItemContent = styled.div`
  margin: ${spacing.m}px 0 0 ${spacing.l}px;
`;

export const List = ({ items }: ListProps) => {
  return (
    <ListContainer>
      {items.map(item => (
        <ListItem>
          <ListItemContent>{item}</ListItemContent>
        </ListItem>
      ))}
    </ListContainer>
  );
};
