import React from 'react';
import HomepageTemplate from '../../templates/HomepageTemplate';

const HomePageJSON = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'Corporation',
  name: 'Oqton',
  url: 'https://oqton.com/',
  logo: 'https://oqton.com/assets/images/DarkOqton.svg',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '1-803-981-6716',
      contactType: 'sales',
      areaServed: 'US',
      availableLanguage: 'en'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+441442279875',
      contactType: 'sales',
      areaServed: 'GB',
      availableLanguage: 'en'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+4961053248122',
      contactType: 'sales',
      areaServed: 'DE',
      availableLanguage: 'German'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+39800035847',
      contactType: 'sales',
      areaServed: 'IT',
      availableLanguage: 'Italian'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+33805103572',
      contactType: 'sales',
      areaServed: 'FR',
      availableLanguage: 'fr'
    }
  ],
  sameAs: [
    'https://twitter.com/oqton',
    'https://www.youtube.com/@Oqton',
    'https://www.linkedin.com/company/oqton/',
    'https://oqton.com/'
  ]
});
const HomeRD = () => {
  return <HomepageTemplate Lang={'ja'} />;
};

export default HomeRD;
