import React from 'react';
import { colorsRD } from '../components/foundations';
import { CustomerStorySpotlight } from '../components/layouts/CustomerStorySpotlight';
import { Footer } from '../components/layouts/Footer';
import { Hero } from '../components/layouts/Hero';
import { MediaComponent } from '../components/layouts/MediaComponent';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { ListContainer } from '../components/pageComponents/Styles/style';
import { Resources } from '../components/layouts/Resources';
import { PrinterOverview } from '../components/pageComponents/PrinterOverview';
import { Demo } from '../components/layouts/Demo';
import { HeaderBackground } from '../components/pageComponents/header';
import { Header } from '../components/layouts/HeaderComponent';
import { HeaderSubtitle } from '../components/subComponents/HeaderSubtitle';
import { Disclaimer } from '../components/layouts/PageContent/PageContentStyles';
import { PageContainer } from '../components/containers/PageContainer';

const D2P = () => {
  return (
    <PageContainer>
      <HelmetContainer
        image="/assets/images/pages/d2p/hero.png"
        title="3D Medical Imaging Software"
        pageDescription="Dicom to Print - 3D Medical Image Processing Software | Developed by Oqtone"
        description="Dicom to Print - 3D Medical Image Processing Software | Developed by Oqton"
        currentPage={'d2p'}
      />
      <HeaderBackground>
        <Header />
        <Hero
          title="<b>3D Medical Image Processing Software</b>"
          subtitle={<HeaderSubtitle icon="D2P" text="Dicom to print" />}
          announcement={
            'Announcement: D2P is now developed and supported by Oqton, following the acquisition of Oqton by 3D Systems.'
          }
          announcementUrl="/news/geomagic-3dxpert-and-amphyon-now-developed-by-oqton/"
          image={{ src: '/assets/images/pages/d2p/hero.png', alt: 'D2P Hero' }}
          description="FDA 510(k)-cleared D2P® software and 3D Systems printers allow surgeons, radiologists, lab technicians, and device designers to create diagnostic-quality digital 3D models and physical 3D prints easily and quickly."
          buttons={[
            { text: 'Request a free trial', vertical: 'd2p' }
            // { text: 'Contact us', vertical: 'buyD2P', color: colorsRD.primary }
          ]}
          phone
        />
      </HeaderBackground>
      <MediaComponent
        articles={[
          {
            title: 'Minimizing the <b>Need for Technical Expertise</b> with D2P',
            content: (
              <React.Fragment>
                <p>
                  DICOM images are imported into the D2P stand-alone modular software package that consolidates all 3D
                  model segmentation and preparation steps into one workstation. D2P is a cleared medical device
                  intended for pre-operative surgical planning and the production of 3D-printed anatomic models for
                  diagnostic purposes.
                </p>
                <br />
                <p>
                  The unique image segmentation tool set and its advanced virtual reality (VR) visualization allow
                  clinicians and point-of-care (POC) staff to minimize the effort and time associated with the creation
                  of patient-specific anatomic models.
                </p>
                <br />
                <p>
                  Digital models exported from the D2P software can be used in a wide variety of applications including
                  3D printers, VR devices, surgical planning software, and CAD software.
                </p>
              </React.Fragment>
            ),
            image: { src: '/assets/images/pages/d2p/media_1.png', alt: 'Placeholder', imagePos: 'right' }
          },
          {
            content: (
              <React.Fragment>
                <p>
                  While D2P will export digital files compatible with 3D printers from most manufacturers, the
                  combination of D2P and 3D Systems printers offers a 510(K)-cleared solution that allows users to 3D
                  print diagnostic-quality models. When printed using selected materials, these 3D models can be
                  sterilized and are biocompatible. See the D2P regulatory information page for more information on the
                  cleared printers and materials.
                </p>
                <br />
                <p>
                  Oqton offers the largest range of 3D printing technologies cleared for manufacture of
                  diagnostic-quality physical models across the widest range of medical specialties.
                </p>
                <br />
                <p>
                  Buying software and hardware from a single source gives you peace of mind that software and hardware
                  is guaranteed to work together.
                </p>
              </React.Fragment>
            ),
            image: { src: '/assets/images/pages/d2p/media_2.png', alt: 'Placeholder', imagePos: 'left' }
          }
        ]}
      />
      <MediaComponent
        inverted
        articles={[
          {
            title: 'Software <b>features</b>',
            content: (
              <ListContainer className={'inverted'}>
                <li>Segmentation for craniomaxillofacial (CMF)</li>
                <li>DICOM to print workflow</li>
                <li>VR rendering</li>
                <li>Automatic vasculature segmentation tools</li>
                <li>3D printing preparation for mesh models</li>
              </ListContainer>
            ),
            image: { src: '/assets/images/pages/d2p/media_3.png', alt: 'Placeholder', imagePos: 'left' }
          }
        ]}
      />
      <Resources
        title="Resources"
        description="Learn more about D2P and discover what’s new in the latest version."
        resources={[
          {
            title: 'D2P Overview Brochure',
            label: 'brochure',
            withBackground: true,
            link: '/assets/images/pages/d2p/d2p-brochure-22V1.pdf'
          },
          {
            title: 'What’s New in D2P 2023',
            label: 'flyer',
            link: 'https://go.oqton.com/l/976933/2023-07-31/bqgl1/976933/1690802339I5CcfL1v/DCD_00284__D2P_WhatsNew_Summer2023_Digital.pdf'
          },
          {
            title: 'Hardware Specifications for D2P',
            label: 'flyer',
            link: '/assets/images/pages/d2p/SPEC-0419_C_D2P_System Requirements.pdf'
          }
        ]}
      />
      <PrinterOverview />
      <CustomerStorySpotlight
        title="D2P customer story"
        cards={[
          {
            smallText:
              '“Using D2P software, we were able to segment the replicate structures out of the patient’s CT scan and send it for printing in our 3D lab.”',
            customerName: 'Dr. Adib Zoabi, Craniomaxillofacial Fellow',
            image: { src: '/assets/images/pages/d2p/customer_story_1.png', alt: 'placeholder', position: 'bottom' },
            hideLink: true
          }
        ]}
      />
      <Demo
        title="<b>Request a free DICOM to PRINT <br /> software trial</b>"
        description="Submit your details to request a free D2P software trial"
        buttonText="Request a trial"
      />
      <MediaComponent
        articles={[
          {
            content: <Disclaimer>Internal reference documents: MM-1299, MM-1297, MM-1298, MM1299, MM-1300</Disclaimer>
          }
        ]}
      />

      <Footer />
    </PageContainer>
  );
};

export default D2P;
