import React, { useState } from 'react';
import YouTubePlayer from 'react-player/youtube';
import { Icon } from '../../subComponents/Icon';
import { Button, ButtonText, Popup } from './styles';

interface VideoButtonProps {
  platform: 'youtube';
  url: string;
  open: boolean;
  onClose: () => void;
}

const VideoContainer = ({ platform, url, open, onClose }: VideoButtonProps) => {
  if (open) {
    if (platform === 'youtube')
      return (
        <Popup onClick={onClose}>
          <YouTubePlayer url={url} controls />
        </Popup>
      );
  }

  return null;
};

export const VideoButton = ({ platform, url }: Omit<VideoButtonProps, 'open' | 'onClose'>) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <ButtonText>Watch video</ButtonText>
        <Icon ml="12px" name="ArrowRight" />
      </Button>
      <VideoContainer open={open} onClose={() => setOpen(false)} url={url} platform={platform} />
    </>
  );
};
