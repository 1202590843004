import React from 'react';
import styled from 'styled-components';

import { spacing, colors, media } from '../../../../foundations';
import { HeadingXS, Quote as QuoteStyle, SubText } from '../../../../../styles/typography';

interface QuoteProps {
  text: string;
  img?: string;
  name?: string;
  title?: string;
}

const QuoteContainer = styled.section<{ hasImage: boolean }>`
  margin: ${spacing.xxl}px auto ${spacing.xxxl}px auto;
  width: 716px;

  ${({ hasImage }) =>
    hasImage
      ? `
  display: grid;
  grid-template-columns: 128px 540px;
  grid-template-areas: 'image textContainer';
  gap: 0 ${spacing.xxl}px;
  margin-bottom: ${spacing.xxxxl}px;
  `
      : `
      display: Flex;
      justify-content: center;
      `}

  @media screen and (max-width: ${media.md}px) {
    width: auto;
    margin: ${spacing.xxl}px ${spacing.l}px ${spacing.xxxl}px ${spacing.l}px;

    ${({ hasImage }) =>
      hasImage
        ? `
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'image' 'textContainer';
  gap: ${spacing.xxl}px 0;
  `
        : ``}
  }
`;

const ContentContainer = styled.article<{ hasImage: boolean }>`
  grid-area: textContainer;

  width: 540px;

  display: flex;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

const TextContainer = styled.div``;

const BorderContainer = styled.div`
  border-left: solid 2px ${colors.accent};
  padding-left: ${spacing.l}px;
  margin: ${spacing.s}px 0;
`;

const QuoteText = styled.p`
  ${QuoteStyle};
  quotes: '“' '”';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;
const ClientImage = styled.img`
  @media screen and (max-width: ${media.md}px) {
    margin: 0 auto;
  }
`;
const ClientName = styled.p`
  ${HeadingXS};

  margin-top: ${spacing.m}px;
`;
const ClientTitle = styled.p`
  ${SubText};

  margin-top: ${spacing.xxs}px;
`;

export const Quote = ({ text, img, name, title }: QuoteProps) => {
  return (
    <QuoteContainer hasImage={!!img}>
      {img && <ClientImage src={img} alt={name} />}
      <ContentContainer hasImage={!!img}>
        {!img && <BorderContainer />}
        <TextContainer>
          <QuoteText>{text}</QuoteText>
          {name && <ClientName>{name}</ClientName>}
          {title && <ClientTitle>{title}</ClientTitle>}
        </TextContainer>
      </ContentContainer>
    </QuoteContainer>
  );
};
