import React, { useContext, useState } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { ProductLogo } from '../ProductLogo/ProductLogo';
import { ButtonComponent } from '../Button/Button';

import { DiscoverBlock, Hero, Panel, WhatToExpect, MainContent } from './styles.jsx';

interface Props {}

export const FreeTrial = ({ ...props }: Props) => {
  const [activeItem, setActiveItem] = useState(null);
  const vertical = 'NewForm-3dxpert';

  return (
    <>
      <Hero>
        <div className={'container'}>
          <div className={'text-container'}>
            <ProductLogo Logo={'3dxpert'} Theme={'outline'} Size={'xl'} />
            <h1>
              The <span>only</span> end-to-end software solution for industrial AM production
            </h1>
            <p>Discover how our production-grade software can transform your business today.</p>
            <ButtonComponent
              OpenDemoModel={true}
              ModelVertical={vertical}
              ModelTitle={'Get your free trial'}
              Label={'Get your free trial now'}
            />
          </div>
          <img className={'hero-image'} src={'/assets/images/3dxpert/3dxpert-free-trial.png'} />
        </div>
      </Hero>
      <MainContent>
        <Panel>
          <div className={'container'}>
            <div className={'inner'}>
              <div className={'text-wrap'}>
                <h2>3DXpert. Additive manufacturing software trusted by global manufacturers.</h2>
                <p>
                  “There were times when we were slicing files and it would take a day and a half…. with 3DXpert the
                  same operation takes just a couple minutes.”
                </p>
                <div className={'review-wrap'}>
                  {/*<img src={'/assets/images/3dxpert/jason-reuther.png'} />*/}
                  <p>
                    <b>Jason Reuther</b>
                    <br />
                    Powder Bed Fusion Design Specialist
                    <br />
                    <b>Emerson Automation Solutions</b>
                  </p>
                </div>
              </div>
              <div className={'logo-wrap'}>
                <img src={'/assets/images/3dxpert/company-logos/cummins.png'} />
                <img src={'/assets/images/3dxpert/company-logos/rafael.png'} />
                <img src={'/assets/images/3dxpert/company-logos/medtech.png'} />
                <img src={'/assets/images/3dxpert/company-logos/addman.png'} />
                <img src={'/assets/images/3dxpert/company-logos/bhughes.png'} />
                <img src={'/assets/images/3dxpert/company-logos/mti.png'} />
                <img src={'/assets/images/3dxpert/company-logos/emerson.png'} />
                <img src={'/assets/images/3dxpert/company-logos/alpine.png'} />
                <img src={'/assets/images/3dxpert/company-logos/trumpf.png'} />
              </div>
            </div>
          </div>
        </Panel>
        <WhatToExpect>
          <div className={'container'}>
            <div className={'key-visual'}>
              <img src={'/assets/images/3dxpert/key-visual.png'} />
            </div>
            <div className={'text-primary'}>
              <div>
                <h2>What to expect</h2>
                <hr className={'divider teal'} />
                <ul>
                  <li>Production-grade software developed in collaboration with manufacturers.</li>
                  <li>Software open to ALL printers.</li>
                  <li>Advanced DfAM, Build Preparation, Simulation & Build Inspection.</li>
                  <li>Comprehensive training and 24/7 support.</li>
                </ul>
                <ButtonComponent
                  ModelVertical={vertical}
                  ModelTitle={'Get your free trial now'}
                  OpenDemoModel={true}
                  Label={'Get your free trial now'}
                  Theme={'3dxpert'}
                />
              </div>
            </div>
          </div>
        </WhatToExpect>
        <DiscoverBlock>
          <div className={'container'}>
            <div className={'text'}>
              <h2>Discover how our production-grade software can transform your business today</h2>
              <ButtonComponent
                ModelVertical={vertical}
                ModelTitle={'Get your free trial now'}
                OpenDemoModel={true}
                Label={'Get your free trial now'}
                Theme={'primary'}
              />
            </div>
          </div>
        </DiscoverBlock>
      </MainContent>
    </>
  );
};
