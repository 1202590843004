import React, { useEffect, useRef } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Grid, MapStyles, MapWrap } from './styles.jsx';
import { ButtonComponent } from '../Button/Button';
import moment from 'moment/moment';
import customMarkerIcon from '../../../../static/assets/icons/map-pin.svg'; // Import your custom marker icon
import customPartnerIcon from '../../../../static/assets/icons/map-pin-partner.svg'; // Import your custom marker icon
import { createMarkUp } from '../helpers';
import { snazzyMapStyles } from './MapHelper';

interface Props {
  eventData: object;
  booth: string;
  address: string;
  lat: string;
  lng: string;
}

export const GoogleMapsComponent: React.FC<Props> = ({ eventData, booth, lng, lat, address, ...props }) => {
  const center = { lat: parseFloat(lat), lng: parseFloat(lng) };
  const zoom = 15;

  const generateGoogleMapsURL = address => {
    const baseURL = 'https://www.google.com/maps/search/?api=1&query=';
    const encodedAddress = encodeURIComponent(address).replace(/%20/g, '+');
    return baseURL + encodedAddress;
  };

  return (
    <MapWrap {...props}>
      <div className="container">
        <h2>
          <span>Useful Information</span>
        </h2>

        <Grid>
          {booth &&
            booth.booth && ( // Check if booth object exists
              <>
                <div>
                  <h4>Find us at</h4>
                </div>
                <div className={'location-booth-data'}>
                  {booth.hall && <span>Hall {booth.hall}</span>}
                  {booth.booth && <span>Booth {booth.booth}</span>}
                </div>
              </>
            )}

          {eventData.speakers && eventData.speakers.speakers.length > 0 && (
            <div>
              <h4>Speaking Engagement</h4>
            </div>
          )}
          {eventData.speakers && eventData.speakers.speakers.length && (
            <div className={'speakers'}>
              {eventData.speakers.speakers &&
                eventData.speakers.speakers.map((speaker, index) => {
                  const time = moment(speaker.date).format('MMMM Do - h:mm a');
                  console.log(speaker.link);
                  if (speaker.link) {
                    return (
                      <a className="speaker" href={speaker.link} key={index} target={'_blank'}>
                        {speaker.title}
                        <br /> <span className={'name'}>{speaker.speaker}</span>
                        {time != 'Invalid date' && (
                          <>
                            <br />
                            <span className={'time'}>
                              <img src={'/assets/icons/clock-red.svg'} />
                              {time}
                            </span>
                          </>
                        )}
                      </a>
                    );
                  } else {
                    return (
                      <div className="speaker" key={index}>
                        {speaker.title}
                        <br /> <span className={'name'}>{speaker.speaker}</span>
                        {time != 'Invalid date' && (
                          <>
                            <br />
                            <span className={'time'}>
                              <img src={'/assets/icons/clock-red.svg'} />
                              {time}
                            </span>
                          </>
                        )}
                      </div>
                    );
                  }
                })}

              {eventData.speakers.event_speakers_link && (
                <ButtonComponent
                  Label={'Event Speakers & Schedule'}
                  Icon={'arrow-right'}
                  Theme={'stripped'}
                  Link={eventData.speakers.event_speakers_link}
                />
              )}
            </div>
          )}

          <div>
            <h4>Venue</h4>
          </div>
          <div className={'location'}>
            <a target={'_blank'} href={generateGoogleMapsURL(address)}>
              <img src={'/assets/icons/red-location.svg'} />
              {address}
            </a>
          </div>
        </Grid>

        <Wrapper apiKey={'AIzaSyAFe3RwU_KsL6I4X4FA-G0cg5U52hg81KI'}>
          <MyMapComponent center={center} zoom={zoom} isPartnerEvent={eventData.type === 'Partner Event'} />
        </Wrapper>
      </div>
      <div className={'spacing-lg'}></div>
      <div className="container">
        {eventData.exhibiting_partners && (
          <>
            <div>
              {eventData.exhibiting_partners.title && (
                <h2>
                  <span>{eventData.exhibiting_partners.title}</span>
                </h2>
              )}
              {eventData.exhibiting_partners.copy && <p>{eventData.exhibiting_partners.copy}</p>}
            </div>
          </>
        )}

        <Grid>
          {eventData.exhibiting_partners &&
            eventData.exhibiting_partners.partners &&
            eventData.exhibiting_partners.partners.map((partner, index) => {
              return (
                <>
                  <div className={`partner-logo-wrap`}>
                    <img className="partner-logo" src={partner.logo} />
                  </div>
                  <div className={'partner-description'}>
                    <h4>{partner.name}</h4>
                    <span dangerouslySetInnerHTML={createMarkUp(partner.description)}></span>
                    <div className={'btn-wrap'}>
                      <ButtonComponent
                        Theme={'accent-teal'}
                        Label={'Contact Partner'}
                        Link={partner.contact_partner_link}
                      />
                    </div>
                  </div>
                  <div>
                    <h4>Find them at:</h4>
                  </div>
                  <div>
                    <div className={'location-booth-data'}>
                      {partner.hall && <span>Hall {partner.hall}</span>}
                      {partner.booth_number && <span>Booth {partner.booth_number}</span>}
                    </div>
                  </div>
                  <div className={'partner-divider'}></div>
                </>
              );
            })}
        </Grid>
      </div>
    </MapWrap>
  );
};

interface MapProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  isPartnerEvent: boolean;
}

export const MyMapComponent: React.FC<MapProps> = ({ center, zoom, isPartnerEvent }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const mapInstance = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        styles: snazzyMapStyles
      });

      let icon = customMarkerIcon;
      if (isPartnerEvent) {
        icon = customPartnerIcon;
      }
      // Create a marker and set it on the map
      new window.google.maps.Marker({
        position: center, // Position of the marker
        map: mapInstance, // Associate the marker with the map instance
        icon: icon // Use your custom marker icon
      });
    }
  }, [center, zoom]);

  return <MapStyles ref={ref} id="map" />;
};
