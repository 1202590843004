import styled from 'styled-components';
import { RDButton } from '../../../styles/typography';
import { colorsRD, spacing } from '../../foundations';

export const Popup = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  color: ${colorsRD.primary};
`;

export const ButtonText = styled.p`
  ${RDButton};

  width: fit-content;
  height: min-content;
  padding-bottom: ${spacing.xs}px;
  border-bottom: solid 1px ${colorsRD.accent.passion};
`;
