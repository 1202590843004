import React, { useContext, useEffect, useState } from 'react';
import { WebinarContextProvider } from './../context/WebinarContext';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { NewsSubNavigation } from '../components/V2/News/NewsSubNavigation/NewsSubNavigation';
import { WebinarGrid, NewsHRDivider } from '../styles/newsStyles';
import { TagsWrap } from '../components/V2/News/TagsWrap/TagsWrap';
import { FormContainerTaller } from '../components/pageComponents/contact/ContactStyles';

import { createMarkUp } from '../components/V2/helpers';

interface Props {
  Lang?: string;
  pageContext?: {
    webinar: any;
  };
}

const SingleWebinarTemplate = ({ Lang = 'en', pageContext, ...props }: Props) => {
  const webinarData = pageContext?.webinar;

  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const vertical = '';

  const [activeForm, setActiveForm] = useState(false);
  const [activeVideo, setActiveVideo] = useState(false);

  useEffect(() => {
    if (props.location.search.substring(306, 307) === 'B' || webinarData.fields.gated_video === false) {
      setActiveVideo(true);
    } else {
      setActiveForm(true);
    }
  }, [props]);

  let headerLang = 'en';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    headerLang = 'zh-cn';
  }

  if (webinarData) {
    return (
      <WebinarContextProvider webinarData={webinarData}>
        <HelmetContainer
          lang={headerLang}
          title={webinarData.title}
          image={webinarData.fields.share_image.page_share_image.url}
        />

        <PageContainer lang={headerLang}>
          <Header lang={headerLang} />

          <NewsSubNavigation />

          <div className={'container'}>
            <WebinarGrid>
              <div>
                <h1>{webinarData.title}</h1>
                <hr />

                <div className="copy" dangerouslySetInnerHTML={createMarkUp(webinarData.fields.content)}></div>
              </div>

              <div>
                {activeVideo && (
                  <>
                    <iframe src={webinarData.fields.video_url} />
                    {webinarData.fields.secondary_video_url && <iframe src={webinarData.fields.secondary_video_url} />}
                  </>
                )}

                {activeForm && (
                  <FormContainerTaller
                    title="Webinar Form"
                    src={webinarData.fields.form_url}
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </WebinarGrid>

            <NewsHRDivider />
            <TagsWrap Categories={webinarData.tags} />
          </div>
        </PageContainer>
        <Footer lang={Lang} />
      </WebinarContextProvider>
    );
  } else {
    return null;
  }
};

export default SingleWebinarTemplate;
