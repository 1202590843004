import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { media } from '../../../../foundations';

const VideoContainer = styled.div`
  width: min-content;
  margin: 0 auto;
  max-width: 960px;
  aspect-ratio: 4 / 2.5;
  @media screen and (max-width: ${media.lg}px) {
    width: 95%;
    min-height: 300px;
    div:first-child {
      width: 390px !important;
    }
  }
`;

export const Video = ({ url }: { url: string }) => {
  return (
    <VideoContainer>
      <ReactPlayer url={url} width={744} height={418} />
    </VideoContainer>
  );
};
