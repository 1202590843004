import React, { useContext, useEffect, useState } from 'react';

import { PostContextProvider } from './../context/PostContext';

import { graphql } from 'gatsby';
import { removeSquareBrackets } from './../helpers/helper';
import BlogJobTitles from '../../data/blog-job-titles';
import BlogVideoJSON from '../../data/blog-video-schema';
import BlogCategories from '../../data/blog-categories.json';
import { SocialShareButtons } from '../components/V2/News/SocialShareButtons/SocialShareButtons';
import { TagsWrap } from '../components/V2/News/TagsWrap/TagsWrap';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';

import {
  SingleNewsWrapper,
  SingleNewsMainContent,
  SingleNewsSideColumn,
  SingleNewsContent,
  NewsHRDivider
} from '../styles/newsStyles.jsx';

import GlobalStyle from '../GlobalStyles';

// * Components
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { NewsSubNavigation } from '../components/V2/News/NewsSubNavigation/NewsSubNavigation';
import { BlogData } from '../components/V2/News/BlogData/BlogData';
import { FeaturedPartner } from '../components/V2/News/FeaturedPartner/FeaturedPartner';
import { RelatedPosts } from '../components/V2/News/RelatedPosts/RelatedPosts';
import { SidebarTopics } from '../components/V2/News/SidebarTopics/SidebarTopics';
import { NewsletterFormComponent } from '../components/V2/Form/NewsletterForm';
import { createMarkUp } from '../components/V2/helpers';

// export const data = graphql`
// `;

interface Props {
  Lang?: string;
  pageContext?: {
    post: any;
  };
}

const PostPage: React.FC<Props> = ({ Lang = 'en', pageContext }) => {
  const post = pageContext?.post;

  console.log(post);

  const getPrimaryCateogory = postCategories => {
    const PrimaryCategories = BlogCategories.categories;
    const matchingSlugs = postCategories.filter(item => PrimaryCategories.includes(item.slug)).map(item => item.slug);

    if (matchingSlugs.length > 0) {
      return matchingSlugs[0];
    } else {
      return undefined;
    }
  };

  console.log(getPrimaryCateogory(post.categories));
  console.log(post.author);

  // let [post, setPost] = useState(props.data.dibPosts);
  // const [mainCategory, setMainCategory] = useState(undefined);
  // const [productCategory, setProductCategory] = useState(undefined);
  // const [relatedArticles, setRelatedArticles] = useState([]);
  // const [form, setForm] = useState(undefined);
  // const [formTitle, setFormTitle] = useState(undefined);
  const [addRequestDemoCTA, setAddRequestDemoCTA] = useState(undefined);
  //
  // const getPrimaryCateogory = postCategories => {
  //   const PrimaryCategories = BlogCategories.categories;
  //   const matchingSlugs = postCategories.filter(item => PrimaryCategories.includes(item.slug)).map(item => item.slug);
  //
  //   if (matchingSlugs.length > 0) {
  //     return matchingSlugs[0];
  //   } else {
  //     return undefined;
  //   }
  // };
  //
  // const getPoductCateogory = postCategories => {
  //   const PrimaryCategories = BlogCategories.products;
  //   const matchingSlugs = postCategories.filter(item => PrimaryCategories.includes(item.slug)).map(item => item.slug);
  //
  //   if (matchingSlugs.length > 0) {
  //     return matchingSlugs[0];
  //   } else {
  //     return undefined;
  //   }
  // };

  // useEffect(() => {
  //   fetch(
  //     `https://api.dropinblog.com/v1/json/post/?b=b069c92f-a309-49dd-afcd-7361d3d7dc8c&post=` + props.data.dibPosts.slug
  //   )
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       // If the post has been updated after it was imported as a static file update the page.
  //       if (props.data.dibPosts.updatedAt !== resultData.data.post.updatedAt) {
  //         setPost(resultData.data.post);
  //         setForm(extractFormUrl(resultData.data.post.content));
  //       }
  //       setRelatedArticles(resultData.data.post.related_posts);
  //       setForm(extractFormUrl(resultData.data.post.content));
  //       setFormTitle(extractFormTitle(resultData.data.post.content));
  //       setAddRequestDemoCTA(checkForDemoFormRequest(resultData.data.post.content));
  //       setMainCategory(getPrimaryCateogory(resultData.data.post.categories));
  //       setProductCategory(getPrimaryCateogory(resultData.data.post.categories));
  //     }); // set data for the post
  // }, [props.data.dibPosts.slug, props.data.dibPosts.updatedAt]);

  // let date = post.publishedAt.split(' ');
  // let realDate = date[0].replace(/[/]/g, '.');
  //
  // function checkForDemoFormRequest(htmlString) {
  //   const regex = /\[ADD-REQUEST-DEMO-FORM\]/;
  //   return regex.test(htmlString);
  // }
  //
  // function extractFormUrl(htmlString) {
  //   const regex = /\[FORM url="([^"]+)"/;
  //   const match = htmlString.match(regex);
  //   if (match && match.length > 1) {
  //     return match[1];
  //   }
  //   return undefined;
  // }

  // function extractFormTitle(htmlString) {
  //   const regex = /\[FORM.*title="([^"]+)"/;
  //   const match = htmlString.match(regex);
  //   let returnVar = undefined;
  //   if (match && match.length > 1) {
  //     returnVar = match[1];
  //   }
  //   return returnVar;
  // }
  //
  // function bodyText() {
  //   let content = post.content;
  //   content = removeSquareBrackets(content);
  //   return {
  //     __html: content
  //   };
  // }
  //
  // const showAuthorJobTitle = ({ authorName }: { authorName: any }) => {
  //   // @ts-ignore
  //   const jobTitle = BlogJobTitles[authorName];
  //   return jobTitle;
  // };
  //
  let headerLang = 'en';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    headerLang = 'zh-cn';
  }

  return (
    <PageContainer>
      <GlobalStyle />
      <HelmetContainer
        lang={headerLang}
        title={post.title}
        description={post.summary}
        image={post.featuredImage}
        // @ts-ignore
        json={JSON.stringify(BlogVideoJSON[post.slug])}
      />
      <Header lang={headerLang} />

      <NewsSubNavigation />

      <SingleNewsWrapper className={'container'}>
        <SingleNewsMainContent>
          <h1>{post.title}</h1>

          <FeaturedPartner post={post} />

          <hr />

          <BlogData
            Category={getPrimaryCateogory(post.categories)}
            Author={post.author}
            Date={post.publishedAt}
            ReadTime={post.fields.read_time}
          />

          <SingleNewsContent dangerouslySetInnerHTML={createMarkUp(post.content)}></SingleNewsContent>

          <SocialShareButtons />
        </SingleNewsMainContent>
        <SingleNewsSideColumn>
          <SidebarTopics />
        </SingleNewsSideColumn>
      </SingleNewsWrapper>

      <div className={'spacing-xl'}></div>

      {/*{addRequestDemoCTA && (*/}
      {/*  <HighlightBlockNoImage*/}
      {/*    Theme={'3dxpert'}*/}
      {/*    Button={{*/}
      {/*      ModelVertical: 'NewForm-Product',*/}
      {/*      ModelTitle: 'Try 3DXpert additive manufacturing software',*/}
      {/*      OpenDemoModel: 'true',*/}
      {/*      copy: 'Start free trial'*/}
      {/*    }}*/}
      {/*    Title={'<h3>Try 3DXpert additive manufacturing software</h3>'}*/}
      {/*  />*/}
      {/*)}*/}

      <div className={'container'}>
        <NewsletterFormComponent Categories={post.categories} />

        <NewsHRDivider />
        <TagsWrap Categories={post.categories} />
        <hr />
        {/*<RelatedPosts Articles={relatedArticles} />*/}
      </div>
      <Footer lang={headerLang} />
    </PageContainer>
  );
};

export default PostPage;
