import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../../containers/Box';
import { Flex } from '../../../../containers/Flex';

import { spacing, colors, media } from '../../../../foundations';
import { HeadingS, Paragraph } from '../../../../../styles/typography';

interface OutroProps {
  title: string;
  text: string | React.ReactNode;
  image?: React.ReactNode;
  moreInformation?: {
    link: string;
    extra?: string;
    links?: { name: string; link: string }[];
  };
  inlineImage?: { src: string; alt: string };
}

const Content = ({ image, text, moreInformation, inlineImage }: Partial<OutroProps>) => {
  return <></>;
};

export const Outro = ({ title, text, image, moreInformation, inlineImage }: OutroProps) => {
  return (
    <>
      <h3>{title}</h3>
      <p>{text}</p>
    </>
  );
};
