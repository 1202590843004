import React from 'react';
import styled from 'styled-components';

import { spacing, media } from '../../../../foundations';

const ImageContainer = styled.section<{ maxWidth?: string }>`
  width: auto;
  margin: ${spacing.xl}px auto;
  max-width: 100%;

  img {
    width: 100%;
  }

  a {
    width: 100% !important;
    margin: 40px auto;
    border-bottom: 0 !important;
  }

  @media screen and (max-width: ${media.xl}px) {
    max-width: 100%;
    width: auto;
  }
`;

export const SingleImage = ({
  src,
  alt,
  maxWidth,
  url
}: {
  src: string;
  alt: string;
  maxWidth?: string;
  url?: string;
}) => {
  return (
    <ImageContainer maxWidth={maxWidth}>
      {url && (
        <a href={url}>
          <img src={src} alt={alt} />
        </a>
      )}
      {!url && <img src={src} alt={alt} />}
    </ImageContainer>
  );
};
