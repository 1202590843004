import React from 'react';

import GlobalStyle from '../../../GlobalStyles';
import { ContactNumbersStyles, GridWrap } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { ArticleLayout1, Category } from '../ArticleBlock/styles';

interface Props {
  copyTop?: string;
  copyBase?: string;
  numbers?: object;
}

export const ContactNumbers = ({ copyTop, copyBase, numbers, ...props }: Props) => {
  return (
    <ContactNumbersStyles>
      <GlobalStyle />
      <div className={'container'}>
        <h4 dangerouslySetInnerHTML={createMarkUp(copyTop)}></h4>

        <GridWrap>
          {numbers.map((item, index) => {
            return (
              <a href={'tel:' + item.phone}>
                <img src={'/assets/icons/flags/rectangular/' + item.titleShort.toLowerCase() + '.png'} />
                <span>
                  {item.titleShort}: {item.phone}
                </span>
              </a>
            );
          })}
        </GridWrap>

        <h5 dangerouslySetInnerHTML={createMarkUp(copyBase)}></h5>
      </div>
    </ContactNumbersStyles>
  );
};
