import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Countries from './../../../../data/country-state-data.json';
import { FormStyles } from './styles.jsx';
import Recaptcha from 'react-recaptcha';
import { InputField } from './InputField';
import { SelectField } from './SelectField';
import { TextArea } from './TextArea';
import { CampaignIDs } from '../../../helpers/campaign-data';
import { ThankYouPageRouteBuilder } from '../../../helpers/thank-you-route';

const ReCaptchaComp = ({ onVerify }) => {
  const handleVerify = recaptchaResponse => {
    if (recaptchaResponse) {
      onVerify(recaptchaResponse);
    }
  };

  return (
    <Recaptcha sitekey="6LfVrjgoAAAAABu1s9CvuamcuiQSmT3veo4hS4-e" size="invisible" verifyCallback={handleVerify} />
  );
};

interface Props {
  Route?: string;
  Fields?: object;
  Lang?: string;
  HiddenFields?: object;
  PrefilledFields?: object;
  ProductOptions?: object;
  Campaigns?: object;
}

export const FormComponent = ({
  Fields,
  Route = 'add-prospect',
  Lang = 'en',
  ProductOptions = [],
  HiddenFields = [],
  PrefilledFields = [],
  ...props
}: Props) => {
  const apiEndpoint = 'https://middleware-oqton.netlify.app/';
  let apiLang = '';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    apiLang = 'cn-';
  }

  const copy = require(`../../../../data/forms/${Lang}.json`);

  function sortByCountry(jsonObj) {
    const sortedKeys = Object.keys(jsonObj).sort((a, b) => {
      const countryA = jsonObj[a]['Country'].toLowerCase();
      const countryB = jsonObj[b]['Country'].toLowerCase();
      return countryA.localeCompare(countryB);
    });

    const sortedJsonObj = {};
    sortedKeys.forEach(key => {
      sortedJsonObj[key] = jsonObj[key];
    });

    return sortedJsonObj;
  }

  const countryArray = Object.values(sortByCountry(Countries));
  const [formInProgress, setFormInProgress] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [activeCountryData, setActiveCountryData] = useState(null);

  const [formData, setFormData] = useState({
    gclid: '',
    utm_term: '',
    utm_content: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    product_interest: '',
    preferred_lang: Lang,
    first_name: '',
    last_name: '',
    campaign_id: '',
    marketingOpt: '',
    asset_campaign_id: '',
    email: '',
    message: '',
    company: '',
    rb: '',
    country: '',
    state: ''
  });

  const [touchedFields, setTouchedFields] = useState({
    product_interest: false,
    first_name: false,
    last_name: false,
    campaign_id: false,
    asset_campaign_id: false,
    email: false,
    message: false,
    company: false,
    rb: false,
    country: false,
    marketingOpt: false,
    state: false
  });

  const [errors, setErrors] = useState([]);

  const handleRecaptchaVerify = token => {
    setRecaptchaToken(token);
  };

  const getCampaignIdFromProduct = product => {
    switch (true) {
      case product.includes('Additive'):
        return CampaignIDs['additive'];
      case product.includes('Healthcare') || product.includes('Dental'):
        return CampaignIDs['dental'];
      case product.includes('3dxpert'):
        return CampaignIDs['3dxpert'];
      case product.includes('3Dxpert'):
        return CampaignIDs['3dxpert'];
      case product.includes('3DXpert'):
        return CampaignIDs['3dxpert'];
      case product.includes('Design'):
        return CampaignIDs['geomagic-designx'];
      case product.includes('Control'):
        return CampaignIDs['geomagic-controlx'];
      case product.includes('Freeform'):
        return CampaignIDs['freeform'];
      case product.includes('Wrap'):
        return CampaignIDs['geomagic-wrap'];
      case product.includes('Solidworks'):
        return CampaignIDs['geomagic-for-solidworks'];
      case product.includes('D2P'):
        return CampaignIDs['d2p'];
      default:
        // General Contact Us
        return '7012T000001OhQcQAK';
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'country') {
      setErrors(errors.filter(item => item !== copy.fields.state.error.required));
      setActiveCountryData(Countries[value.toLowerCase().replace(/\s/g, '-')]);
    }

    if (name === 'product_interest' && Route === 'request-a-trial') {
      const CampaignID = getCampaignIdFromProduct(value);
      setFormData({
        ...formData,
        [name]: value,
        ['campaign_id']: CampaignID
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }

    if (name === 'marketingOpt') {
      let tempErrors = errors;
      let itemToRemove = copy.fields.marketingOpt.error.required;
      let indexToRemove = tempErrors.indexOf(itemToRemove);
      if (indexToRemove !== -1) {
        tempErrors.splice(indexToRemove, 1);
      }
      setErrors(tempErrors);
    }
  };

  const updateSingleField = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const isValidEmail = email => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  };

  const handleTouch = e => {
    const { name, value } = e.target;

    setTouchedFields({
      ...touchedFields,
      [name]: true
    });

    // If the field is a required field
    if (
      name === 'first_name' ||
      name === 'last_name' ||
      name === 'company' ||
      name === 'country' ||
      name === 'state' ||
      name === 'email' ||
      name === 'product_interest' ||
      name === 'message'
    ) {
      //If the field is not filled in
      if (formData[name].length < 1) {
        setErrors(prevErrors => {
          // Create a Set to store unique errors
          const uniqueErrors = new Set(prevErrors);

          // Add the new error to the Set
          uniqueErrors.add(copy['fields'][name]['error']['required']);

          // Convert the Set back to an array
          return [...uniqueErrors];
        });
      } else {
        if (name === 'email') {
          setErrors(prevErrors => {
            // Create a Set to store unique errors
            const uniqueErrors = new Set(prevErrors);

            // Remove the specific string from the Set
            const stringToRemove = copy['fields'][name]['error']['required'];
            uniqueErrors.delete(stringToRemove);

            // Convert the Set back to an array
            return [...uniqueErrors];
          });

          if (!isValidEmail(formData[name])) {
            setErrors(prevErrors => {
              // Create a Set to store unique errors
              const uniqueErrors = new Set(prevErrors);

              // Add the new error to the Set
              uniqueErrors.add(copy['fields'][name]['error']['invalid']);

              // Convert the Set back to an array
              return [...uniqueErrors];
            });
          } else {
            setErrors(prevErrors => {
              // Create a Set to store unique errors
              const uniqueErrors = new Set(prevErrors);

              // Remove the specific string from the Set
              const stringToRemove = copy['fields'][name]['error']['invalid'];
              uniqueErrors.delete(stringToRemove);

              // Convert the Set back to an array
              return [...uniqueErrors];
            });
          }
        } else {
          setErrors(prevErrors => {
            // Create a Set to store unique errors
            const uniqueErrors = new Set(prevErrors);

            // Remove the specific string from the Set
            const stringToRemove = copy['fields'][name]['error']['required'];
            uniqueErrors.delete(stringToRemove);

            // Convert the Set back to an array
            return [...uniqueErrors];
          });
        }
      }
    }
  };

  const handleSubmit = async e => {
    setFormInProgress(true);

    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = [
      'first_name',
      'last_name',
      'company',
      'email',
      'country',
      'product_interest',
      'marketingOpt'
    ];
    const emptyFields = requiredFields.filter(field => !formData[field]);

    if (emptyFields.length > 0) {
      console.log(emptyFields);

      // Display error message or prevent form submission
      // setErrors([copy.errors.requiredFields]);

      const newErrors = [];
      const newTouched = { ...touchedFields };
      emptyFields.map((field, key) => {
        newErrors.push(copy['fields'][field]['error']['required']);
        newTouched[field] = true;
      });

      if (activeCountryData && activeCountryData.States && formData['state'].length === 0) {
        newErrors.push(copy['fields']['state']['error']['required']);
        newTouched['state'] = true;
        setErrors(newErrors);
        setTouchedFields(newTouched);
        setFormInProgress(false);
        return;
      }

      setErrors(newErrors);
      setTouchedFields(newTouched);
      setFormInProgress(false);
      return;
    }

    if (activeCountryData.States && formData['state'].length === 0) {
      const newErrors = [];
      const newTouched = { ...touchedFields };
      newErrors.push(copy['fields']['state']['error']['required']);
      newTouched['state'] = true;
      setErrors(newErrors);
      setTouchedFields(newTouched);
      setFormInProgress(false);
      return;
    }

    e.preventDefault();
    try {
      if (formData['rb'] !== '' && window && window.location.origin !== 'http://localhost:8000') {
        alert(copy.errors.robot);
        setFormInProgress(false);
        return;
      }

      formData['campaign_id'] = getCampaignIdFromProduct(formData['product_interest']);
      const response = await axios.post(`${apiEndpoint}.netlify/functions/${apiLang}${Route}`, formData);

      let url = `/thank-you/?name=${response.data.first_name}&lang=${response.data.preferred_lang}`;
      url = ThankYouPageRouteBuilder(response.data.preferred_lang, formData.product_interest, response.data.first_name);

      if (window) {
        window.location.href = url;
      }
    } catch (error) {
      setFormInProgress(false);
      alert(copy.errors.unknown);
      console.error(copy.errors.unknown, error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const cookieValue = Cookies.get('utmdata');

      console.log(cookieValue);

      if (cookieValue != null && cookieValue !== undefined) {
        const parsedData = cookieValue.split('|').reduce((acc, section) => {
          const match = section.match(/(utm\w+)=\(?([^\)]+)\)?/);
          if (match) {
            const [, key, value] = match;
            acc[key] = value;
          } else if (section.startsWith('gclid=')) {
            const [key, value] = section.split('=');
            acc[key] = value;
          }
          return acc;
        }, {});

        console.log(parsedData);

        setFormData(prevFormData => ({
          ...prevFormData,
          gclid: parsedData['gclid'] || prevFormData['gclid'],
          utm_source: parsedData['utm_source'] || prevFormData['utm_source'],
          utm_campaign: parsedData['utm_campaign'] || prevFormData['utm_campaign'],
          utm_medium: parsedData['utm_medium'] || prevFormData['utm_medium'],
          utm_term: parsedData['utm_term'] || prevFormData['utm_term'],
          utm_content: parsedData['utm_content'] || prevFormData['utm_content']
        }));
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const newFormData = PrefilledFields.reduce(
      (acc, item) => {
        acc[item.field] = item.value;
        return acc;
      },
      { ...formData }
    );
    setFormData(newFormData);
  }, []);

  return (
    <>
      <FormStyles onSubmit={handleSubmit} className={`Component-Form in-progress-${formInProgress}`} {...props}>
        <label className={'robot-check'}>
          Checker
          <input type={'text'} value={formData.rb} name={'rb'} onChange={handleChange} />
        </label>
        <label className={'hidden'}>
          Campaign
          <input type={'text'} value={formData.campaign_id} name="campaign_id" onChange={handleChange} />
        </label>

        <InputField
          required={true}
          touchedFields={touchedFields}
          formData={formData}
          copy={copy}
          handleTouch={handleTouch}
          handleChange={handleChange}
          fieldName={'first_name'}
          fieldType={'text'}
        />
        <InputField
          required={true}
          touchedFields={touchedFields}
          formData={formData}
          copy={copy}
          handleTouch={handleTouch}
          handleChange={handleChange}
          fieldName={'last_name'}
          fieldType={'text'}
        />
        <InputField
          required={true}
          touchedFields={touchedFields}
          formData={formData}
          copy={copy}
          handleTouch={handleTouch}
          handleChange={handleChange}
          fieldName={'company'}
          fieldType={'text'}
        />

        <InputField
          required={true}
          touchedFields={touchedFields}
          formData={formData}
          copy={copy}
          handleTouch={handleTouch}
          handleChange={handleChange}
          fieldName={'email'}
          fieldType={'email'}
        />

        <SelectField
          required={true}
          touchedFields={touchedFields}
          formData={formData}
          copy={copy}
          handleTouch={handleTouch}
          handleChange={handleChange}
          fieldName={'country'}
          options={countryArray}
        />

        {activeCountryData && activeCountryData.States && (
          <SelectField
            required={true}
            touchedFields={touchedFields}
            formData={formData}
            copy={copy}
            handleTouch={handleTouch}
            handleChange={handleChange}
            fieldName={'state'}
            options={activeCountryData.States}
          />
        )}

        {!HiddenFields.includes('product_interest') && (
          <SelectField
            required={true}
            touchedFields={touchedFields}
            formData={formData}
            copy={copy}
            restrictOptions={ProductOptions}
            handleTouch={handleTouch}
            handleChange={handleChange}
            fieldName={'product_interest'}
            options={copy.fields.product_interest.options}
          />
        )}

        {!HiddenFields.includes('message') && (
          <TextArea
            required={true}
            touchedFields={touchedFields}
            formData={formData}
            copy={copy}
            handleTouch={handleTouch}
            handleChange={handleChange}
            fieldName={'message'}
          />
        )}

        <div className={'marketing-opt'}>
          {copy.fields.marketingOpt.label} <span className={'required'}>*</span>
          <span
            className={`error required ${
              touchedFields['marketingOpt'] === true && formData['marketingOpt'].length < 1 ? '' : 'hidden'
            }`}
          >
            {copy.fields.marketingOpt.error.required}
          </span>
          <label htmlFor="optIn">
            <input type="radio" id="optIn" name="marketingOpt" value="yes" onChange={handleChange} />
            {copy.fields.marketingOpt.options.optin}
          </label>
          <label htmlFor="optOut">
            <input type="radio" id="optOut" name="marketingOpt" value="no" onChange={handleChange} />
            {copy.fields.marketingOpt.options.optout}
          </label>
        </div>

        <p className={`${errors.length > 0 ? 'errors' : 'errors hidden'}`}>
          <span className={'validation'}>
            {copy.errors.validation}
            <ul>
              {errors &&
                errors.map((error, index) => {
                  return <li key={index}>{error}</li>;
                })}
            </ul>
          </span>
        </p>

        <ReCaptchaComp onVerify={handleRecaptchaVerify} />

        <label className={'full-width'}>
          <input disabled={errors.length !== 0} type="submit" value={copy.fields.submit.label} />
        </label>
      </FormStyles>
    </>
  );
};
