const BlogVideoJSON = {
    'introduction-to-first-article-inspection': {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "Control X – 3D inspection and metrology software",
        "description": "Bring the power of 3D scan-based inspection to more people in more places with industry-leading 3D metrology software that makes it easy to capture and interpret scan data.",
        "thumbnailUrl": "",
        "uploadDate": "2022-09-22",
        "duration": "PT0M47S",
        "contentUrl": "https://youtu.be/MN-a9KO8H2E"
    },
    'design-on-the-fly-examples-of-3d-scanning-software-accelerating-product-development': {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": "3D Scanning a Submarine: Ensuring Quality When It Counts",
        "description": "After 6 years of trying to build a submarine by themselves, a couple guys turned to the team at CATI to 3D Scan the lid to the submarine so they could achieve a clear finish. The results speak for themselves.",
        "thumbnailUrl": "",
        "uploadDate": "2022-06-21",
        "duration": "PT3M31S",
        "contentUrl": "https://youtu.be/2MLq1D297qg"
    }
}

// eslint-disable-next-line import/no-default-export
export default BlogVideoJSON;