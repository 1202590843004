import React, { useState, useEffect } from 'react';
import AllEventData from '../../data/events/events.json';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Header } from '../components/layouts/HeaderComponent';
import GlobalStyles, { BackgroundGrey } from '../GlobalStyles';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { createSlug } from '../helpers/helper';

gsap.registerPlugin(ScrollToPlugin);

import { PaginationStyles, FilterContainer, NoResultsDisplay, CheckboxWrapper } from '../styles/newsStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import {
  EventsGrid,
  SingleUpcomingEventStyles,
  FilterWrap,
  DisplayResults
} from '../components/V2/Events/EventTitleBlock/styles';
import moment from 'moment/moment';
import { LogoWrap } from '../components/V2/Events/SideColumn/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/fontawesome-free-solid';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { GoogleMapsMultiPointComponent } from '../components/V2/Map/MultipointMap';
import BlogCategories from '../../data/blog-categories.json';
import { ButtonComponent } from '../components/V2/Button/Button';

interface Props {
  baseCategory?: object;
}

const SingleEventLink = ({ data, Lang = 'en' }: { data: any; Lang?: string }) => {
  const startformattedDate = moment(data.start_date).format('MMMM D');
  const endformattedDate = moment(data.end_date).format('D, YYYY');
  const EventsPageCopy = require(`../../data/content/Pages/events/${Lang}.json`);

  const simplifiedStartDate = moment(data.start_date).format('MMMM D YYYY');
  const simplifiedEndDate = moment(data.end_date).format('MMMM D YYYY');

  return (
    <>
      <SingleUpcomingEventStyles
        href={`/events/${createSlug(data.title)}/`}
        className={`category-${data.type.toLowerCase().replace(' ', '-')}`}
      >
        <h4 className={`category category-${data.type.toLowerCase().replace(' ', '-')}`}>
          <span>{EventsPageCopy.type[data.type.toLowerCase().replace(' ', '-')]}</span>
        </h4>
        <h3>{data.title}</h3>
        <p>
          {data['location']['copy']}
          <br />

          {simplifiedStartDate === simplifiedEndDate ? (
            <>{simplifiedStartDate}</>
          ) : (
            <>
              {startformattedDate} - {endformattedDate}
            </>
          )}
        </p>
        <hr />
        <div className={'logo-row'}>
          <img className={'logo'} src={data.event_logo} alt="Event Logo" />
          <FontAwesomeIcon icon={'arrow-right'} />
        </div>
      </SingleUpcomingEventStyles>
    </>
  );
};

const EventsTemplate = ({ Lang = 'en', baseCategory = [], ...props }: Props) => {
  const vertical = '';
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const EventsPageCopy = require(`../../data/content/Pages/events/${Lang}.json`);

  const [events, setevents] = useState([]);
  const [contentLoaded, setContentLoaded] = useState(false);
  const eventsPerPage = 25; // Number of events to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState(baseCategory);
  const [selectedProducts, setSelectedProducts] = useState(['all']);
  const [selectedRegions, setSelectedRegions] = useState(['all']);
  const [filteredevents, setFilteredevents] = useState([]);
  const [activeEventView, setActiveEventView] = useState('grid');
  const [openFilters, setOpenFilters] = useState(false);
  const [showingFutureEvents, setShowingFutureEvents] = useState(true);
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const [uniqueRegions, setUniqueRegions] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setContentLoaded(true);
        setevents(AllEventData);
      }, 100);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (events.length > 0) {
      setFilteredevents(
        events.filter(thisEvent => {
          let datePass = true;
          let productPass = true;
          let regionPass = true;

          if (isFutureDate(thisEvent.end_date) && showingFutureEvents) {
            datePass = true;
          } else if (!isFutureDate(thisEvent.end_date) && !showingFutureEvents) {
            datePass = true;
          } else {
            datePass = false;
          }

          if (!selectedProducts.includes('all') && selectedProducts.length > 0) {
            const hasSelectedProduct = thisEvent.products.some(product =>
              selectedProducts.includes(createSlug(product.product_selector))
            );
            if (!hasSelectedProduct) {
              productPass = false;
            }
          }

          if (!selectedRegions.includes('all') && selectedRegions.length > 0) {
            if (!selectedRegions.includes(thisEvent.region)) {
              regionPass = false;
            }
          }

          if (datePass && productPass && regionPass) {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [events, showingFutureEvents, selectedProducts, selectedRegions]);

  useEffect(() => {
    let productArray = [];
    let regionArray = [];
    if (events && events.length > 0) {
      productArray = Array.from(
        new Set(events.flatMap(event => event.products.map(category => category.product_selector)))
      );
      productArray.sort();
      setUniqueProducts(productArray);

      regionArray = Array.from(new Set(events.flatMap(event => event.region)));
      regionArray.sort();
      setUniqueRegions(regionArray);
    }
  }, [events, selectedCategories]);

  const handleProductChange = categorySlug => {
    let updateProducts = [...selectedProducts];

    if (categorySlug === 'all') {
      updateProducts = ['all'];
    } else {
      updateProducts = updateProducts.filter(slug => slug !== 'all');

      const isSelected = updateProducts.includes(categorySlug);

      if (isSelected) {
        updateProducts = updateProducts.filter(slug => slug !== categorySlug);
      } else {
        updateProducts.push(categorySlug);
      }
    }
    if (updateProducts.length === 0) {
      updateProducts = ['all'];
    }

    setSelectedProducts(updateProducts);
    setCurrentPage(1);
  };

  const handleRegionChange = categorySlug => {
    console.log('handleRegionChange', categorySlug);
    let updateRegions = [...selectedRegions];

    if (categorySlug === 'all') {
      updateRegions = ['all'];
    } else {
      updateRegions = updateRegions.filter(slug => slug !== 'all');

      const isSelected = updateRegions.includes(categorySlug);

      if (isSelected) {
        updateRegions = updateRegions.filter(slug => slug !== categorySlug);
      } else {
        updateRegions.push(categorySlug);
      }
    }

    if (updateRegions.length === 0) {
      updateRegions = ['all'];
    }

    console.log(updateRegions);
    setSelectedRegions(updateRegions);
    setCurrentPage(1);
  };

  const isFutureDate = date => {
    const endDate = new Date(date);
    const now = new Date();
    return endDate > now;
  };

  const indexOfLastevent = currentPage * eventsPerPage;
  const indexOfFirstevent = indexOfLastevent - eventsPerPage;
  const currentevents = filteredevents.slice(indexOfFirstevent, indexOfLastevent);
  const totalPages = Math.ceil(filteredevents.length / eventsPerPage);

  const handleToggleFilterWrap = () => {
    setOpenFilters(!openFilters);
  };

  return (
    <PageContainer>
      <BackgroundGrey>
        <GlobalStyles />
        <HelmetContainer lang={Lang} title="Events" />
        <Header lang={Lang} />

        <LargeHero
          AdditionalClasses={'eventHero'}
          Background={'/assets/images/events/events-bg.png'}
          BackgroundStyle={'cover'}
          Copy={EventsPageCopy.content.hero.copy}
        />

        <div className={'container'}>
          <FilterWrap>
            <div className={'filter-date'}>
              <span
                onClick={() => {
                  setShowingFutureEvents(true);
                }}
                className={showingFutureEvents ? 'active' : ''}
              >
                {EventsPageCopy.content.filters.upcoming}
              </span>
              <span
                onClick={() => {
                  setShowingFutureEvents(false);
                }}
                className={showingFutureEvents ? '' : 'active'}
              >
                {EventsPageCopy.content.filters.past}
              </span>
            </div>
            <div>
              <button onClick={handleToggleFilterWrap}>
                <img src={'/assets/icons/filter.png'} alt={''} />
                Filters
              </button>
            </div>
          </FilterWrap>

          {openFilters && (
            <FilterContainer className={'events-filters'}>
              <CheckboxWrapper className={'filters-region'}>
                <h3>Regions</h3>
                <div className={'check-wrap'}>
                  <label>
                    <input
                      type="checkbox"
                      value={'all'}
                      checked={selectedRegions.includes('all')}
                      onChange={() => handleRegionChange('all')}
                    />
                    All Regions
                  </label>

                  {uniqueRegions.map((region, index) => {
                    let slug = region;
                    if (region) {
                      return (
                        <label key={index}>
                          <input
                            type="checkbox"
                            value={slug}
                            checked={selectedRegions.includes(slug)}
                            onChange={() => handleRegionChange(slug)}
                          />
                          {region}
                        </label>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </CheckboxWrapper>
              <CheckboxWrapper className={'filters-products'}>
                <h3>Products</h3>
                <div className={'check-wrap'}>
                  <label>
                    <input
                      type="checkbox"
                      value={'all'}
                      checked={selectedProducts.includes('all')}
                      onChange={() => handleProductChange('all')}
                    />
                    All Products
                  </label>

                  {uniqueProducts.map((product, index) => {
                    let slug = createSlug(product);
                    return (
                      <label key={index}>
                        <input
                          type="checkbox"
                          value={slug}
                          checked={selectedProducts.includes(slug)}
                          onChange={() => handleProductChange(slug)}
                        />
                        {product}
                      </label>
                    );
                  })}
                </div>
              </CheckboxWrapper>
            </FilterContainer>
          )}

          <DisplayResults>
            <div className={'results'}>{filteredevents.length} Results</div>
            <div className={'display-type'}>
              <span
                className={activeEventView === 'grid' ? 'active' : ''}
                onClick={() => {
                  setActiveEventView('grid');
                }}
              >
                <img src={'/assets/icons/grid.svg'} />
                Grid
              </span>
              <hr />
              <span
                onClick={() => {
                  setActiveEventView('map');
                }}
                className={activeEventView === 'map' ? 'active' : ''}
              >
                <img src={'/assets/icons/map-pin-outline.svg'} />
                Map
              </span>
            </div>
          </DisplayResults>

          {activeEventView === 'map' && (
            <GoogleMapsMultiPointComponent events={filteredevents} lng={0} lat={28.956776960949963} />
          )}

          {activeEventView === 'grid' && (
            <EventsGrid>
              {filteredevents.map((event, index) => {
                return <SingleEventLink key={index} data={event} Lang={Lang} />;
              })}

              {filteredevents.length === 0 && contentLoaded && (
                <NoResultsDisplay>
                  <h2>{EventsPageCopy.content.filters.noResults}</h2>
                </NoResultsDisplay>
              )}
              {!contentLoaded && (
                <NoResultsDisplay>
                  <h2>...Loading...</h2>
                </NoResultsDisplay>
              )}
            </EventsGrid>
          )}

          {totalPages > 1 && (
            <PaginationStyles>
              {Array.from({ length: totalPages }, (_, index) => {
                // Display the current page and the pages before and after it
                if (
                  index === 0 || // First page
                  index === currentPage - 1 || // Current page
                  index === totalPages - 1 || // Last page
                  (index >= currentPage - 2 && // Pages after the current page
                    index <= currentPage) ||
                  (index <= currentPage && // Pages before the current page
                    index >= currentPage - 1)
                ) {
                  return (
                    <button
                      key={index}
                      onClick={() => handlePagination(index + 1)}
                      disabled={currentPage === index + 1}
                    >
                      {index + 1}
                    </button>
                  );
                } else if (
                  (index === currentPage - 3 && currentPage > 3) || // Add "..." before pages if there is a gap
                  (index === currentPage + 1 && currentPage < totalPages - 2) // Add "..." after pages if there is a gap
                ) {
                  return <span key={index}>...</span>;
                }
              })}
            </PaginationStyles>
          )}
        </div>

        <div className={'spacing-xl'}></div>

        <HighlightBlock
          Theme={'teal-dark'}
          ImageType={'HalfBackground'}
          MicroTitle={'Contact'}
          Button={{
            ModelTitle: globalCopy.Buttons.RequestDemo,
            ModelVertical: vertical,
            copy: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true
          }}
          Image={{
            url: '/assets/images/homepage-v2/homepage-highlight-block.png'
          }}
          AdditionalClass={'background-white homepage-feature'}
          Title={'Discover how Oqton can boost<br/>your production'}
        />

        <div className={'spacing-xl'}></div>

        <Footer lang={Lang} />
      </BackgroundGrey>
    </PageContainer>
  );
};
export default EventsTemplate;
