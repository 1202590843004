import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { ThankYou } from '../components/layouts/ThankYou';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const ContactUsThankYou = () => {
  return (
    <PageContainer>
      <HelmetContainer title="Thank you" />
      <Main noPadding>
        <Content>
          <Header />
          <ThankYou
            customText={
              <>
                Vielen Dank, dass Sie unser Team kontaktiert haben! Wir werden Ihre Anfrage so schnell als möglich
                beantworten.
              </>
            }
          />
        </Content>
      </Main>
      <Footer />
    </PageContainer>
  );
};

export default ContactUsThankYou;
