import styled from 'styled-components';
import {bgGradients, colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const FormStyles = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 100px;
  width: 100%;
  
  &.in-progress-true{
    opacity: 0.3;
    pointer-events: none !important;
  }

  .robot-check {
    position: absolute;
    z-index: -10;
    width: 10px;
      opacity: 0;
  }

  &.Component-DownloadForm{
    label.form-label-email{
      width: 100%;
    }
  }
  
  input[type='submit'] {
    background: ${colors.teal};
    color: white;
    font-family: centrano2;
    font-weight: 600;
    padding: 16px;
    display: inline-block;
    border-radius: 4px;
    border-style: none;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    transition: 0.35s;
    text-decoration: none;

    &[disabled] {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &.theme-geomagic-design-x{
      background: ${bgGradients.designx};
    }
    &.theme-geomagic-control-x{
      background: ${bgGradients.controlx};
    }
    &.theme-geomagic-for-solidworks{
      background: ${bgGradients.geomagicSolidWorks};
    }
    
  }

  div.marketing-opt {
    width: 100%;
    font-family: centrano2-book;
    font-size: 16px;
    color: ${colors.UILightSecondary};
    margin-bottom: 32px;
    position: relative;
    
    .error {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      text-align: right;
      pointer-events: none;
      color: ${colors.highlight};
    }

    label {
      margin: 6px 0 12px;
      display: flex;
      align-items: baseline;
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        align-items: center;
        width: 100%;
      }

      input[type='radio'] {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  label {
    width: 100%;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      width: calc(50% - 16px);
    }
    font-family: centrano2-book;
    font-size: 16px;
    color: ${colors.UILightSecondary};
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    letter-spacing: 0.02em;

    span.required {
      color: ${colors.highlight};
      margin-left: 6px;
    }

    &.full-width {
      width: 100%;
    }

    &.errored {
      color: ${colors.highlight};

      select,
      textarea,
      input[type='email'],
      input[type='text'] {
        border-bottom-color: ${colors.highlight};
        background: rgba(240, 37, 66, 0.1);
      }
    }

    .error {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      text-align: right;
      pointer-events: none;
      color: ${colors.highlight};
    }

    textarea,
    select,
    input[type='email'],
    input[type='text'] {
      margin: 8px 0 30px 0;
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-family: centrano2-book;
      font-size: 16px;
      padding: 12px 0;
      background-color: transparent;
      border: none;
      border-bottom: solid 1px ${colors.UILightTertiary};
      -webkit-border-top-left-radius: 4px;
      -webkit-border-top-right-radius: 4px;
      -moz-border-radius-topleft: 4px;
      -moz-border-radius-topright: 4px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      transition: 0.25s;

      &:focus-visible,
      &:focus {
        outline: none;
        background: rgba(71, 186, 206, 0.16);
        border-bottom-color: ${colors.teal};
        padding-left: 16px;
      }
    }

    textarea {
      border: solid 1px ${colors.UILightTertiary};
      min-height: 150px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  p.errors {
    width: 100%;
    margin-bottom: 32px;
    padding: 16px;
    background: rgba(240, 37, 66, 0.1);
    color: ${colors.highlight};
    border-radius: 4px;
    font-size: 16px;
    font-family: centrano2-book;

    ul {
      margin: 16px 0 0;
      padding-left: 20px;

      li {
        list-style: disc;
      }
    }

  }

  p.preferences{
    margin-top: 12px;
    font-size: 16px !important;
    a{
      transition: 0.3s;
      &:hover{
        color: ${colors.teal};
      }
    }
  }
  
`;

export const NewsletterWrapper = styled.div`
  background: #DDEEF4;
  margin: 42px 0;
  padding: 24px;
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 42px;
    display: flex;
  }
  background-image: url("/assets/images/backgrounds/newsletter-bg.png");
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  justify-content: space-between;

  .text-wrap,
  .Component-Form {
    margin: 24px 0;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin: 0;
      width: calc(50% - 36px);
    }

    .preferences{
      margin-top: 12px;
      font-size: 16px !important;
      a{
        transition: 0.3s;
        &:hover{
          color: ${colors.teal};
        }
      }
    }

    label {
      font-size: 12px;
      text-transform: uppercase;
    }

    label.form-label-email {
      width: 100%;
    }

    input[type='email'],
    input[type='text'] {
      background: rgba(255, 255, 255, 0.6);
      border: solid 1px ${colors.border};
      border-radius: 4px;
      backdrop-filter: blur(10px);
      padding-left: 16px;
      margin-top: 0;
      margin-bottom: 16px;
    }

    p.errors {
      backdrop-filter: blur(10px);
      margin-bottom: 16px;
    }

    input[type='email'] {
      width: 100%;
    }

    input[type='submit'] {
      width: 100%;
    }


  }

  .text-wrap {
    h3 {
      font-weight: 500;
      font-size: 24px;
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 46px;
      }
      line-height: 1.1;
      letter-spacing: 0.02em;
      color: ${colors.primary};
      margin-bottom: 16px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 23px;
      }
      line-height: 1.3;
      letter-spacing: 0.02em;
      color: ${colors.light};
      font-family: centrano2-book;
    }
  }

  .Component-Form {
    padding-bottom: 0;
    overflow: hidden;
  }

`;