import React from 'react';
import HomepageTemplate from './../templates/HomepageTemplate';

const HomeRD = () => {
  let defaultLang = 'en';
  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    defaultLang = 'zh-cn';
  }

  return <HomepageTemplate Lang={defaultLang} />;
};

export default HomeRD;
