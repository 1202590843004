import React from 'react';
import { Bold } from '../../../styles/typography';
import { Box } from '../../containers/Box';
import { Flex } from '../../containers/Flex';
import { Content } from '../../layouts/PageContainer/PageContainer';
import { VideoButton } from '../../layouts/VideoButton/VideoButton';
import { Outro, Paragraph, Quote } from '../news/customerStories/components';
import { createMarkUp } from '../../V2/helpers';
import { Contact } from './Contact';
import { ImageContainer, ListContainer, OutroLink, QuoteContainer } from './styles';

export const AIContent = ({ content }) => {
  if (content) {
    return (
      <Content>
        <Paragraph title={content.title_1} text={content.p1} />
        <Paragraph text={content.p2} />
        <Paragraph text={content.p3} />
        <Flex justifyContent="center">
          <ImageContainer
            src="/assets/images/AIInternship/reverseEngineering.png"
            alt={content.imgAlt1}
            width="800px"
          />
        </Flex>
        <Paragraph
          title="What can we offer you?"
          text={<ListContainer dangerouslySetInnerHTML={createMarkUp(content.list1)}></ListContainer>}
        />
        <Flex justifyContent="center">
          <ImageContainer
            src="/assets/images/AIInternship/3dPointCloudSegmentation.png"
            alt={content.imgAlt2}
            width="800px"
          />
        </Flex>
        <Paragraph
          title={content.title2}
          text={
            <>
              <p>{content.p5}</p>
              <ListContainer dangerouslySetInnerHTML={createMarkUp(content.list2)}></ListContainer>
            </>
          }
        />
        <Paragraph
          text={
            <>
              <p>{content.p6}</p>
              <ListContainer dangerouslySetInnerHTML={createMarkUp(content.list3)}></ListContainer>
            </>
          }
        />
        <Paragraph
          text={
            <>
              <p>{content.p7}</p>
              <ListContainer dangerouslySetInnerHTML={createMarkUp(content.list4)}></ListContainer>
            </>
          }
        />
        <Box mt="64px">
          <Paragraph text={content.p8} />
          <Paragraph text={<VideoButton platform="youtube" url="https://www.youtube.com/watch?v=jAQSM2dhDV4" />} />
        </Box>

        <QuoteContainer>
          <Quote
            text={content.quote_1}
            name={content.quote_name}
            title={content.quote_title}
            img="/assets/images/people/tomTourwe.png"
          />
        </QuoteContainer>
        <Paragraph
          title={content.title3}
          text={
            <>
              <p>{content.p9}</p>
              <ListContainer dangerouslySetInnerHTML={createMarkUp(content.list5)}></ListContainer>
            </>
          }
        />
        <Contact />
        <Outro
          title={content.outro_title_1}
          text={content.p10}
          inlineImage={{ src: '/assets/images/AIInternship/benSchrauwen.png', alt: 'Ben Schrauwen' }}
        />
      </Content>
    );
  } else {
    return (
      <Content>
        <Paragraph
          title="Who are we?"
          text="We are Oqton’s AI team. Our ultimate goal is to fully automate the manufacturing process – from design
                  to production to logistics – using state-of-the-art AI technologies."
        />
        <Paragraph
          text="We deliver the AI models and the supporting infrastructure that powers Oqton’s Manufacturing Operating
                  System."
        />
        <Paragraph
          text="We analyze all kinds of data, 3D point clouds, sensor data, audio data, imagery, ... to support
                  applications such as 3D part segmentation, orientation for 3D printing, reverse engineering point
                  cloud scans to CAD models, 2D and 3D nesting, job scheduling, tool wear, machine monitoring…"
        />
        <Flex justifyContent="center">
          <ImageContainer
            src="/assets/images/AIInternship/reverseEngineering.png"
            alt="Reverse Engineering - Converting a 3D point cloud to a CAD representation"
            width="800px"
          />
        </Flex>
        <Paragraph
          title="What can we offer you?"
          text={
            <ListContainer>
              <li>
                If you like to apply state-of-the-art AI technology while working on your master thesis, you can&nbsp;
                <a href="mailto:tom.tourwe@oqton.com">contact us</a> for a <Bold>MSc thesis topic</Bold> to work on.
              </li>
              <li>
                If you want to gain some industrial experience and earn some money during the summer holidays, you
                can&nbsp;<a href="mailto:tom.tourwe@oqton.com">apply</a> for an <Bold>internship</Bold>.
              </li>
              <li>
                If you are interested in performing world-class PhD research with industrial relevance and business
                potential, we can&nbsp;<a href="mailto:tom.tourwe@oqton.com">engage</a> in{' '}
                <Bold>
                  a Baekeland PhD project (With support from&nbsp;
                  <a
                    href="https://www.vlaio.be/nl/subsidies-financiering/baekeland-mandaten/baekeland-mandates"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    VLAIO
                  </a>
                  )
                </Bold>
              </li>
            </ListContainer>
          }
        />
        <Flex justifyContent="center">
          <ImageContainer
            src="/assets/images/AIInternship/3dPointCloudSegmentation.png"
            alt="3D Point Cloud Segmentation - Identifying regions of interest within a 3D point cloud"
            width="800px"
          />
        </Flex>
        <Paragraph
          title="What can you expect?"
          text={
            <>
              <p>The challenges we love to work on together with you:</p>
              <ListContainer>
                <li>
                  <Bold>Nesting</Bold>, also known as bin packing, which involves placing a collection of 3D parts in
                  the limited build envelope of the 3D printer (the maximal physical volume the printer can build)
                </li>
                <li>
                  <Bold>Scheduling</Bold>, which concerns allocating tasks to be executed to available resources such as
                  3D printers.
                </li>
                <li>
                  <Bold>Segmentation</Bold>, which covers identifying local regions of interest within a 3D point cloud
                </li>
                <li>
                  <Bold>Reverse engineering</Bold>, which supports turning a 3D point cloud scan into a CAD
                  representation
                </li>
                <li>
                  <Bold>Data reliability</Bold>, which covers analyzing the quality of our datasets, consisting of
                  millions of 3D objects, and hence guaranteeing the performance of our AI models
                </li>
                <li>
                  <Bold>Anomaly detection</Bold>, which examines if a machine’s behavior is not “normal”.
                </li>
              </ListContainer>
            </>
          }
        />
        <Paragraph
          text={
            <>
              <p>AI technologies we (would like to) use for such applications:</p>
              <ListContainer>
                <li>
                  <Bold>Different network architectures</Bold>, such as rotation-invariant point cloud networks,
                  transformers, general adversarial networks, variational auto-encoders, …
                </li>
                <li>
                  <Bold>Different algorithms</Bold>, such as advanced search algorithms, genetic algorithms, no-fit
                  polygon…
                </li>
                <li>
                  <Bold>Different learning paradigms</Bold>, such as reinforcement learning, federated learning,
                  self-supervised learning…
                </li>
              </ListContainer>
            </>
          }
        />
        <Paragraph
          text={
            <>
              <p>
                In addition to working on such challenges and technologies in a stimulating R&D environment alongside
                highly motivated people, Oqton offers:
              </p>
              <ListContainer>
                <li>access to large real-world datasets</li>
                <li>use of our state-of-the-art compute infrastructure</li>
                <li>mentoring by seasoned AI engineers</li>
              </ListContainer>
            </>
          }
        />
        <Box mt="64px">
          <Paragraph text="If you want to find out more about Oqton's Manufacturing Operating System and how we use point cloud based deep learning for supporting digital manufacturing, have a look at this video." />
          <Paragraph text={<VideoButton platform="youtube" url="https://www.youtube.com/watch?v=jAQSM2dhDV4" />} />
        </Box>

        <QuoteContainer>
          <Quote
            text="We are also open to consider your interests and to think along with your ideas, contact us with your own creative suggestions!"
            name="Tom Tourwé"
            title="Head of AI"
            img="/assets/images/people/tomTourwe.png"
          />
        </QuoteContainer>
        <Paragraph
          title="What do we expect"
          text={
            <>
              <p>Our ideal candidate:</p>
              <ListContainer>
                <li>is curious and eager to explore state-of-the-art AI technologies</li>
                <li>is able to work autonomously</li>
                <li>has good programming skills in either Python or C++</li>
                <li>
                  loves to tackle complex challenges, in our case related to geometric deep learning, combinatorial
                  optimization, or multimodal learning
                </li>
              </ListContainer>
            </>
          }
        />
        <Outro
          title="AI Team at Oqton"
          image={<img src="/assets/images/AIInternship/team.png" alt="Overview of team members" width="100%" />}
          text={
            <>
              <OutroLink href="https://www.linkedin.com/in/tomtourwe/">Tom Tourwé</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/jarnevandenherrewegen/">Jarne Van den Herrewegen</OutroLink>
              ,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/miloš-nešić-b07056225/">Miloš Nešić</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/irven-aelbrecht-97228a86/">Irven Aelbrecht</OutroLink>
              ,&nbsp;
              <br />
              <OutroLink href="https://www.linkedin.com/in/rubenverhack/">Ruben Verhack</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/axel-vlaminck-2b01a098/">Axel Vlaminck</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/steven-coene-3a3a84b/">Steven Coene</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/guillaume-van-wassenhove/">
                Guillaume Van Wassenhove
              </OutroLink>
              ,&nbsp;
              <br />
              <OutroLink href="https://www.linkedin.com/in/tom-cnops-b315a365/">Tom Cnops</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/anthony-rathé/">Anthony Rathé</OutroLink>,&nbsp;
              <OutroLink href="https://www.linkedin.com/in/fvdnabee/">Floris Van den Abeele</OutroLink>
            </>
          }
        />
        <Contact />
        <Outro
          title="History"
          text="Oqton was founded by Ben Schrauwen, who used to head the Reservoir Lab at Ghent University. It should thus come as no surprise that Oqton’s core values and culture are reminiscent of those of the Reservoir Lab: we provide a diverse and inclusive workplace were talented people with a desire to make an impact love to work together, to think big and to make incredible things happen."
          inlineImage={{ src: '/assets/images/AIInternship/benSchrauwen.png', alt: 'Ben Schrauwen' }}
        />
      </Content>
    );
  }
};
