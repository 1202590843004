import React, { useContext } from 'react';
import { useEventContext, EventContextProvider } from './../context/EventContext';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import {
  BackgroundCircles,
  BackgroundCircles2,
  BackgroundCircles3,
  BackgroundGrey,
  BackgroundHalfGrey,
  BackgroundHalfGreyReverse,
  BackgroundWhite
} from '../GlobalStyles';
import { EventTitleBlock } from '../components/V2/Events/EventTitleBlock/EventTitleBlock';
import { EventWrap } from '../components/V2/Events/EventTitleBlock/styles';
import { EventSideColumnBlock } from '../components/V2/Events/SideColumn/SideColumn';
import { SelectedProductSlider } from '../components/V2/ProductSlider/SelectedProductSlider';
import { GoogleMapsComponent } from '../components/V2/Map/GoogleMap';
import { LinkedIn } from '../components/icons/LinkedIn';
import { LinkedInList } from '../components/V2/LinkedInList/LinkedInList';
import { TagsWrap } from '../components/V2/News/TagsWrap/TagsWrap';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { SocialFollow } from '../components/V2/SocialFollow/SocialFollow';

interface Props {
  Lang?: string;
  pageContext?: {
    event: any;
  };
}

const SingleEventTemplate = ({ Lang = 'en', pageContext, ...props }: Props) => {
  const eventData = pageContext?.event;

  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const vertical = '';

  if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
    Lang = 'zh-cn';
  }

  if (eventData) {
    return (
      <EventContextProvider eventData={eventData}>
        <PageContainer>
          <BackgroundGrey className={'increaseZIndex'}>
            <HelmetContainer lang={Lang} title={`${eventData.title}`} />

            <Header demoVertical={vertical} lang={Lang} />

            <div className={'container'}>
              <EventWrap>
                <EventTitleBlock EventData={eventData} />
                <EventSideColumnBlock EventData={eventData} />
              </EventWrap>
              <div className={'spacing-xl'}></div>
            </div>

            <SelectedProductSlider Products={eventData.fields.products_demo} Title={'Exhibited Products'} />

            {eventData.fields.team_introduction_title && (
              <LinkedInList
                Title={eventData.fields.team_introduction_title}
                Copy={eventData.fields.team_introduction_copy}
                Profiles={eventData.fields.team}
              />
            )}

            <GoogleMapsComponent
              eventData={eventData.fields}
              booth={eventData.fields.booth_location}
              lat={eventData.fields.location['google-maps'].lat}
              lng={eventData.fields.location['google-maps'].lng}
              address={eventData.fields.location.address}
            />

            <div className={'container'}>
              <TagsWrap Categories={eventData.tags} Links={false} />
            </div>
          </BackgroundGrey>

          <BackgroundWhite>
            <BackgroundCircles3>
              <SocialFollow Title={eventData.fields.social_follow} />
            </BackgroundCircles3>

            <HighlightBlock
              Theme={'teal-dark'}
              ImageType={'HalfBackground'}
              MicroTitle={'Contact'}
              Button={{
                ModelTitle: globalCopy.Buttons.RequestDemo,
                ModelVertical: vertical,
                copy: globalCopy.Buttons.RequestDemo,
                OpenDemoModel: true
              }}
              Image={{
                url: '/assets/images/homepage-v2/homepage-highlight-block.png'
              }}
              AdditionalClass={'background-white homepage-feature'}
              Title={'Discover how Oqton can boost<br/>your production'}
            />
            <div className={'spacing-xl'}></div>
          </BackgroundWhite>
        </PageContainer>
        <Footer lang={Lang} />
      </EventContextProvider>
    );
  } else {
    return null;
  }
};

export default SingleEventTemplate;
