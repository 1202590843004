import React from 'react';
import { Footer } from '../../../components/layouts/Footer';
import { Hero } from '../../../components/layouts/Hero';
import { MediaComponent } from '../../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { RDBold } from '../../../styles/typography';
import { Related } from '../../../components/layouts/Related';
import { ListContainer } from '../../../components/pageComponents/Styles/style';

import { Resources } from '../../../components/layouts/Resources';
import { HeaderSubtitle } from '../../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../../components/pageComponents/header';
import { Header } from '../../../components/layouts/HeaderComponent';

import {
  ProductSubTitleContainer,
  ProductSubTitle,
  ProductSubTitleIcon,
  ProductSubTitleLeft,
  ProductSubTitleRight,
  Light
} from '../../../components/pageComponents/mediaComponentContent/styles';
import {
  GeomagicEssentialsContent,
  GeomagicEssentialsControlXContent
} from '../../../components/pageComponents/mediaComponentContent/geomagicEssentials';
import { GeomagicEssentialsComboContent } from '../../../components/pageComponents/mediaComponentContent/geomagicEssentials/geomagicEssentialsComboContent';
import { Icon } from '../../../components/subComponents/Icon';

import { createMarkUp } from '../../../components/V2/helpers';
import { PageContainer } from '../../../components/containers/PageContainer';

const EssentialsBundle = () => {
  const Lang = 'ja';
  const data = require(`../../../../data/content/Pages/campaigns/essentials-bundle/${Lang}.json`);
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer
        currentPage={'campaigns-geomagic-essentials-bundle'}
        lang={Lang}
        image="/assets/images/pages/geomagicDesignX/hero.png"
        title={data.helmet.title}
        pageDescription={data.helmet.pageDescription}
        description={data.helmet.description}
        noindex
      />
      <HeaderBackground>
        <Header lang={Lang} />
        <Hero
          lang={Lang}
          title={data.content.hero.title}
          description={data.content.hero.description}
          subtitle={<HeaderSubtitle icon="ControlXEssentials" boldText="Geomagic" text="Control X Essentials" />}
          subtitle2={<HeaderSubtitle icon="DesignXEssentials" boldText="Geomagic" text="Design X Essentials" />}
          image={{ src: '/assets/images/pages/geomagicDesignX/hero.png', alt: 'Design X Hero' }}
          buttons={[{ text: globalCopy.Buttons.RequestDemo, vertical: 'GeomagicEssentials' }]}
          vertical="GeomagicEssentials"
        />
      </HeaderBackground>
      <ProductSubTitleContainer>
        <ProductSubTitle>
          <ProductSubTitleLeft>
            <ProductSubTitleIcon>
              <Icon name="DesignXEssentials" size={64} />
            </ProductSubTitleIcon>
            <RDBold>Geomagic</RDBold>
            <Light>Design X Essentials</Light>
          </ProductSubTitleLeft>
          <ProductSubTitleRight>{data.content.banner}</ProductSubTitleRight>
        </ProductSubTitle>
      </ProductSubTitleContainer>
      <MediaComponent
        id="essentials"
        articles={[
          {
            title: data.content.media1.title,
            content: <GeomagicEssentialsContent content={data.content.media1} />,
            image: { src: '/assets/images/pages/geomagicDesignX/media_1.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />
      <MediaComponent
        decreasedPadding
        articles={[
          {
            title: data.content.media2.title,
            content: (
              <React.Fragment>
                <p>{data.content.media2.p1}</p>
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.media2.list)}></ListContainer>
              </React.Fragment>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="Geomagic DesignX"
                  className="vidyard_iframe"
                  src="https://play.vidyard.com/7niTcirjvV9Dj5zGvjskaN.html"
                  width="640"
                  height="360"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            },
            image: {
              src: '/assets/images/pages/geomagicDesignX/media_2.png',
              alt: 'Geomagic DesignX',
              imagePos: 'left'
            }
          }
        ]}
      />
      <ProductSubTitleContainer>
        <ProductSubTitle>
          <ProductSubTitleLeft>
            <ProductSubTitleIcon>
              <Icon name="ControlXEssentials" size={64} />
            </ProductSubTitleIcon>
            <b> Geomagic</b> <Light> Control X Essentials</Light>
          </ProductSubTitleLeft>
          <ProductSubTitleRight>{data.content.banner2}</ProductSubTitleRight>
        </ProductSubTitle>
      </ProductSubTitleContainer>

      <MediaComponent
        id="controlx"
        articles={[
          {
            title: data.content.media3.title,
            content: <GeomagicEssentialsControlXContent content={data.content.media3} />,
            image: { src: '/assets/images/pages/geomagic-control-x/media_1.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />

      <MediaComponent
        decreasedPadding
        articles={[
          {
            title: data.content.media4.title,
            content: (
              <React.Fragment>
                <p>{data.content.media4.p1}</p>
                <ListContainer dangerouslySetInnerHTML={createMarkUp(data.content.media4.list)}></ListContainer>
              </React.Fragment>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="Geomagic ControlX"
                  className="vidyard_iframe"
                  src="https://play.vidyard.com/EetkFteEVJWwJXiamxtzRW.html?"
                  width="640"
                  height="360"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            },
            image: {
              src: '/assets/images/pages/geomagic-control-x/media_2.png',
              alt: 'Geomagic ControlX',
              imagePos: 'left'
            }
          }
        ]}
      />

      <MediaComponent
        id="GeomagicForSolidworks"
        inverted
        articles={[
          {
            title: data.content.media5.title,
            content: <GeomagicEssentialsComboContent content={data.content.media5} />,
            image: { src: '/assets/images/pages/geomagicDesignX/media_5.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />

      <Resources
        title={data.content.Resources.title}
        description={data.content.Resources.description}
        resources={[
          {
            title: data.content.Resources.resource_1,
            label: 'flyer',
            link: '/assets/files/brochures/geomagicessentials/geomagic-designx-versionscomparison-22v1-en-digital.pdf'
          },
          {
            title: data.content.Resources.resource_2,
            label: 'flyer',
            link: '/assets/files/brochures/geomagicessentials/geomagic-controlx-comparisonchart-22v1-en-digital.pdf'
          }
        ]}
      />

      <Related
        title="Related technologies"
        articles={[
          {
            title: 'Geomagic Design X',
            description: data.content.Related.title_1,
            image: { src: '/assets/images/pages/geomagic-control-x/designX.png', alt: 'placeholder' },
            url: globalCopy.Navigation.ProductURLs.geoDesignX,
            linkText: globalCopy.Buttons.Learn
          },
          {
            title: 'Geomagic Control X',
            description: data.content.Related.title_2,
            image: { src: '/assets/images/pages/geomagicDesignX/controlX.png', alt: 'placeholder' },
            url: globalCopy.Navigation.ProductURLs.geoControlX,
            linkText: globalCopy.Buttons.Learn
          }
        ]}
      />
      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default EssentialsBundle;
