import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../components/V2/FeaturedNavigation/FeaturedNavigation';
import { ClientSpotlight } from '../components/V2/ClientSpotlight/ClientSpotlight';
import { IconGrid } from '../components/V2/IconGrid/IconGrid';
import { Grid } from '../components/V2/Grid/Grid';
import { BackgroundGrey, BackgroundHalfGrey, BackgroundHalfGreyReverse } from '../GlobalStyles';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { TextBlock } from '../components/V2/TextBlock/TextBlock';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';

interface Props {
  lang?: string;
}

const MOSTemplate = ({ lang = 'en', ...props }: Props) => {
  const Lang = lang;
  const data = require(`../../data/content/Pages/products/manufacturing-os-v2/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const bqData = require(`../../data/content/Pages/additive/build-quality/${Lang}.json`);
  const sitemap = require(`../../data/sitemap-data.json`);
  const pageVertical = 'NewForm-MOS';

  return (
    <PageContainer lang={Lang}>
      <HelmetContainer
        currentPage={'manufacturing-os'}
        lang={Lang}
        title={data.helmet.title}
        description={data.helmet.description}
      />
      <Header lang={lang} demoVertical={pageVertical} />
      <LargeHero
        Logo={'manufacturing-os'}
        Copy={data.content.LargeHero.copy}
        BackgroundStyle={'image'}
        BackgroundColour={'off-white'}
        Country={Lang}
        AddSalesTel={true}
        Background={'/assets/images/manufacturing-os/mos-hero-image.png'}
        Button={{
          ModelTitle: globalCopy.Buttons.RequestDemo,
          ModelVertical: pageVertical,
          theme: 'manufacturing-os',
          copy: globalCopy.Buttons.RequestDemo,
          OpenDemoModel: true
        }}
      />
      <BackgroundGrey>
        <FeaturedNavigation
          AdditionalClass={`additional-margin-bottom`}
          ID={data.content.FeaturedNavigation.Nav[0].toLowerCase()}
          Theme={'manufacturing-os'}
          Title={data.content.FeaturedNavigation.Title}
          NavItems={data.content.FeaturedNavigation.Nav}
          Copy={data.content.FeaturedNavigation.Copy}
        />
        <ClientSpotlight Title={data.content.ClientSpotlight.Title} Items={data.content.ClientSpotlight.Items} />
        <HighlightBlock
          AdditionalClass={'build-quality-announcement'}
          BlockWidth={'full-width'}
          Theme={'PrimaryGradient'}
          Button={{
            link: sitemap['build-quality'][lang],
            copy: globalCopy.Buttons.Learn
          }}
          Title={bqData.content.AnnouncementHighlight.Title}
          Copy={bqData.content.AnnouncementHighlight.Copy}
          ImageType={'Split'}
          Image={{
            url: '/assets/2024/images/build-quality/announcement-keyv.png'
          }}
        />
      </BackgroundGrey>
      <BackgroundGrey id={data.content.FeaturedNavigation.Nav[1].toLowerCase()}>
        <Grid
          MicroTitle={data.content.Grid.Microtitle}
          ShowDivider={false}
          Copy={data.content.Grid.Copy}
          Title={data.content.Grid.Title}
          additionalClasses={'flexible '}
          Items={data.content.Grid.Items}
        />
      </BackgroundGrey>

      <BackgroundHalfGreyReverse>
        <HighlightBlock
          Title={data.content.HighlightBlock.Title}
          ImageType={'Split'}
          Theme={'manufacturing-os'}
          Image={{
            url: '/assets/images/manufacturing-os/mos-in-screen.png'
          }}
          Button={{
            ModelTitle: globalCopy.Buttons.RequestDemo,
            ModelVertical: pageVertical,
            copy: globalCopy.Buttons.RequestDemo,
            OpenDemoModel: true
          }}
        />
      </BackgroundHalfGreyReverse>

      <IconGrid
        Title={data.content.IconGrid.Title}
        Subtitle={data.content.IconGrid.Subtitle}
        Items={data.content.IconGrid.Items}
      />

      <BackgroundGrey id={data.content.FeaturedNavigation.Nav[2].toLowerCase()}>
        <TextBlock
          ShowHR={false}
          MicroTitle={data.content.TextBlock.MicroTitle}
          Title={data.content.TextBlock.title}
          Copy={data.content.TextBlock.copy}
        />

        <Grid ShowDivider={false} additionalClasses={'flexible mos'} Items={data.content.Grid2.Items} />
      </BackgroundGrey>

      <ArticleBlock
        ID={data.content.FeaturedNavigation.Nav[3].toLowerCase()}
        Title={'Manufacturing OS: Real-Life Experiences Shared'}
        Layout={'layout-3'}
        MostRecentBlogs={true}
        Topic={'manufacturing-os'}
      />

      <HighlightBlock
        Title={data.content.HighlightBlock2.Title}
        ImageType={'Split'}
        Theme={'manufacturing-os'}
        Image={{
          url: '/assets/images/manufacturing-os/mos-highlight-screens-2.png'
        }}
        Button={{
          ModelTitle: globalCopy.Buttons.RequestDemo,
          ModelVertical: pageVertical,
          copy: globalCopy.Buttons.RequestDemo,
          OpenDemoModel: true
        }}
      />

      <div className={'spacing-xl'}></div>

      <Footer Lang={lang} lang={lang} />
    </PageContainer>
  );
};

export default MOSTemplate;
