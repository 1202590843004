import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

import './../static/assets/fonts/fonts.css';

export const colors = {
  highlight: '#f02542',
  primary: '#234057',
  border: 'rgba(35, 64, 87, 0.2)',
  light: '#59747A',
  light2: '#A8B7BF',
  fossilGrey: '#C4CED4',
  offWhite: '#F7F6F5',
  white: '#ffffff',
  tertiary: '#59747A',
  red: '#D91010',
  green: '#0C7E41',
  teal: '#47BACE',
  darkTeal: '#234057',
  darkRed: '#6E1311',
  orange: '#D3572E',
  secondaryOrange: '#a74625',

  UILightSecondary: '#59747A',
  UILightStackAccent: 'rgba(59, 179, 219, 0.2)',
  UILightInverseSecondary: 'rgba(255,255,255,0.85)',
  UILightTertiary: '#A8B7BF',
  UILightBordersSecondary: '#ced2d6',
  UILightBackground: '#F8F7F6',

  UILightStackSecondary: 'rgba(35, 64, 87, 0.05)',

  solidMOS: '#2C95E2',
  solid3DXpert: '#7CB7C9',
  solidAmphyon: '#046228',
  solidGeomagicDesignx: '#D31618',
  solidGeomagicControlx: '#137940',
  solidFreeform: '#68C1C9',
  solidWrap: '#EA5C2D',
  solidGeomagicForSolidWorks: '#8E1917',
  solidD2P: '#9BB6BF',
};

export const bgGradients = {
  primary: 'linear-gradient(210.9deg, rgb(16, 110, 141) 15.33%, rgb(2, 36, 54) 100.81%);',
  highlight:
    'linear-gradient(85.37deg, #99181D 0%, #DB2229 100%), linear-gradient(105.02deg, #A8B7BF 6.8%, #D5DBDE 98.07%);',
  light: 'linear-gradient(105.02deg, #A8B7BF 6.8%, #D5DBDE 98.07%);',
  threeDXpert: 'linear-gradient(45deg, #568599 0%, #7BBEDA 100%);',
  amphyon: 'linear-gradient(44.35deg, #134D13 0%, #52A043 81.32%);',
  controlx: 'linear-gradient(44.89deg, #085C2F 0%, #0C8243 101.37%);',
  geomagicSolidWorks: 'linear-gradient(39.09deg, #6E1311 2.63%, #941917 94.22%);',
  designx: 'linear-gradient(43.73deg, #99181D 8.58%, #DB2229 83.32%);',
  wrap: 'linear-gradient(44.89deg, #C9562E 0%, #EA5C2D 101.37%);',
  essentials: 'linear-gradient(43.97deg, #B89C49 -2.36%, #F7D262 95.52%);',
  freeform: 'linear-gradient(85.37deg, #5CA2A8 0%, #70C6CE 100%);',
  threeDSprint: 'linear-gradient(44.89deg, #295E87 6.74%, #3C89C6 83.53%);',
  d2p: 'linear-gradient(45deg, #839299 0%, #A4B9C0 72.07%);',
  dark: 'linear-gradient(180deg, #032B40 -1.76%, #022334 100%), linear-gradient(105.02deg, #A8B7BF 6.8%, #D5DBDE 98.07%);',
  mos: 'linear-gradient(45deg, #257EBF 2.63%, #31A8FF 94.22%);'
};

export const mediaQueriesSizes = {
  xxxl: 1900,
  xxl: 1500,
  xl: 1169,
  lg: 900,
  md: 700,
  sm: 500
};
export const containerWidths = {
  xxxl: 1860,
  xxl: 1400,
  xl: 1139,
  lg: 870,
  md: 670,
  sm: 470
};

export const BackgroundWhite = styled.div`
  background: #ffffff;
`;

export const BackgroundGDX = styled.div`
  background: ${bgGradients.designx};
`;
export const Background3DX = styled.div`
  background: ${bgGradients.threeDXpert};
`;

export const BackgroundG4S = styled.div`
  background: ${bgGradients.geomagicSolidWorks};
`;

export const BackgroundGCX = styled.div`
  background: ${bgGradients.controlx};
`;

export const BackgroundGrey = styled.div`
  background: #f8f7f6;
  
  &.increaseZIndex{
    position: relative;
    z-index: 1;
    
    
  }
  
`;

export const BackgroundBlue = styled.div`
  background: ${colors.primary};
`;

export const BackgroundGradientBlue = styled.div`
  background: linear-gradient(219.03deg, #106e8d 19.76%, #022436 115.88%);
`;

export const BackgroundGradientPrimary = styled.div`
  background: ${bgGradients.primary}
`;

export const BackgroundHalfOffWhite = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    background: ${colors.offWhite};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const BackgroundHalfGrey = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    background: #f8f7f6;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const BackgroundHalfBlue = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    background: ${colors.primary};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;
export const BackgroundHalfGreyReverse = styled.div`
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    background: #f8f7f6;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const BackgroundGreyMeshBottom = styled.div`
  background: #f8f7f6;
  background-image: url('/assets/images/backgrounds/contact-page-mesh.webp');
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
`;

export const BackgroundWave = styled.div`
  background-image: url('/assets/images/backgrounds/background-wave.webp');
  background-size: cover;
  background-position: top center;
`;

export const BackgroundCircles = styled.div`
  background-image: url('/assets/images/background/circles.png');
  background-size: cover;
  background-position: top center;
`;

export const BackgroundCircles2 = styled.div`
  background-image: url('/assets/images/backgrounds/background-circles-2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0;
`;

export const BackgroundCircles3 = styled.div`
  position: relative;
  .container{
    position: relative;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 200%;
    background-image: url('/assets/images/homepage-v2/background_circles-2.svg');
    position: absolute;
    top: -50%;
    pointer-events: none;
    z-index: 0;
    background-size: 100% auto;
    opacity: 0.5;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

export const BackgroundTrippleCurves = styled.div`
  background-image: url('/assets/images/backgrounds/curves.png');
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
`;

const GlobalStyle = createGlobalStyle`
  .sb-show-main.sb-main-padded {
    padding: 0;
  }

  .hidden {
    display: none !important;
  }

  body {
    overflow-x: hidden;
    font-family: 'centrano2', sans-serif;
    line-height: 1.1;

    &.zh-cn-body {
      p, h3 {
        font-weight: 400 !important;
      }
    }

    .container {
      width: 100%;
      padding: 0 20px;
      margin: 0 auto;

      @media (min-width: ${mediaQueriesSizes.sm}px) {
        padding: 0 15px;
        width: ${containerWidths.sm}px;
      }
      @media (min-width: ${mediaQueriesSizes.md}px) {
        width: ${containerWidths.md}px;
      }
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        width: ${containerWidths.lg}px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        width: ${containerWidths.xl}px;
      }
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        width: ${containerWidths.xxl}px;
      }
    }

    .spacing-sm {

    }

    .spacing-sm {
      display: block;
      height: 24px;
    }

    .spacing-md {
      display: block;
      height: 40px;
    }

    .spacing-lg {
      display: block;
      height: 54px;
    }

    .spacing-xl {
      display: block;
      height: 72px;
    }

    .background-circle-cutout {
      width: 100%;
      margin-bottom: -10px;
    }

    .background-curves {
      width: 100vw;
      margin-top: -8%;
      margin-bottom: -10%;

      img {
        width: 100%;
      }
    }

    div.title-underline, hr.divider {
      width: 64px;
      height: 2px;
      background: ${colors.highlight};
      display: block;
      margin: 0;
      border: none;

      &.center {
        margin-left: auto;
        margin-right: auto;
      }

      &.large {
        width: 80px;
      }

      &.full {
        width: 100%;
      }

      &.teal {
        background: ${colors.teal};
      }
    }

    h1, h2, h3, h4, h5, h6, p {
      margin: 0;
    }

    div, a {
      box-sizing: border-box;
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.01em;
      text-align: left;
      color: ${colors.primary};
      margin: 0;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 32px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 40px;
      }
    }
    
    

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: 0.01em;
      color: ${colors.light};

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 20px;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 24px;
      }


    }

    button {
      font-family: 'centrano2', sans-serif;
    }
  }

  .polymer-form-container {
    background-color: #F8F7F6;
    background-image: url('/assets/2024/images/polymers/form-background-image.png');
    padding: 50px 0 0;
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding: 120px 0 0;
      background-size: contain;

    }

    h3 {
      font-family: centrano2-book;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 125%;
      color: ${colors.primary};
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 28px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 125%;
      text-align: center;
      color: ${colors.primary};
      max-width: 800px;
      margin: 10px auto 20px;
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 36px;
      }
    }

    hr.divider.teal {
      margin: 30px auto 50px;
    }


  }

`;

export default GlobalStyle;
