import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import GlobalStyle from '../../../GlobalStyles.jsx';
import axios from 'axios';
import Countries from './../../../../data/country-state-data.json';
import { FormStyles, NewsletterWrapper } from './styles.jsx';
import Recaptcha from 'react-recaptcha';
import { InputField } from './InputField';
import { sendGAEvent } from '../../../helpers/helper';

const ReCaptchaComp = ({ onVerify }) => {
  const handleVerify = recaptchaResponse => {
    if (recaptchaResponse) {
      onVerify(recaptchaResponse);
    }
  };

  return (
    <Recaptcha sitekey="6LfVrjgoAAAAABu1s9CvuamcuiQSmT3veo4hS4-e" size="invisible" verifyCallback={handleVerify} />
  );
};

interface Props {
  Fields?: object;
  Lang?: string;
  Categories?: object;
}

export const NewsletterFormComponent = ({ Categories, Fields, Lang = 'en', ...props }: Props) => {
  const copy = require(`../../../../data/forms/${Lang}.json`);

  console.log(Categories.map(item => item.title).join(', '));

  function sortByCountry(jsonObj) {
    const sortedKeys = Object.keys(jsonObj).sort((a, b) => {
      const countryA = jsonObj[a]['Country'].toLowerCase();
      const countryB = jsonObj[b]['Country'].toLowerCase();
      return countryA.localeCompare(countryB);
    });

    const sortedJsonObj = {};
    sortedKeys.forEach(key => {
      sortedJsonObj[key] = jsonObj[key];
    });

    return sortedJsonObj;
  }

  const countryArray = Object.values(sortByCountry(Countries));
  const [formCompleted, setFormCompleted] = useState(false);
  const [formInProgress, setFormInProgress] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [activeCountryData, setActiveCountryData] = useState(null);

  const [formData, setFormData] = useState({
    utm_term: '',
    utm_content: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    product_interest: Categories.map(item => item.title).join(', '),
    preferred_lang: Lang,
    first_name: '',
    last_name: '',
    campaign_id: '',
    asset_campaign_id: '',
    email: '',
    message: '',
    company: '',
    rb: '',
    country: '',
    state: ''
  });

  const [touchedFields, setTouchedFields] = useState({
    product_interest: false,
    first_name: false,
    last_name: false,
    campaign_id: false,
    asset_campaign_id: false,
    email: false,
    message: false,
    company: false,
    rb: false,
    country: false,
    state: false
  });

  const [errors, setErrors] = useState([]);

  const handleRecaptchaVerify = token => {
    setRecaptchaToken(token);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    if (name === 'country') {
      setErrors(errors.filter(item => item !== copy.fields.state.error.required));
      setActiveCountryData(Countries[value.toLowerCase().replace(/\s/g, '-')]);
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const isValidEmail = email => {
    // Regular expression for email validation
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the pattern
    return emailPattern.test(email);
  };

  const handleTouch = e => {
    const { name, value } = e.target;

    setTouchedFields({
      ...touchedFields,
      [name]: true
    });

    // If the field is a required field
    if (
      name === 'first_name' ||
      name === 'last_name' ||
      name === 'country' ||
      name === 'state' ||
      name === 'email' ||
      name === 'product_interest' ||
      name === 'message'
    ) {
      //If the field is not filled in
      if (formData[name].length < 1) {
        setErrors(prevErrors => {
          // Create a Set to store unique errors
          const uniqueErrors = new Set(prevErrors);

          // Add the new error to the Set
          uniqueErrors.add(copy['fields'][name]['error']['required']);

          // Convert the Set back to an array
          return [...uniqueErrors];
        });
      } else {
        if (name === 'email') {
          setErrors(prevErrors => {
            // Create a Set to store unique errors
            const uniqueErrors = new Set(prevErrors);

            // Remove the specific string from the Set
            const stringToRemove = copy['fields'][name]['error']['required'];
            uniqueErrors.delete(stringToRemove);

            // Convert the Set back to an array
            return [...uniqueErrors];
          });

          if (!isValidEmail(formData[name])) {
            setErrors(prevErrors => {
              // Create a Set to store unique errors
              const uniqueErrors = new Set(prevErrors);

              // Add the new error to the Set
              uniqueErrors.add(copy['fields'][name]['error']['invalid']);

              // Convert the Set back to an array
              return [...uniqueErrors];
            });
          } else {
            setErrors(prevErrors => {
              // Create a Set to store unique errors
              const uniqueErrors = new Set(prevErrors);

              // Remove the specific string from the Set
              const stringToRemove = copy['fields'][name]['error']['invalid'];
              uniqueErrors.delete(stringToRemove);

              // Convert the Set back to an array
              return [...uniqueErrors];
            });
          }
        } else {
          setErrors(prevErrors => {
            // Create a Set to store unique errors
            const uniqueErrors = new Set(prevErrors);

            // Remove the specific string from the Set
            const stringToRemove = copy['fields'][name]['error']['required'];
            uniqueErrors.delete(stringToRemove);

            // Convert the Set back to an array
            return [...uniqueErrors];
          });
        }
      }
    }
  };

  const handleSubmit = async e => {
    let apiLang = '';
    if (process.env.GATSBY_LANG_DEFAULT === 'zh-cn') {
      apiLang = 'cn-';
    }

    setFormInProgress(true);
    sendGAEvent('Form Submissions', 'Submission Attempted', 'Newsletter Form', 'current-url');
    e.preventDefault();
    try {
      if (formData['rb'] !== '' && window && window.location.origin !== 'http://localhost:8000') {
        alert('Robot Detected - We have detected that this form has been automatically filled in');
        setFormInProgress(false);
        return;
      }

      const response = await axios.post(
        `https://middleware-oqton.netlify.app/.netlify/functions/${apiLang}newsletter-sign-up`,
        formData
      );
      sendGAEvent('Form Submissions', 'Submission Success', 'Newsletter Form', 'current-url');
      setFormCompleted(true);
    } catch (error) {
      sendGAEvent('Form Submissions', 'Submission Failed', 'Newsletter Form', 'current-url');
      setFormInProgress(false);
      alert('error submitting the form');
      console.error('Error submitting this form:', error);
    }
  };

  useEffect(() => {
    const cookieValue = Cookies.get('__utmzz');

    if (cookieValue != null && cookieValue != undefined) {
      const parsedData = cookieValue.split('|').reduce((acc, section) => {
        const match = section.match(/(utm\w+)=\(?([^\)]+)\)?/);
        if (match) {
          const [, key, value] = match;
          acc[key] = value;
        }
        return acc;
      }, {});

      setFormData({
        ...formData,
        ['utm_source']: parsedData['utmcsr'],
        ['utm_campaign']: parsedData['utmccn'],
        ['utm_medium']: parsedData['utmcmd']
      });
    }
  }, []);

  useEffect(() => {
    if (window) {
      if (formData['utm_content'] === '') {
        setFormData({
          ...formData,
          ['utm_content']: window.location.href
        });
      }
    }
  }, []);

  return (
    <>
      <NewsletterWrapper>
        {!formCompleted && (
          <>
            <div className={'text-wrap'}>
              <h3>Subscribe to our newsletter</h3>
              <p>Get our best content straight in your inbox</p>
            </div>
            <FormStyles onSubmit={handleSubmit} className={`Component-Form in-progress-${formInProgress}`} {...props}>
              <label className={'robot-check'}>
                Checker
                <input type={'text'} value={formData.rb} name={'rb'} onChange={handleChange} />
              </label>

              <label className={'hidden'}>
                Campaign
                <select name="asset_campaign_id" value={formData.asset_campaign_id} onChange={handleChange}>
                  <option value="">No Campaign ID</option>
                  <option value="7012T000001bPscQAE">Website Form Homepage Contact Form</option>
                </select>
              </label>

              <InputField
                required={true}
                touchedFields={touchedFields}
                formData={formData}
                copy={copy}
                handleTouch={handleTouch}
                handleChange={handleChange}
                fieldName={'first_name'}
                fieldType={'text'}
              />
              <InputField
                required={true}
                touchedFields={touchedFields}
                formData={formData}
                copy={copy}
                handleTouch={handleTouch}
                handleChange={handleChange}
                fieldName={'last_name'}
                fieldType={'text'}
              />
              <InputField
                required={true}
                touchedFields={touchedFields}
                formData={formData}
                copy={copy}
                handleTouch={handleTouch}
                handleChange={handleChange}
                fieldName={'email'}
                fieldType={'email'}
              />

              <p className={`${errors.length > 0 ? 'errors' : 'errors hidden'}`}>
                <span className={'validation'}>
                  {copy.errors.validation}
                  <ul>
                    {errors &&
                      errors.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                  </ul>
                </span>
              </p>

              <label className={'full-width'}>
                <input disabled={errors.length !== 0} type="submit" value={copy.fields.subscribe.label} />
              </label>

              <p className={'preferences content'}>
                <a
                  className={'preferences content'}
                  target="_blank"
                  href={
                    'https://go.oqton.com/emailPreference/e/epc/976933/NGP-J4Rx44PTMQzL0HR__90ZlUe1aWgTcG8Tl6bx5xw/320'
                  }
                >
                  {copy.preferences}
                </a>
              </p>
            </FormStyles>
          </>
        )}
        {formCompleted && (
          <div className={'text-wrap'}>
            <h3>Thank you for subscribing!</h3>
            <p>Stay tuned for updates and exclusive content.</p>
            <p className={'preferences'}>
              <a
                className={'preferences content'}
                target="_blank"
                href={
                  'https://go.oqton.com/emailPreference/e/epc/976933/NGP-J4Rx44PTMQzL0HR__90ZlUe1aWgTcG8Tl6bx5xw/320'
                }
              >
                {copy.preferences}
              </a>
            </p>
          </div>
        )}
      </NewsletterWrapper>
    </>
  );
};
