import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { ThankYou } from '../components/layouts/ThankYou';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const ContactUsThankYou = () => {
  return (
    <PageContainer>
      <HelmetContainer title="Thank you" />
      <Main noPadding>
        <Content>
          <Header />
          <ThankYou
            customText={<>저희 팀에 연락해 주셔서 감사합니다. 가능한 한 빨리 귀하의 요청에 응답하겠습니다.</>}
          />
        </Content>
      </Main>
      <Footer />
    </PageContainer>
  );
};

export default ContactUsThankYou;
