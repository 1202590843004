import React from 'react';
import { CustomerStorySpotlight } from '../../components/layouts/CustomerStorySpotlight';
import { Footer } from '../../components/layouts/Footer';
import { Header } from '../../components/layouts/HeaderComponent';
import { Hero } from '../../components/layouts/Hero';
import { MediaComponent } from '../../components/layouts/MediaComponent';
import { ProductSwitch } from '../../components/layouts/ProductSwitch';
import { Statistics } from '../../components/layouts/Statistics';
import { UnevenCardContainer } from '../../components/layouts/UnevenCardContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { HeaderBackground } from '../../components/pageComponents/home';
import { HeaderSubtitle } from '../../components/subComponents/HeaderSubtitle';
import { useGetInnerWidth } from '../../utils/hooks';
import { PageContainer } from '../../components/containers/PageContainer';
import HomepageTemplate from '../../templates/HomepageTemplate';

const HomeRD = () => {
  return <HomepageTemplate Lang={'it'} />;
};

export default HomeRD;
