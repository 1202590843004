import React from 'react';

import { BenefitsIconsStyles, IconWrap } from './styles.jsx';
import { createMarkUp } from '../helpers';

interface Props {
  title?: string;
  subtitle?: string;
  icons?: object;
}

export const BenefitsIcons = ({ title, subtitle, icons, ...props }: Props) => {
  return (
    <BenefitsIconsStyles>
      <div className={'container'}>
        <h4>{subtitle}</h4>
        <h2>{title}</h2>

        <IconWrap>
          {icons.map((item, index) => {
            return (
              <div className={'benefit'} key={index}>
                <img src={item.icon.url} /> <h5>{item.label}</h5>
              </div>
            );
          })}
        </IconWrap>
      </div>
    </BenefitsIconsStyles>
  );
};
