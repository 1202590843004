import React from 'react';
import styled from 'styled-components';

import { spacing, colors, media } from '../../../../foundations';
import { HeadingM, HeadingMLight, Tag } from '../../../../../styles/typography';

interface HeaderProps {
  customer: string;
  title: string;
  category: string;
  subcategory: string;
}

const HeaderContainer = styled.section`
  margin: ${spacing.xxxl}px auto;
  padding-top: ${spacing.xxl}px;

  width: 960px;

  @media screen and (max-width: ${media.lg}px) {
    width: 100%;
  }
`;

const Customer = styled.p`
  ${HeadingMLight};
`;
const Title = styled.h1`
  ${HeadingM};

  margin-bottom: ${spacing.xxl}px;
`;
const Category = styled.p`
  ${Tag};
  color: ${colors.secondaryAccent};

  width: 360px;

  padding-top: ${spacing.l}px;
  border-top: solid 2px ${colors.secondaryAccent};
`;

export const Header = ({ customer, title, category, subcategory }: HeaderProps) => {
  return (
    <HeaderContainer>
      <Customer>{customer}</Customer>
      <Title>{title}</Title>
      <Category>
        {subcategory} {category}
      </Category>
    </HeaderContainer>
  );
};
