import React, { useEffect, useState } from 'react';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { Locations } from '../components/pageComponents/contact';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

import {
  ContactFormContainer,
  FormTitle,
  Text,
  LocationsLink,
  FormContainerTaller
} from '../components/pageComponents/contact/ContactStyles';
import { Bold } from '../styles/typography';
import { Icon } from '../components/subComponents/Icon';

const Form = () => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    // Test with this url: /form/?form=/2022-04-28/223w or /form/?form=/2022-04-18/rw3 or /form/?form=/2022-04-28/225z
    // https://www.oqton.com/form/?form=/2022-04-28/2246i
    // https://go.oqton.com/l/976933/2022-04-28/2246i
    // https://go.oqton.com/l/976933/2022-04-28/223w
    // Get the form parameter from url:
    let data = typeof window !== 'undefined' ? window.location.search : '';
    data = data.replace(/\?form=/g, '');
    // Set up a regular expression to make sure the data looks correct:
    let pattern = /^\/\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12]\d|3[01])\/[\da-z]+$/;
    let testResult = pattern.test(data);

    // If the data looks correct send the full url to the form:
    testResult ? (data = `https://go.oqton.com/l/976933${data}`) : (data = '');
    setUrl(data);
  }, []);
  return (
    <PageContainer>
      <HelmetContainer title="Contact us" />
      <h1 hidden>Contact us</h1>
      <Header />
      <Content>
        <ContactFormContainer>
          <section>
            <FormTitle>
              <Bold>Get in touch</Bold>
              <br />
              with us?
            </FormTitle>
            <Text>
              Have a question?
              <br /> Send us a note and we'll get back to you.
            </Text>
            <LocationsLink href="#locations">
              Our locations
              <Icon name="Caret" color="quaternaryAccent" ml={14} />
            </LocationsLink>
          </section>
          <FormContainerTaller title="Contact form" src={url} width="100%" height="100%" />
        </ContactFormContainer>
      </Content>
      <Locations />
      <Footer />
    </PageContainer>
  );
};

export default Form;
