import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { TermsAndConditionsContainer } from '../components/pageComponents/termsandConditions/TermsAndConditions';
import { HelmetContainer } from '../components/pageComponents/helmet';

const TermsAndConditions = () => {
  return (
    <PageContainer>
      <HelmetContainer title="Terms and Conditions" />
      <Main noPadding>
        <Header />
        <Content>
          <h1 hidden>Terms and Conditions</h1>
          <TermsAndConditionsContainer />
        </Content>
      </Main>
      <Footer />
    </PageContainer>
  );
};

export default TermsAndConditions;
