import { FormComponent } from '../components/V2/Form/Form';

import React from 'react';
import { BackgroundGrey } from '../GlobalStyles';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Header } from '../components/layouts/HeaderComponent';

const FormTest = () => {
  const Lang = 'en';

  return (
    <>
      <BackgroundGrey>
        <PageContainer>
          <HelmetContainer currentPage={'additive'} lang={Lang} title={'form test'} />
          <Header demoVertical={'additive'} lang={Lang} />
          <div className={'container'}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <FormComponent />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </PageContainer>
      </BackgroundGrey>
    </>
  );
};

export default FormTest;
