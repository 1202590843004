import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { LogoWrap } from './styles.jsx';

import MOSDark from './logos/manufacturing-os-dark.svg';
import MOSLight from './logos/manufacturing-os-light.svg';
import MOSOutline from './logos/manufacturing-os-outline.svg';

import GDesignXDark from './logos/geomagic-designx-dark.svg';
import GDesignXLight from './logos/geomagic-designx-light.svg';
import GDesignXoutline from './logos/geomagic-designx-outline.svg';

import GDesignXGoDark from './logos/geomagic-designx-go-dark.svg';
import GDesignXGoLight from './logos/geomagic-designx-go-light.svg';
import GDesignXGooutline from './logos/geomagic-designx-go-outline.svg';

import GDesignXPlusDark from './logos/geomagic-designx-plus-dark.svg';
import GDesignXPlusLight from './logos/geomagic-designx-plus-light.svg';
import GDesignXPlusoutline from './logos/geomagic-designx-plus-outline.svg';

import GDesignXProDark from './logos/geomagic-designx-pro-dark.svg';
import GDesignXProLight from './logos/geomagic-designx-pro-light.svg';
import GDesignXProoutline from './logos/geomagic-designx-pro-outline.svg';

import GDesignXGoShortDark from './logos/geomagic-designx-go-short-dark.svg';
import GDesignXGoShortLight from './logos/geomagic-designx-go-short-light.svg';
import GDesignXGoShortoutline from './logos/geomagic-designx-go-short-outline.svg';

import GDesignXPlusShortDark from './logos/geomagic-designx-plus-short-dark.svg';
import GDesignXPlusShortLight from './logos/geomagic-designx-plus-short-light.svg';
import GDesignXPlusShortoutline from './logos/geomagic-designx-plus-short-outline.svg';

import GDesignXProShortDark from './logos/geomagic-designx-pro-short-dark.svg';
import GDesignXProShortLight from './logos/geomagic-designx-pro-short-light.svg';
import GDesignXProShortoutline from './logos/geomagic-designx-pro-short-outline.svg';

import GControlXDark from './logos/geomagic-controlx-dark.svg';
import GControlLight from './logos/geomagic-controlx-light.svg';
import GControloutline from './logos/geomagic-controlx-outline.svg';

import GFreeformDark from './logos/geomagic-freeform-dark.svg';
import GFreeformLight from './logos/geomagic-freeform-light.svg';
import GFreeformoutline from './logos/geomagic-freeform-outline.svg';

import GWrapDark from './logos/geomagic-wrap-dark.svg';
import GWrapLight from './logos/geomagic-wrap-light.svg';
import GWrapoutline from './logos/geomagic-wrap-outline.svg';

import ThreeDXpertDark from './logos/3dxpert-dark.svg';
import ThreeDXpertLight from './logos/3dxpert-light.svg';
import ThreeDXpertoutline from './logos/3dxpert-outline.svg';

import AmphyonDark from './logos/amphyon-dark.svg';
import AmphyonLight from './logos/amphyon-light.svg';
import Amphyonoutline from './logos/amphyon-outline.svg';

import D2pDark from './logos/d2p-dark.svg';
import D2pLight from './logos/d2p-light.svg';
import D2poutline from './logos/d2p-outline.svg';

import G4SDark from './logos/G4SDark.svg';
import G4SLight from './logos/G4SLight.svg';
import G4Soutline from './logos/G4S-outline.svg';

interface Props {
  Link?: boolean;
  Lang?: string;
  Logo?:
    | 'manufacturing-os'
    | 'geomagic-for-solid'
    | 'geomagic-for-solidworks'
    | 'geomagic-designx'
    | 'geomagic-designx-go'
    | 'geomagic-designx-plus'
    | 'geomagic-designx-pro'
    | 'geomagic-designx-go-short'
    | 'geomagic-designx-plus-short'
    | 'geomagic-designx-pro-short'
    | 'geomagic-design-x'
    | 'geomagic-controlx'
    | 'geomagic-control-x'
    | 'geomagic-freeform'
    | 'freeform'
    | 'geomagic-wrap'
    | '3dxpert'
    | 'amphyon'
    | 'd2p';
  Theme?: 'light' | 'dark' | 'outline';
  Size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const getURL = ({ Lang, logo }: { Lang: any; logo: any }) => {
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);

  if (logo === 'geomagic-design-x') {
    logo = 'geomagic-designx';
  }
  if (logo === 'geomagic-control-x') {
    logo = 'geomagic-controlx';
  }
  if (logo === 'geomagic-for-solidworks') {
    logo = 'geomagic-for-solid';
  }

  switch (logo) {
    case 'manufacturing-os':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.manufacturingOs;
      }
      return '/manufacturing-os/';
    case 'geomagic-designx':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.geoDesignX;
      }
      return '/geomagic-designx/';
    case 'geomagic-controlx':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.geoControlX;
      }
      return '/geomagic-controlx/';
    case 'geomagic-freeform':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.geoFreeeform;
      }
      return '/geomagic-freeform/';
    case 'geomagic-wrap':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.geoWrap;
      }
      return '/geomagic-wrap/';
    case '3dxpert':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.Threedxpert;
      }
      return '/3dxpert/';
    case 'amphyon':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.amphyon;
      }
      return '/amphyon/';
    case 'd2p':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.d2p;
      }
      return '/d2p/';
    case 'geomagic-for-solid':
      if (Lang !== 'en') {
        return globalCopy.Navigation.ProductURLs.geoDesignX;
      }
      return '/geomagic-for-solidworks/';
    default:
    // code block
  }
};

const getLogo = (logo, theme) => {
  switch (logo) {
    case 'manufacturing-os':
      if (theme === 'light' || theme === '') {
        return MOSLight;
      }
      if (theme === 'outline') {
        return MOSOutline;
      }
      return MOSDark;
    case 'geomagic-designx':
      if (theme === 'light' || theme === '') {
        return GDesignXLight;
      }
      if (theme === 'outline') {
        return GDesignXoutline;
      }
      return GDesignXDark;
    case 'geomagic-designx-go':
      if (theme === 'light' || theme === '') {
        return GDesignXGoLight;
      }
      if (theme === 'outline') {
        return GDesignXGooutline;
      }
      return GDesignXGoDark;
    case 'geomagic-designx-plus':
      if (theme === 'light' || theme === '') {
        return GDesignXPlusLight;
      }
      if (theme === 'outline') {
        return GDesignXPlusoutline;
      }
      return GDesignXPlusDark;
    case 'geomagic-designx-pro':
      if (theme === 'light' || theme === '') {
        return GDesignXProLight;
      }
      if (theme === 'outline') {
        return GDesignXProoutline;
      }
      return GDesignXProDark;
    case 'geomagic-designx-go-short':
      if (theme === 'light' || theme === '') {
        return GDesignXGoShortLight;
      }
      if (theme === 'outline') {
        return GDesignXGoShortoutline;
      }
      return GDesignXGoShortDark;
    case 'geomagic-designx-plus-short':
      if (theme === 'light' || theme === '') {
        return GDesignXPlusShortLight;
      }
      if (theme === 'outline') {
        return GDesignXPlusShortoutline;
      }
      return GDesignXPlusShortDark;
    case 'geomagic-designx-pro-short':
      if (theme === 'light' || theme === '') {
        return GDesignXProShortLight;
      }
      if (theme === 'outline') {
        return GDesignXProShortoutline;
      }
      return GDesignXProShortDark;
    case 'geomagic-controlx':
      if (theme === 'light' || theme === '') {
        return GControlLight;
      }
      if (theme === 'outline') {
        return GControloutline;
      }

      return GControlXDark;
    case 'geomagic-freeform':
      if (theme === 'light' || theme === '') {
        return GFreeformLight;
      }
      if (theme === 'outline') {
        return GFreeformoutline;
      }

      return GFreeformDark;
    case 'freeform':
      if (theme === 'light' || theme === '') {
        return GFreeformLight;
      }
      if (theme === 'outline') {
        return GFreeformoutline;
      }

      return GFreeformDark;
    case 'geomagic-wrap':
      if (theme === 'light' || theme === '') {
        return GWrapLight;
      }
      if (theme === 'outline') {
        return GWrapoutline;
      }

      return GWrapDark;
    case '3dxpert':
      if (theme === 'light' || theme === '') {
        return ThreeDXpertLight;
      }
      if (theme === 'outline') {
        return ThreeDXpertoutline;
      }

      return ThreeDXpertDark;
    case 'amphyon':
      if (theme === 'light' || theme === '') {
        return AmphyonLight;
      }
      if (theme === 'outline') {
        return Amphyonoutline;
      }

      return AmphyonDark;
    case 'd2p':
    case 'dicom-to-print':
      if (theme === 'light' || theme === '') {
        return D2pLight;
      }
      if (theme === 'outline') {
        return D2poutline;
      }

      return D2pDark;
    case 'geomagic-for-solid':
      if (theme === 'light' || theme === '') {
        return G4SLight;
      }
      if (theme === 'outline') {
        return G4Soutline;
      }
      return G4SDark;
    default:
    // code block
  }
};

export const ProductLogo = ({
  Logo = 'manufacturing-os',
  Theme = 'light',
  Size = 'md',
  Lang = 'en',
  Link = false,
  ...props
}: Props) => {
  if (Logo === 'geomagic-design-x') {
    Logo = 'geomagic-designx';
  }
  if (Logo === 'geomagic-control-x') {
    Logo = 'geomagic-controlx';
  }
  if (Logo === 'geomagic-for-solidworks') {
    Logo = 'geomagic-for-solid';
  }

  return (
    <LogoWrap className={'Component-ProductLogo size-' + Size} {...props}>
      <GlobalStyle />
      {Link ? (
        <a href={getURL({ Lang: Lang, logo: Logo })}>
          <img src={getLogo(Logo, Theme)} title={Logo} alt={Logo} />
        </a>
      ) : (
        <img src={getLogo(Logo, Theme)} title={Logo} alt={Logo} />
      )}
    </LogoWrap>
  );
};
