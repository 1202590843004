import React from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { StatisticsHomepageBlockStyles, Stat, StatWrapper, FormWrapper, TextStyles } from './styles.jsx';
import { createMarkUp } from '../helpers';
import { FormComponent } from '../Form/Form';

interface Props {
  Lang?: string;
  Copy?: string;
  Items?: object;
  Block2: object;
}

export const StatisticsHomepageBlock = ({ Lang = 'en', Copy = '', Items = [], Block2 = [], ...props }: Props) => {
  return (
    <>
      <StatisticsHomepageBlockStyles {...props}>
        <div className={'container'}>
          <div className={'inner'}>
            <h2 dangerouslySetInnerHTML={createMarkUp(Copy)}></h2>
            <StatWrapper>
              {Items &&
                Items.map((item, index) => (
                  <Stat key={index}>
                    <p>
                      <span>{item.value}</span> {item.stat}
                    </p>
                  </Stat>
                ))}
            </StatWrapper>
          </div>
        </div>
      </StatisticsHomepageBlockStyles>
      <TextStyles>
        <div className={'line-vertical-divider'}></div>
        <div className={'container'}>
          <h3>{Block2.microtitle}</h3>
          <h2>{Block2.title}</h2>
          <p>{Block2.copy}</p>
        </div>
        <img alt="" className={'arrow'} src={'/assets/images/homepage-v2/blue_arrow.svg'} />
      </TextStyles>
      <FormWrapper id={'contact-form'}>
        <div className={'container'}>
          <FormComponent Lang={Lang} HiddenFields={'message'} />
        </div>
      </FormWrapper>
    </>
  );
};
