import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../GlobalStyles";

export const IconGridComponentStyles = styled.div`
  padding: 64px 0;

  h4 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: ${colors.highlight};
    margin-bottom: 16px;
  }

  h2 {
    font-weight: 500;
    letter-spacing: 0.02em;
    color: ${colors.primary};
    margin-bottom: 32px;
    font-size: 24px;
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 72px 0;
    h4 {
      font-size: 18px;
    }
    h2 {
      margin-bottom: 64px;
      font-size: 42px;
    }
  }
  
  &.theme-dark{
    background: ${colors.primary};
    h2{
      color: ${colors.white};
    }
  }
  

`;

export const TableWrap = styled.div`
  display: grid;
  grid-column-gap: 64px;
  grid-row-gap: 0;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  &.counter-3,&.counter-6 {
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

export const ItemStyle = styled.div`
  border-top: 1px solid rgba(35, 64, 88, 0.2);
  border-bottom: 1px solid rgba(35, 64, 88, 0.2);
  padding: 24px 0;
  &:nth-child(n+1):nth-child(-n+3) {
    border-bottom: none;
  }
  
  img{
    height: 48px;
    margin-bottom: 24px;
  }
  
  h5{
    color: ${colors.primary};
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  
  p{
    color: ${colors.UILightSecondary};
    font-size: 18px;
    font-weight: 300;
    line-height: 1.7;
  }
  
  &.theme-dark{
    border-top: 1px solid rgba(255,255,255,0.4);
    border-bottom: 1px solid rgba(255,255,255,0.4);
    h5{
      color: white;
    }
    p{
      color: rgba(255,255,255,0.4);
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 28px 0;

  }


`;
