import React, { useEffect, useState } from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { BackgroundGrey, BackgroundHalfGreyReverse } from '../GlobalStyles';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { FeaturedNavigation } from '../components/V2/FeaturedNavigation/FeaturedNavigation';
import { ChildPageLinks } from '../components/V2/ChildPageLinks/ChildPageLinks';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { Grid } from '../components/V2/Grid/Grid';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';

interface Props {
  Lang?: string;
}

const FreeformTemplate = ({ Lang = 'en', wordpressData = null, ...props }: Props) => {
  let globalCopy = require(`../../data/content/Global/${Lang}.json`);
  let freeformHaptic = require(`../../data/content/Pages/freeform-haptic/${Lang}.json`);
  const vertical = 'NewForm-Freeform';

  let GridItems = [];

  if (wordpressData != null) {
    if (wordpressData.fields) {
      wordpressData.fields.product_feature_group.features.map((item, key) => {
        let temp = {
          Component: 'GridItem6',
          ComponentSize: '',
          Copy: {
            BackgroundImage: item.image.url,
            Title: item.title,
            Copy: item.copy
          }
        };
        GridItems.push(temp);
      });
    }

    return (
      <>
        <PageContainer lang={Lang}>
          <HelmetContainer
            image={wordpressData.fields.seo.page_share_image.url}
            title={wordpressData.fields.seo.page_title}
            pageDescription={wordpressData.fields.seo.page_meta_description}
            description={wordpressData.fields.seo.page_meta_description}
          />

          <Header demoVertical={vertical} lang={Lang} />

          <LargeHero
            Logo={'geomagic-freeform'}
            Country={Lang}
            AddSalesTel={wordpressData.fields.hero.hero_.add_sales_number}
            BackgroundStyle={'image'}
            Copy={`<h1><b>${wordpressData.fields.hero.hero_.title}</b></h1>${wordpressData.fields.hero.hero_.copy}`}
            Background={wordpressData.fields.hero.hero_.image.url}
            Button={{
              theme: 'geomagic-freeform',
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.ContactUs,
              OpenDemoModel: true
            }}
          />

          <FeaturedNavigation
            AdditionalClass={'annoucment-at-top'}
            Theme={'freeform'}
            Image={{ link: wordpressData.fields.introduction_block.image.url }}
            Title={wordpressData.fields.introduction_block.title}
            NavItems={[]}
            Copy={wordpressData.fields.introduction_block.copy}
          />

          <BackgroundGrey>
            <ChildPageLinks
              Lang={Lang}
              Items={wordpressData.fields.sub_page_navigation.sub_pages}
              Title={wordpressData.fields.sub_page_navigation.title}
            />
          </BackgroundGrey>
          <HighlightBlock
            BlockWidth={'full-width'}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            MicroTitle={wordpressData.fields.highlight_block_one.subtitle}
            Theme={'freeform'}
            ImageType={'Split'}
            Title={`<b>${wordpressData.fields.highlight_block_one.title}</b>`}
            Image={{
              url: wordpressData.fields.highlight_block_one.image.url
            }}
          />

          <Grid
            ShowDivider={false}
            Copy={wordpressData.fields.product_feature_group.subtitle}
            Title={wordpressData.fields.product_feature_group.title}
            additionalClasses={'flexible'}
            Items={GridItems}
          />

          <HighlightBlock
            BlockWidth={'full-width'}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: vertical,
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            MicroTitle={wordpressData.fields.highlight_block_two.subtitle}
            Theme={'freeform'}
            ImageType={'Split'}
            Title={`<b>${wordpressData.fields.highlight_block_two.title}</b>`}
            Image={{
              url: wordpressData.fields.highlight_block_two.image.url
            }}
          />

          <ArticleBlock
            Link={{
              link: '/news/',
              copy: 'All Insights'
            }}
            MostRecentBlogs={true}
            Title={wordpressData.fields.news_articles.title}
            Topic={'freeform'}
            Layout={'layout-4'}
          />

          <HighlightBlock
            AdditionalClass={'haptic-announcement'}
            Theme={'PrimaryGradient'}
            Button={{
              target: '_blank',
              link: 'https://www.3dsystems.com/haptics',
              copy: globalCopy.Buttons.Learn
            }}
            Title={freeformHaptic.highlight.title}
            ImageType={'Split'}
            Image={{
              url: 'https://oqtonadmin.com/wp-content/uploads/2024/04/haptic-device.png'
            }}
          />

          <div className={'spacing-xl'}></div>

          <Footer Lang={Lang} lang={Lang} />
        </PageContainer>
      </>
    );
  } else {
    return null;
  }
};

export default FreeformTemplate;
