import React from 'react';
import styled from 'styled-components';
import { Paragraph, ParagraphBold, Tag } from '../../../../../styles/typography';

import { spacing, colors, media } from '../../../../foundations';

interface IntroProps {
  description: string;
  introText: React.ReactNode;
  metadata: {
    client: string;
    location: string;
    challenge: string;
  };
}

export const Intro = ({ description, introText, metadata }: IntroProps) => {
  return <p>{introText}</p>;
};
