import React, { useContext, useState } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';
import { ComponentAccordion, ListWrapper } from './styles.jsx';
import { ModalContext } from '../../../context/ModalContext';
import { createMarkUp } from '../helpers';
import { CustomCopy } from './CustomCopy';

interface Props {
  Title?: string;
  DataType?: string;
  Items?: object;
  Theme?: 'light' | 'dark';
}

export const Accordion = ({ Title = '', DataType = 'json', Items = [], Theme = 'light', ...props }: Props) => {
  const [activeItem, setActiveItem] = useState(null);
  const { setIsModalOpen } = useContext(ModalContext);
  const { setModalVertical } = useContext(ModalContext);
  const { setModalTitle } = useContext(ModalContext);

  const toggleAccordionItem = itemKey => {
    setActiveItem(activeItem === itemKey ? null : itemKey);
  };

  return (
    <ComponentAccordion className={`Component-Accordion theme-${Theme}`} {...props}>
      <GlobalStyle />
      <div className="container">
        {Title && (
          <>
            <h2>{Title}</h2>
            <div className="title-underline " />
          </>
        )}

        <ListWrapper>
          {Items.map((item, index) => (
            <li key={index} className={activeItem === index ? 'active' : ''}>
              <h4 onClick={() => toggleAccordionItem(index)}>{item.title}</h4>
              {item.customCopyID ? (
                <CustomCopy customCopyID={item.customCopyID} />
              ) : (
                <>
                  {item.content ? (
                    <div className={'copy item.content'}>
                      <p>
                        {item.content.map((content, index) => {
                          if (content.vertical) {
                            return (
                              <span
                                key={index}
                                className={'clickable'}
                                onClick={() => {
                                  setModalTitle(content.title);
                                  setIsModalOpen(true), setModalVertical(content.vertical);
                                }}
                              >
                                {content.copy}
                              </span>
                            );
                          } else {
                            return <span dangerouslySetInnerHTML={createMarkUp(content.copy)}></span>;
                          }
                        })}
                      </p>
                    </div>
                  ) : (
                    <div className={'copy item-not-content'}>
                      <p>
                        {item.showRequestAFreeTrial && (
                          <span
                            className={'clickable'}
                            onClick={() => {
                              setIsModalOpen(true),
                                setModalVertical(item.showRequestAFreeTrial.vertical),
                                setModalTitle(item.showRequestAFreeTrial.title);
                            }}
                          >
                            {item.showRequestAFreeTrial.copy}
                          </span>
                        )}
                      </p>
                      <div className={`data-type-${DataType}`} dangerouslySetInnerHTML={createMarkUp(item.copy)}></div>
                    </div>
                  )}
                </>
              )}
            </li>
          ))}
        </ListWrapper>
      </div>
    </ComponentAccordion>
  );
};

// <a href={'#'} onClick={()=>{handleClick(e)}}>Request a free trial</a>
