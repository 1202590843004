import React from 'react';
import { Footer } from '../../../components/layouts/Footer';
import { Hero } from '../../../components/layouts/Hero';
import { MediaComponent } from '../../../components/layouts/MediaComponent';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { RDBold } from '../../../styles/typography';
import { Related } from '../../../components/layouts/Related';
import { ListContainer } from '../../../components/pageComponents/Styles/style';

import { Resources } from '../../../components/layouts/Resources';
import { HeaderSubtitle } from '../../../components/subComponents/HeaderSubtitle';
import { HeaderBackground } from '../../../components/pageComponents/header';
import { Header } from '../../../components/layouts/HeaderComponent';

import {
  ProductSubTitleContainer,
  ProductSubTitle,
  ProductSubTitleIcon,
  ProductSubTitleLeft,
  ProductSubTitleRight,
  Light
} from '../../../components/pageComponents/mediaComponentContent/styles';
import {
  GeomagicEssentialsContent,
  GeomagicEssentialsControlXContent
} from '../../../components/pageComponents/mediaComponentContent/geomagicEssentials';
import { GeomagicEssentialsComboContent } from '../../../components/pageComponents/mediaComponentContent/geomagicEssentials/geomagicEssentialsComboContent';
import { Icon } from '../../../components/subComponents/Icon';
import { PageContainer } from '../../../components/containers/PageContainer';

const EssentialsBundle = () => {
  return (
    <PageContainer>
      <HelmetContainer
        currentPage={'campaigns-geomagic-essentials-bundle'}
        image="/assets/images/pages/geomagicDesignX/hero.png"
        title="Geomagic Design X Essentials & Geomagic Control X Essentials"
        pageDescription="Make the most of any scanner, at any frequency, with industry leading toolsets for Scan-to-CAD and metrology workflow. | Developed by Oqton"
        description="Make the most of any scanner, at any frequency, with industry leading toolsets for Scan-to-CAD and metrology workflow. | Developed by Oqton"
        noindex
      />
      <HeaderBackground>
        <Header />
        <Hero
          title="<b>Affordable</b> 3D Scanning and Inspection Software"
          subtitle={<HeaderSubtitle icon="ControlXEssentials" boldText="Geomagic" text="Control X Essentials" />}
          subtitle2={<HeaderSubtitle icon="DesignXEssentials" boldText="Geomagic" text="Design X Essentials" />}
          image={{ src: '/assets/images/pages/geomagicDesignX/hero.png', alt: 'Design X Hero' }}
          description="Make the most of any scanner, at any frequency, with industry leading toolsets for Scan-to-CAD and metrology workflow."
          buttons={[{ text: 'Request a demo', vertical: 'GeomagicEssentials' }]}
          vertical="GeomagicEssentials"
        />
      </HeaderBackground>
      <ProductSubTitleContainer>
        <ProductSubTitle>
          <ProductSubTitleLeft>
            <ProductSubTitleIcon>
              <Icon name="DesignXEssentials" size={64} />
            </ProductSubTitleIcon>
            <RDBold>Geomagic</RDBold>
            <Light>Design X Essentials</Light>
          </ProductSubTitleLeft>
          <ProductSubTitleRight>Accurate and Accessible Scan-to-CAD workflows</ProductSubTitleRight>
        </ProductSubTitle>
      </ProductSubTitleContainer>
      <MediaComponent
        id="essentials"
        articles={[
          {
            title: '<b>What is</b> Geomagic Design X Essentials?',
            content: <GeomagicEssentialsContent />,
            image: { src: '/assets/images/pages/geomagicDesignX/media_1.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />
      <MediaComponent
        decreasedPadding
        articles={[
          {
            title: '<b>Why</b> Geomagic Design X Essentials?',
            content: (
              <React.Fragment>
                <p>
                  Geomagic Design X Essentials is purpose-built for converting 3D scan data into high-quality
                  feature-based CAD models.
                </p>
                <ListContainer>
                  <li>
                    Leverage existing assets to easily recreate or improve designs. Import scan data of any size from
                    all major scanners or portable CRMs.
                  </li>
                  <li>
                    Leverage existing assets to easily recreate or improve designs. Import scan data of any size from
                    all major scanners or portable CRMs.
                  </li>
                  <li>Rebuild CAD data using accurate solid modelling, surface fitting and mesh editting tools.</li>
                  <li>Rapidly creates solids or surfaces just like in CAD.</li>
                  <li>
                    Geomagic Design X Essentials works just like your CAD package, making it simpler than ever to create
                    as-designed and as-built 3D CAD and model data.
                  </li>
                  <li>
                    <li>
                      Geomagic Design X Essentials works just like your CAD package, making it simpler than ever to
                      create as-designed and as-built 3D CAD and model data.
                    </li>
                  </li>
                </ListContainer>
              </React.Fragment>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="Geomagic DesignX"
                  className="vidyard_iframe"
                  src="https://play.vidyard.com/7niTcirjvV9Dj5zGvjskaN.html"
                  width="640"
                  height="360"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            },
            image: {
              src: '/assets/images/pages/geomagicDesignX/media_2.png',
              alt: 'Geomagic DesignX',
              imagePos: 'left'
            }
          }
        ]}
      />
      <ProductSubTitleContainer>
        <ProductSubTitle>
          <ProductSubTitleLeft>
            <ProductSubTitleIcon>
              <Icon name="ControlXEssentials" size={64} />
            </ProductSubTitleIcon>
            <RDBold> Geomagic</RDBold> <Light> Control X Essentials</Light>
          </ProductSubTitleLeft>
          <ProductSubTitleRight>Accessible, Quality Metrology Tools for all</ProductSubTitleRight>
        </ProductSubTitle>
      </ProductSubTitleContainer>

      <MediaComponent
        id="controlx"
        articles={[
          {
            title: '<b>What is</b> Geomagic Control X Essentials?',
            content: <GeomagicEssentialsControlXContent />,
            image: { src: '/assets/images/pages/geomagic-control-x/media_1.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />
      <MediaComponent
        decreasedPadding
        articles={[
          {
            title: '<b>Why</b> Geomagic Control X Essentials?',
            content: (
              <React.Fragment>
                <p>
                  Bring the power of 3D scan-based inspection to more people in more places with software that makes it
                  easy to interpret scan data.
                </p>
                <ListContainer>
                  <li>
                    Integrate tightly with CAD and Geomagic Design X Essentials so you can update your 3D CAD models to
                    compensate for any problems.
                  </li>
                  <li>Everything you need for professional level inspection and reporting, all in one place</li>
                  <li>
                    Address the growing skill gap in manufacturing with a metrology software that works just like your
                    CAD package, requiring little or no training to get started.
                  </li>
                  <li>
                    Empower more people to inspect incoming parts from suppliers to prevent defective parts from making
                    it to assembly.
                  </li>
                  <li>
                    Use data from any 3D scanner, from entry-level to industry leading, without having to learn another
                    software interface.
                  </li>
                </ListContainer>
              </React.Fragment>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="Geomagic ControlX"
                  className="vidyard_iframe"
                  src="https://play.vidyard.com/EetkFteEVJWwJXiamxtzRW.html?"
                  width="640"
                  height="360"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            },
            image: {
              src: '/assets/images/pages/geomagic-control-x/media_2.png',
              alt: 'Geomagic ControlX',
              imagePos: 'left'
            }
          }
        ]}
      />
      <MediaComponent
        id="GeomagicForSolidworks"
        inverted
        articles={[
          {
            title: 'A COMPLETE PACKAGE <b>Design X Essentials & Control X Essentials</b>',
            content: <GeomagicEssentialsComboContent />,
            image: { src: '/assets/images/pages/geomagicDesignX/media_5.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />

      <Resources
        title="Resources"
        description="Compare features across the Geomagic Design X and Control X editions to understand which best fits your requirements."
        resources={[
          {
            title: 'Geomagic Design X Comparison Chart',
            label: 'flyer',
            link: '/assets/files/brochures/geomagicessentials/geomagic-designx-versionscomparison-22v1-en-digital.pdf'
          },
          {
            title: 'Control X Comparison Chart',
            label: 'flyer',
            link: '/assets/files/brochures/geomagicessentials/geomagic-controlx-comparisonchart-22v1-en-digital.pdf'
          }
        ]}
      />

      <Related
        title="Related technologies"
        articles={[
          {
            title: 'Geomagic Design X',
            description:
              'Increase in project frequency? In need of further Reverse Engineering tools? Check out Geomagic Design X, the industry’s most complete Scan-to-CAD solution, adding to what’s on offer in Design X Essentials.',
            image: { src: '/assets/images/pages/geomagic-control-x/designX.png', alt: 'placeholder' },
            url: '/geomagic-designx'
          },
          {
            title: 'Geomagic Control X',
            description:
              'Expanding your production? Looking for deeper integrated automation system? Geomagic Control X delivers further 3D scanning and metrology functionality on top of what is available within Control X Essentials.',
            image: { src: '/assets/images/pages/geomagicDesignX/controlX.png', alt: 'placeholder' },
            url: '/geomagic-controlx'
          }
        ]}
      />
      <Footer />
    </PageContainer>
  );
};

export default EssentialsBundle;
