import React from 'react';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { ResourceLinks } from '../components/V2/ResourceLinks/ResourceLinks';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { BackgroundWave } from '../GlobalStyles';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';
import { LogoGrid } from '../components/V2/LogoGrid/LogoGrid';
import { AnnoucementBar } from '../components/V2/AnnoucementBar/AnnoucementBar';
import moment from 'moment/moment';

const ThreeDInspectionSolution = () => {
  const vertical = '3d-scanning-solutions';

  return (
    <PageContainer>
      <HelmetContainer
        currentPage={'3d-scanning-solutions'}
        title="3D Scanning Solutions | Oqton "
        description={`Maximize the potential of your 3D scanning hardware with industry leading 3D Scanning software from Geomagic, developed by Oqton.`}
      />

      <BackgroundWave>
        <Header demoVertical={vertical} />
        <LargeHero
          AddSalesTel={true}
          BackgroundStyle={'image'}
          Copy={
            '<h1><b>3D Scanning Solutions</b></h1><p>Maximize the potential of your 3D scanning hardware with our industry leading Reverse Engineering and Inspection professional 3D scanning software portfolio.</p>'
          }
          Background={'/assets/images/solutions/3d-scanning-solutions-key-visual.webp'}
          Button={{
            ModelVertical: vertical,
            copy: 'Contact Us',
            OpenDemoModel: true,
            theme: 'accent-red'
          }}
        />
      </BackgroundWave>
      <AnnoucementBar
        AdditionalClass={'offset-bottom increase-z header-margin-0'}
        Image={'https://oqtonadmin.com/wp-content/uploads/2024/03/aerospace-annoucement.png'}
        Content={
          '<h3>From Physical Part to Parametric Model: 3D Scanning Applications in Aerospace</h3><p>Download this whitepaper and discover how companies across the Aerospace industry are leveraging 3D Scanning to design and manufacture better parts, faster</p>'
        }
        Link={{
          label: 'Download whitepaper',
          target: '_blank',
          link: 'https://oqton.com/news/ebooks/3d-scanning-applications-in-aerospace/'
        }}
      />

      <TextColumns
        AdditionalClasses={'annoucement-top'}
        Theme={'dark'}
        Columns={'1'}
        Copy={
          '<p>3D Scanning is the process of converting physical, real-world objects into precise digital models, for various applications.<br/><br/>Integration of 3D scan-based technology into any production environment provides the perfect platform to supercharge product manufacture through fast digitization, geometry optimization and accurate quality assurance. </p>'
        }
        Title={`Unlock the value of your existing parts with Geomagic software.`}
        Logos={['geomagic-designx', 'geomagic-controlx', 'geomagic-wrap', 'geomagic-for-solid']}
      />
      <LogoGrid
        Title={'Trusted by industry partners'}
        Copy={'Click each logo to learn more'}
        Logos={[
          {
            src: '/assets/images/partners/partner-logos/faro.png',
            alt: 'Faro',
            link: '/partners/faro/'
          },
          {
            src: '/assets/images/partners/partner-logos/evatronix.png',
            alt: 'Evatronix',
            link: '/partners/evatronix/'
          },
          {
            src: '/assets/images/partners/partner-logos/creaform.png',
            alt: 'Creaform',
            link: '/partners/creaform/'
          },
          {
            src: '/assets/images/partners/partner-logos/shining3d.png',
            alt: 'Shining3d',
            link: '/partners/shining-3d/'
          },
          {
            src: '/assets/images/partners/partner-logos/artec3d.png',
            alt: 'artec3d',
            link: '/partners/artec-3d/'
          },
          {
            src: 'https://i0.wp.com/oqtonadmin.com/wp-content/uploads/2023/10/Scantech-1.png',
            alt: 'scantech',
            link: '/partners/scantech/'
          },
          {
            src: '/assets/images/partners/partner-logos/kreon.png',
            alt: 'kreon',
            link: '/partners/kreon/'
          },
          {
            src: '/assets/images/partners/partner-logos/nikon.png',
            alt: 'nikon',
            link: '/partners/nikon/'
          },
          {
            src: '/assets/images/partners/partner-logos/hexagon.png',
            alt: 'hexagon',
            link: '/partners/hexagon/'
          },
          {
            src: '/assets/images/partners/partner-logos/smartech3d.png',
            alt: 'hexagon',
            link: '/partners/smarttech3d/'
          }
        ]}
      />

      <SplitColumn
        ImagePosition={'Left'}
        Copy={
          "<h3>Reverse Engineering</h3><p><a href='/3d-reverse-engineering'>Reverse Engineering</a> has always been used in part production in some capacity, to redesign, recreate and optimize components.<br/><br/>Whether you're recreating a legacy part from 3D scan data, or taking your prototype to mass production, we've got the solution. No digital CAD file or design data for an existing part? Reverse Engineering is the answer.</p>"
        }
        Image={{
          src: '/assets/images/solutions/3d-reverse-engineering-key-visual.webp',
          alt: ''
        }}
        Button={{
          copy: 'Find out more',
          link: '/3d-reverse-engineering',
          theme: 'white-outline'
        }}
      />
      <SplitColumn
        ImagePosition={'Right'}
        ImageOverlap={false}
        ImageBackground={false}
        Copy={
          "<h3>Inspection</h3><p>If you're serious about producing high quality parts, regardless of what industry you're supplying, then high quality <a href='/3d-inspection'>3D scan-based inspection software</a> are a non-negotiable. Pairing an accurate 3D scan-based inspection software with a quality, accurate 3D scanner will set you up for success.</p>"
        }
        Background={'White'}
        Image={{
          src: '/assets/images/solutions/inspection-key-visual-v2.webp',
          alt: ''
        }}
        Button={{
          copy: 'Find out more',
          link: '/3d-inspection',
          theme: 'white-outline'
        }}
      />
      <SplitColumn
        ImagePosition={'Left'}
        ImageOverlap={false}
        ImageBackground={false}
        Copy={
          '<h3>Hardware agnostic solutions for any industry</h3><p>Fast, accurate, 3D data capture, CAD creation and inspection is revolutionizing industries across the board, from aftermarket automotive parts and aerospace assemblies, to cultural heritage preservation and dental aligner inspection.<br/><br/>Whatever your industry, whether complete novice or seasoned 3D scanning veteran, our hardware agnostic software packages have you covered.</p>'
        }
        Background={'Primary'}
        Image={{
          src: '/assets/images/solutions/3d-scanning-solutions-hardware.webp',
          alt: ''
        }}
      />

      <ResourceLinks
        Title={'Resources'}
        Copy={'Check out some of our great 3D Inspection content'}
        Button={{
          Label: 'Show All Resources',
          Link: '/news'
        }}
        Items={[
          {
            category: 'Blog',
            title: 'Which Reverse Engineering solution is right for me?',
            link: {
              target: '_blank',
              url: '/posts/which-reverse-engineering-solution-is-right-for-me/',
              copy: 'Read Blog'
            }
          },
          {
            category: 'Webinar',
            title: 'Improving Turbine Blade Manufacturing Efficiencies with Geomagic',
            link: {
              target: '_blank',
              url: 'https://oqton.com/news/webinars/turbine-blade-webinar/',
              copy: 'Watch Webinar'
            }
          },
          {
            category: 'Blog',
            title: 'Introduction to First Article Inspection',
            link: {
              target: '_blank',
              url: '/posts/introduction-to-first-article-inspection/',
              copy: 'Read Blog'
            }
          }
        ]}
      />
      <ArticleBlock
        Title={'Customer stories'}
        Copy={
          'Learn about some of the game-changing ways our customers are using 3D scanning to bring better products to market, faster. '
        }
        Layout={'layout-1'}
        Link={{
          copy: 'All Customer Stories',
          link: '/news/?&category=Customer-Story'
        }}
        Items={[
          {
            title:
              'Cummins uses Oqton’s Geomagic software and metal 3D printing to get 1952 race car running again 50% faster',
            link: '/news/customer-stories/cummins-uses-geomagic-to-get-1952-race-car-running-again/',
            image: '/assets/images/customerStories/cummins/og_image.png',
            category: 'Customer Story',
            readTime: ''
          },
          {
            title: 'Geosciences and libraries turn to Geomagic Wrap from Oqton in wake of disaster',
            link: '/news/customer-stories/geosciences-turn-to-geomagic-wrap-in-wake-of-disaster/',
            image: '/assets/images/customerStories/usf/og_image.png',
            category: 'Customer Story',
            readTime: ''
          },
          {
            title: 'Kindig-it Design builds cooler cars faster with Oqton’s Geomagic Control X and Design X',
            link: '/news/customer-stories/kindig-it-design-builds-cars-faster-with-oqton/',
            image: '/assets/images/customerStories/kindigItDesign/IMG-1.png',
            category: 'Customer Story',
            readTime: ''
          }
        ]}
      />

      <Footer />
    </PageContainer>
  );
};

export default ThreeDInspectionSolution;
