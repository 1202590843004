import React, { useState } from 'react';
import { ButtonComponent } from '../../Button/Button';
import styled from 'styled-components';
import { colors, mediaQueriesSizes } from '../../../../GlobalStyles';
import It from '../../../../pages/it';
import { Modal } from './GridItemHelper';

interface Props {
  Item?: object;
}

const GridItemStyle = styled.div`
  position: relative; /* Ensure the div has a relative position */
  display: flex; /* Enable flexbox layout */
  align-items: flex-end; /* Align the image to the bottom */
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  border-radius: 4px;

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  h3,
  p,
  a,
  button {
    text-align: center;
    position: relative;
    z-index: 1;
  }

  h3 {
    font-family: centrano2-medium;
    font-size: 32px;
    line-height: 40px;
  }

  p {
    font-family: centrano2-book;
    font-size: 18px !important;
    line-height: 1.9 !important;
    letter-spacing: 0.01em;
    text-align: center !important;
  }

  p {
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 100%;
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 100%;
    }
  }

  div.content {
    padding: 32px 16px;
    text-align: center;
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      padding: 52px 32px 16px;
    }
  }
`;

export const GridItem6 = ({ Item, ...props }: Props) => {
  const handleClick = () => {
    setModalActive(true);
  };

  const [modalActive, setModalActive] = useState(null);

  return (
    <>
      {modalActive ? (
        <Modal
          close={() => {
            setModalActive(false);
          }}
          video={Item.Copy.Video}
        />
      ) : null}
      <GridItemStyle className={'item ' + Item.ComponentSize} {...props}>
        <div className={'content'}>
          <h3>{Item.Copy.Title}</h3>
          <p>{Item.Copy.Copy}</p>
          {Item.Copy.Button && Item.Copy.Button.Link && (
            <ButtonComponent Theme={'stripped'} Link={Item.Copy.Button.Link} Label={Item.Copy.Button.Label} />
          )}
          {Item.Copy.Video && (
            <ButtonComponent
              ClickFunction={handleClick}
              IconPosition={'left'}
              Theme={'stripped'}
              Icon={'play'}
              Label={Item.Copy.Button.Label}
            />
          )}
        </div>
        <img alt="" src={Item.Copy.BackgroundImage} />
      </GridItemStyle>
    </>
  );
};
