import React, { useContext } from 'react';
import GlobalStyle from '../../../GlobalStyles.jsx';

import { SideColumnStyles, LogoWrap } from './styles.jsx';
import { createMarkUp } from '../../helpers';
import { ButtonComponent } from '../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/fontawesome-free-solid';
import fontawesome from '@fortawesome/fontawesome';
import { generateGoogleCalendarURL, getURLParameter, removeURLParams } from '../../../../helpers/helper';
import { ModalContext } from '../../../../context/ModalContext';
import moment from 'moment';

interface Props {
  EventData: object;
}

export const EventSideColumnBlock = ({ EventData, ...props }: Props) => {
  fontawesome.library.add(faPlus);

  let endDate;
  let endMoment;
  let isInPast = false;

  if (EventData.fields.date && EventData.fields.date.end) {
    endDate = EventData.fields.date.end;
    endMoment = moment(endDate);
    isInPast = endMoment.isBefore(moment());
  }

  const DisplayButton = displayOptions => {
    if (displayOptions === 'always') {
      return true;
    }
    if (displayOptions === 'pre-event' && !isInPast) {
      return true;
    }
    if (displayOptions === 'post-event' && isInPast) {
      return true;
    }
    return false;
  };

  let buttonCounter = 0;

  return (
    <SideColumnStyles className={`theme-${EventData.fields['type'].toLowerCase()}`} {...props}>
      {EventData.fields.event_logo && (
        <LogoWrap>
          <img src={EventData.fields.event_logo.url} alt={EventData.title} />
        </LogoWrap>
      )}

      <div className={'button-wrapper'}>
        {EventData.fields.sidebar_buttons &&
          EventData.fields.sidebar_buttons.map((link, index) => {
            if (DisplayButton(link.display_options)) {
              let theme = 'stripped';
              let icon = 'arrow-right';
              if (buttonCounter === 0) {
                if (EventData.type === 'Partner Event' || EventData.type === 'Conference') {
                  theme = 'accent-teal';
                } else {
                  theme = 'accent-red';
                }
                icon = '';
              }
              buttonCounter++;

              if (link.button_type === 'url') {
                return (
                  <React.Fragment key={index}>
                    <ButtonComponent
                      Target="_blank"
                      Link={link.link}
                      Label={link.button_label}
                      Icon={icon}
                      Theme={theme}
                    />
                    <br />
                  </React.Fragment>
                );
              }

              if (link.button_type === 'form') {
                return (
                  <React.Fragment key={index}>
                    <ButtonComponent
                      ModelTitle={link.button_label}
                      OpenDemoModel={true}
                      IframeURL={link.form_link}
                      Label={link.button_label}
                      Icon={icon}
                      Theme={theme}
                    />
                    <br />
                  </React.Fragment>
                );
              }
            }
          })}
        {!isInPast && (
          <ButtonComponent
            Theme="stripped"
            Target="_blank"
            Label={'Add to your calendar'}
            Link={generateGoogleCalendarURL(EventData)}
            Icon="plus"
          />
        )}
      </div>
    </SideColumnStyles>
  );
};
