import React from 'react';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { HighlightBlock } from '../components/V2/HighlightBlocks/HighlightBlock/HighlightBlock';
import { HighlightBlockNoImage } from '../components/V2/HighlightBlocks/HighlightBlockNoImage/HighlightBlockNoImage';
import { BackgroundGrey } from '../GlobalStyles';

interface Props {
  Lang?: string;
}

const AdditiveManufacturingTemplate = ({ Lang = 'en', ...props }: Props) => {
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const geomagicDesignX = require(`../../data/content/Pages/products/geomagic-designx/${Lang}.json`);
  const geomagicControlx = require(`../../data/content/Pages/products/geomagic-controlx/${Lang}.json`);
  const geomagicForSolidworks = require(`../../data/content/Pages/products/geomagic-for-solidworks/${Lang}.json`);
  const additive = require(`../../data/content/Pages/additive-v2/${Lang}.json`);

  return (
    <>
      <PageContainer>
        <BackgroundGrey>
          <HelmetContainer currentPage={'additive'} lang={Lang} title={'TEMP'} />
          <Header demoVertical={'additive'} lang={Lang} />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            Theme={'teal'}
            ImageType={'Background'}
            MicroTitle={'Contact'}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/additive-manufacturing/cta-bg.png'
            }}
            Title={'Take the first step towards transforming your additive manufacturing journey with us. '}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            AdditionalClass={'background-white text-primary text-limit-width'}
            Theme={'teal-dark'}
            ImageType={'Background'}
            Subtitle={"Experience new manufacturing standards by joining Oqton's solutions. "}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/additive-manufacturing/additive-manufacturing-hero.png'
            }}
            Title={'Request a demo now to discover how we can drive innovation and productivity in your industry.'}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            Theme={'accent-green'}
            Title={geomagicControlx.content.HighlightBlock.Title}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestFreeTrial,
              ModelVertical: 'controlX',
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            Image={{
              title: '',
              alt: 'Geomagic Control X Render',
              url: '/assets/images/pages/geomagic-control-x/hero.png'
            }}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            Theme={'accent-red'}
            Title={geomagicDesignX.content.HighlightBlock.Title}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestFreeTrial,
              ModelVertical: 'designx',
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/pages/geomagicDesignX/3DReverseEngineeringSoftware.webp'
            }}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlockNoImage
            Theme={'Dark-Primary'}
            AdditionalDescription={
              'Oqton provides automated dental workflows to help you meet growing production goals while significantly reducing manpower requirements. An end-to-end, integrated dental production system, Oqton ensures full traceability to future-proof your business.<br/><br/>Our platform is agnostic, replacing multiple disconnected software applications, across all of your machines.'
            }
            Title={'<h3>Why Oqton for Dental Production?</h3>'}
            Button={{
              copy: 'Download the brochure',
              ModelVertical: 'HealthcareBrochure',
              ModelTitle: 'Download the brochure',
              OpenDemoModel: true
            }}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            Theme={'geomagicSolidWorks'}
            Title={geomagicForSolidworks.content.HighlightBlock.Title}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestFreeTrial,
              ModelVertical: 'GeomagicForSolidworks',
              copy: globalCopy.Buttons.RequestFreeTrial,
              OpenDemoModel: true
            }}
            Image={{
              title: '',
              alt: '',
              url: '/assets/images/pages/geomagic-for-solidworks/3d_parts.png'
            }}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            Theme={'Primary'}
            Size={'extraText'}
            Title={additive.content.highlightBlock.copy}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: 'additive',
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Image={{
              className: '',
              title: '',
              alt: '',
              url: '/assets/images/additive/3DXpert-lattice.webp'
            }}
          />
          <br /> <br /> <br /> <br />
          <HighlightBlock
            Theme={'Primary'}
            Size={'extraText'}
            Title={additive.content.HighlightBlock2.copy}
            Button={{
              ModelTitle: globalCopy.Buttons.RequestDemo,
              ModelVertical: 'additive',
              copy: globalCopy.Buttons.RequestDemo,
              OpenDemoModel: true
            }}
            Image={{
              className: '',
              title: '',
              alt: '',
              url: '/assets/images/additive/oqton-industrial-additive-manufacturing-solutions.png'
            }}
          />
        </BackgroundGrey>
        <br /> <br /> <br /> <br />
      </PageContainer>
      <Footer lang={Lang} />
    </>
  );
};

export default AdditiveManufacturingTemplate;
