import React from 'react';
import { ContactButton, ContactContainer, LinkContainer } from './styles';

export const Contact = () => {
  return (
    <ContactContainer>
      <LinkContainer href="mailto:&#116;&#111;&#109;&#046;&#116;&#111;&#117;&#114;&#119;&#101;&#064;&#111;&#113;&#116;&#111;&#110;&#046;&#099;&#111;&#109;?subject=AI Academy">
        <ContactButton>Contact us</ContactButton>
      </LinkContainer>
    </ContactContainer>
  );
};
