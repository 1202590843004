import React from 'react';

import DownloadTemplate from '../../../templates/DownloadTemplateDX';

const DownloadGeomagicDX = () => {
  const Lang = 'pt-br';

  return <></>;
};

export default DownloadGeomagicDX;
