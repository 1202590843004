import React from 'react';
import { PageContainer } from '../components/containers/PageContainer';
import { Footer } from '../components/layouts/Footer';
import { Header } from '../components/layouts/HeaderComponent';
import { AIContent, AIHeader } from '../components/pageComponents/AIInternship';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { Main } from '../styles/background';

const AIInternship = () => {
  const Lang = 'en';
  const data = require(`../../data/content/Pages/ai/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer currentPage={'ai-academy'} title={data.helmet.title} />
      <h1 hidden>{data.helmet.title}</h1>

      <Main noPadding>
        <Header lang={Lang} />
        <AIHeader data={data.content} />
        <AIContent content={data.mainContent} />
      </Main>
      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default AIInternship;
