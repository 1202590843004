import React from 'react';
import { Features } from '../components/layouts/FeatureContainer';
import { Footer } from '../components/layouts/Footer';
import { Header } from '../components/layouts/HeaderComponent';
import { Hero } from '../components/layouts/Hero';
import { MediaComponent } from '../components/layouts/MediaComponent';
import { Resources } from '../components/layouts/Resources';
import { HeaderBackground } from '../components/pageComponents/header';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { HeaderSubtitle } from '../components/subComponents/HeaderSubtitle';
import { RDBold } from '../styles/typography';
import { SubHeader } from '../components/layouts/MediaComponent/styles';
import { ListContainerIndented } from '../components/pageComponents/Styles/style';
import { colorsRD } from '../components/foundations';
import { PageContainer } from '../components/containers/PageContainer';

const Amphyon = () => {
  const Lang = 'en';
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer
        image="/assets/images/pages/amphyon/key-visual-amphyon.webp"
        title="Additive Manufacturing Simulation Software"
        pageDescription="Metal Additive Manufacturing Software | Developed by Oqton"
        description="Metal Additive Manufacturing Software | Developed by Oqton"
        currentPage={'amphyon'}
      />
      <HeaderBackground>
        <Header />
        <Hero
          title="<b>Metal additive manufacturing simulation software</b>"
          image={{ src: '/assets/images/pages/amphyon/key-visual-amphyon.webp', alt: 'Amphyon Hero' }}
          subtitle={<HeaderSubtitle icon="Amphyon" text="Amphyon" />}
          announcement={
            'Announcement: Amphyon is now developed and supported by Oqton, following the acquisition of Oqton and Additive Works by 3D Systems.'
          }
          announcementUrl="/news/geomagic-3dxpert-and-amphyon-now-developed-by-oqton/"
          buttons={[
            { text: globalCopy.Buttons.RequestFreeTrial, vertical: 'amphyon' }
            // { text: globalCopy.Buttons.ContactUs, vertical: 'amphyon', color: colorsRD.primary }
          ]}
          phone
        />
      </HeaderBackground>

      <MediaComponent
        articles={[
          {
            title: '<b>Simulation-based</b> process preparation software for<br/><b>metal additive manufacturing</b>',
            content: (
              <>
                Amphyon provides a "first-time-right" additive manufacturing process by integrating new analysis and
                simulation tools into the process chain of metal additive manufacturing (LBM, SLM, DMLS, Metal 3D
                Printing). Driven by current requirements and challenges of{' '}
                <a href="/additive-manufacturing/">industrial additive manufacturing</a>, Amphyon significantly reduces
                pre-processing costs and takes metal AM to the next level of automation.
              </>
            ),
            image: { src: '/assets/images/pages/amphyon/media_1.png', alt: 'Amphyon', imagePos: 'right' }
          }
        ]}
      />
      <MediaComponent
        articles={[
          {
            title: 'The <b>ASAP</b> Principle',
            content: (
              <>
                <p>
                  The ASAP-Principle describes four ideal steps on the way to a stable, efficient and reliable process
                  chain:
                  <RDBold> Assessment, Simulation, Adaption and the Process</RDBold> itself. By examining all possible
                  build-up orientations with respect to economical and physical aspects of the process on the Assessment
                  stage, both, limitations of the design and optimal orientations can be calculated. The integration of
                  simulation based, automatic generation of optimized support structures and fast process simulation
                  tools into the pre-processing chain on the Simulation stage ensures geometric accuracy and increases
                  process stability while tremendously reducing the costs of process preparation. Finally, on the
                  Adaption stage, process parameters should be controlled with respect to thermal and mechanical aspects
                  via hatch re-orientation and parameter adaption. After going through these steps of pre-processing, on
                  the last stage the first-time-right process itself concludes the ASAP-Principle.
                </p>
                <SubHeader>Assessment</SubHeader>
                <ListContainerIndented>
                  <li>
                    Assess build time, material use, accessibility, post processing efforts and distortion sensitivity
                    for all build-up orientations of your 3D model.
                  </li>
                </ListContainerIndented>
                <SubHeader>Simulation</SubHeader>
                <ListContainerIndented>
                  <li>
                    Automatically perform a fast calculation of residual stress and distortions of your component on
                    regular workstation hardware.
                  </li>
                </ListContainerIndented>
                <SubHeader>Adaption</SubHeader>
                <ListContainerIndented>
                  <li>
                    Adapt scanpower/-rate and scan-vector alignment based on local geometry and optimize the thermal
                    input and strain accumulation according to cutting edge scientific knowledge.
                  </li>
                </ListContainerIndented>
                <SubHeader>Process</SubHeader>
                <ListContainerIndented>
                  <li>Build your part first-time-right.</li>
                </ListContainerIndented>
              </>
            ),
            image: { src: '/assets/images/pages/amphyon/media_2.png', alt: 'Placeholder', imagePos: 'right' }
          }
        ]}
      />

      {/*<CustomerStorySpotlight*/}
      {/*  title="Additive industry references"*/}
      {/*  cards={[*/}
      {/*    {*/}
      {/*      label: <img src="/assets/images/logos/nexa3D.svg" alt="nexa3D" width={72} />,*/}
      {/*      smallText:*/}
      {/*        '"I am really impressed with Amphyon\'s innovative approach in providing an intuitive and practical solution that can benefit a wide range of industrial users involved with designing and 3D-printing metallic parts with high levels of geometric ',*/}
      {/*      customerName: 'Subir Roy, Ph.D., Senior Technical Director',*/}
      {/*      hideLink: true*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: <img src="/assets/images/logos/nexa3D.svg" alt="nexa3D" width={72} />,*/}
      {/*      smallText:*/}
      {/*        'Amphyon\'s cutting-edge software tools for physics-based process preparation allow users to unlock the maximum potential of the DMLS technology and boost profitability of AM use cases."',*/}
      {/*      customerName: 'Dr. Hans J. Langer, CEO & Chairman',*/}
      {/*      hideLink: true*/}
      {/*    },*/}
      {/*    {*/}
      {/*      label: <img src="/assets/images/logos/nexa3D.svg" alt="nexa3D" width={72} />,*/}
      {/*      smallText:*/}
      {/*        '"The Amphyon software is a key part of the 3T development strategy as we continue to provide our valued partners the total additive manufacturing quality supply chain solution."',*/}
      {/*      customerName: 'Nigel Robinson, Operations Director',*/}
      {/*      hideLink: true*/}
      {/*    }*/}
      {/*  ]}*/}
      {/*/>*/}
      <Features
        title="Amphyon Features"
        columns=""
        featureCards={[
          {
            heading: 'Examiner',
            description:
              'Application specific optimization of build-up orientation based on the analysis and evaluation of required support volume, build time, surface accessibility, distortion sensitivity, and post-processing effort for every orientation,'
          },
          {
            heading: 'Supports',
            description:
              'Generation of optimized support structures that adjust support perforation and interfaces between part and support structure according to the loads that occur during the build-up process.'
          },
          {
            heading: 'Mechanical process simulation',
            description:
              'Fast mechanical simulation of the metal additive manufacturing process, calculating the residual stress and distortiion fields on regular desktop hardware.'
          },
          {
            heading: 'Thermal process simulation',
            description:
              'Fast thermal simulation of the metal additive manufacturing process, calculating the temperature history and field on regular desktop hardware.'
          },
          {
            heading: 'Predeformation',
            description: 'One-click solution to pre-compensate residual distortions by adapting the build-up geometry.'
          }
        ]}
      />
      <Resources
        title="Resources"
        resources={[
          {
            title: 'Technology and product overview',
            label: 'Brochure',
            link: '/assets/files/brochures/amphyon/Amphyon-General-22V1-EN-Digital.pdf',
            withBackground: true
          }
        ]}
      />
      <Footer />
    </PageContainer>
  );
};

export default Amphyon;
