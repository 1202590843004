import React from 'react';
import { createMarkUp } from '../../V2/helpers';
import { SubSection, SubTitle, Title, Date, ContentContainer } from './styles';

export const AIHeader = ({ data }) => {
  if (data) {
    return (
      <ContentContainer>
        <Title dangerouslySetInnerHTML={createMarkUp(data.title)}></Title>
        <SubSection>
          <SubTitle>{data.sub}</SubTitle>
          <Date>16.02.2022</Date>
        </SubSection>
        <img src="/assets/images/AIInternship/header.png" alt="Powdered by AI" width="100%" />
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <Title>
          Looking for students to join us <br />
          in solving manufacturing's biggest <br />
          challenges using AI.
        </Title>
        <SubSection>
          <SubTitle>AI Academy</SubTitle>
          <Date>16.02.2022</Date>
        </SubSection>
        <img src="/assets/images/AIInternship/header.png" alt="Powdered by AI" width="100%" />
      </ContentContainer>
    );
  }
};
