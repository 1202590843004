import styled from 'styled-components';
import {colors,mediaQueriesSizes} from "../../../GlobalStyles";

export const GridStyles = styled.div`
  padding: 64px 0;

  h4 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: ${colors.highlight};
    text-align: center;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 400;
    color: ${colors.primary};
    text-align: center;
    margin-bottom: 16px;
      &.color-white{
          color: white;
      }
  }

  p {
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.02em;
    color: ${colors.primary};
    max-width: 1000px;
    margin: 0 auto;
    &.small{
      font-family: centrano2-book;
      font-size: 16px;
      margin-bottom: 68px;
    }
  }
  
  .title-underline{
    margin: 18px auto 64px;
  }

  &.theme-teal{
    .title-underline{
      background: ${colors.teal};
    }
  }



  @media (min-width: ${mediaQueriesSizes.md}px) {

      h4 {
      font-size: 18px;
    }
    h2{
      font-size: 32px;
    }
    p{
      padding: 0 24px;
      font-size: 24px;
      &.small{
        font-size: 18px;
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {

      h2{
      font-size: 48px;
    }
    p{
      font-size: 32px;
      &.small{
        font-size: 24px;
      }
    }
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
      &.alignment-left{
          h4,h2,p{
              text-align: left;
          }
          .title-underline{
              margin-left: 0;
              margin-right: auto;
          }
      }

  }


`;

export const GridWrap = styled.div`
  display: grid;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(1, 1fr);
margin: 32px 0 0;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: repeat(3, 1fr);
  }

    &.homepage-grid{
        .item{
            background: white;
        }
    }
  .item {
    padding: 24px;
    background: #FFFFFF;
    //background: #f7f7f7;
    height: 380px;
    border: 1.5px solid #EBE8E5;
    position: relative;
    z-index: 1;
    
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      color: ${colors.primary};
      margin-bottom: 16px;
    }

    p {
      padding: 0;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.2;
      color: ${colors.light};
        text-wrap: balance;
    }

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      h3 {
        font-size: 22px;
      }
    }
    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding: 24px;
      height: 520px;

      &.double {
        grid-column: span 2 !important;
      }

      h3 {
        font-size: 24px;
      }

      p {
        font-size: 18px;
      }

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding: 24px;
      height: 480px;

      &.double {
        grid-column: span 2 !important;
      }

      h3 {
        font-size: 28px;
      }

      p {
        font-size: 18px;
      }

    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      padding: 32px;
      h3 {
        font-size: 26px;
      }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      padding: 46px;
      h3 {
        font-size: 32px;
      }
    }
  }
  
  &.flexible{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item{
      flex-basis: 100%;
      height: auto;
      padding:0;
      align-items: center;
      @media (min-width: ${mediaQueriesSizes.md}px){
        flex-basis: 46%;
      }
      @media (min-width: ${mediaQueriesSizes.xl}px){
        flex-basis: calc(33% - 20px);
      }
    }
      
    &.mos{
        .item{
            @media (min-width: ${mediaQueriesSizes.xl}px){
                flex-basis: 48%;
            }
        }
    }  
  }
  
`;

